import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  GetAppOutlined,
  TaskOutlined
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import ButtonDropdown from '../components/buttonDropdown';
import { Colors } from '../config/default';
import MuiPopover from '../components/muiPopover';
import Prompt from '../components/prompt';
import { Button, Grid, Checkbox, Typography, FormControlLabel, Switch } from '@mui/material';
import {
  isvendorValueMatching,
  vendorValueMatchForERP,
  getLineItemsCopy
} from '../constants/utils';
import { canvasDetails, documentTypes, userRoles } from '../constants/appConstants';
import MuiModal from '../components/muiModel';
import BottomDrawer from '../components/bottmDrawer';
import {
  arrangedLineitems,
  formatLineItems,
  consolidateItems,
  resetObjFields
} from '../services/common';
import { useToast } from '../toast/toastContext';
import { getDocumentDetails, matchPO } from '../services/services';
import { verifySameCodesForHeaderAndLineItems } from '../utils/canvasUtils';

const useStyles = makeStyles({
  primaryCheckbox: {
    '&.MuiCheckbox-colorPrimary': {
      color: Colors.TEXTGREY
    }
  }
});
const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: Colors.WHITE
  },
  '& .MuiSwitch-track': {
    backgroundColor: Colors.RED,
    transparency: 1
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: Colors.TOURQUISE
  }
}));
export default function DocumentButtons({
  annotationType,
  lineItem,
  lineItemsTotalMatch,
  openApprovePrompt,
  setOpenApprovePrompt,
  handleButtonClick,
  loadingButtons,
  glInfo,
  paymentTerms,
  duplicateDocumentNoErrorObj,
  vendors,
  hasErrors,
  hasPaymentErrors,
  hasLineItemErrors,
  typeCheck,
  vendorNumberCheck,
  setAnchorEl,
  anchorEl,
  approvers,
  manualEmails,
  selectedEmails,
  setSelectedEmails,
  fetchingPO,
  openPrompt,
  setOpenPrompt,
  handleOpen,
  refId,
  isDrawerOpen,
  setIsDrawerOpen,
  isPoMatched,
  isInvoiceNumberMatched,
  isLineItemsMatched,
  handleClick,
  setRef,
  setLineItemsData,
  po,
  totalAmount,
  data,
  fields,
  obj,
  setObj,
  namesArray,
  isLastActivityEmpty,
  lineItemInitialObj,
  showPrompt,
  valueChanged,
  id,
  callSource,
  setLineItem,
  setTolalItems,
  totalItems,
  headers,
  handleFieldChange,
  handleBoxClick,
  imageDimensions,
  setClickedBoxIndex,
  setIsLineItemsMatched,
  matchingCallsrc,
  combineItems,
  setCombineItems,
  disableConsolidate,
  handleAmountCalculator,
  buttonColors,
  content,
  setVendorNumberCheck,
  setTypeCheck,
  setButtonColors,
  invoiceNumber,
  setButtonType,
  setFetchingPO,
  lineItemsData,
  setIsPoMatched,
  setIsInvoiceNumberMatched,
  setConsolidating,
  array,
  setIsAmountMatched,
  setIsDateMatched,
  setFields,
  setActualaccountCodeValues,
  accountCodeArray,
  checkPoMatching,
  setIsInitialized,
  isInitialized
}) {
  const {
    APPROVE,
    VERIFY,
    FETCH_PO,
    FETCH_PO_DESCRIPTION,
    FETCH_SC_DESCRIPTION,
    DT_LINEITEM,
    SAME_CODES,
    TOTAL_PROMPT_MESSAGE
  } = canvasDetails;
  const { ACCOUNTANT, OWNER } = userRoles;
  const { ESTIMATE, DELIVERY_TICKET, INVOICE, G702, RECEIPT } = documentTypes;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const erpType = userDetails?.user?.erpType;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const diablePOandSC = po === '' || po === '-' || totalAmount === '' || totalAmount === '-';
  const classes = useStyles();
  const { showToast } = useToast();
  const [matchPoLoading, setMatchPoLoading] = useState(false);
  const [matchDtLoading, setMatchDtLoading] = useState(false);
  const allChildSelected =
    buttonColors?.length === content?.length && typeCheck && vendorNumberCheck;
  const allChecksAreEnabled = typeCheck || vendorNumberCheck;

  const POPermission =
    userDetails?.user?.poMatchPermission &&
    annotationType !== ESTIMATE &&
    annotationType !== DELIVERY_TICKET &&
    annotationType !== 'Po';

  const something = buttonColors?.filter((item) => item === true);
  const isTextNotChecked =
    array?.length !== buttonColors?.length || something?.length !== array?.length;

  const handleParentCheckboxChange = () => {
    if (allChildSelected || buttonColors?.length > 0 || allChecksAreEnabled) {
      setButtonColors([]);
      setTypeCheck(false);
      setVendorNumberCheck(false);
    } else {
      setButtonColors(content?.map((_, index) => !buttonColors[index]));
      setTypeCheck(true);
      setVendorNumberCheck(true);
    }
  };
  const handleOpenPrompt = (type) => {
    setOpenApprovePrompt(true);
    setButtonType(type);
  };
  const FetchPo = async (matchWith) => {
    setFetchingPO(true);
    let lineItemsCopy = [...lineItem];
    getLineItemsCopy(lineItemsCopy, lineItemsData);
    const params = {
      matchWith: matchWith,
      referenceDocumentId: '',
      poNumber: po,
      total: totalAmount,
      lineItems: formatLineItems(lineItemsCopy),
      callPurpose: 'Fetch'
    };

    const result = await matchPO(id, params);
    if (result?.status === 200) {
      setLineItem([]);
      setIsInvoiceNumberMatched({});
      setIsPoMatched(true);
      setIsAmountMatched({});
      setIsDateMatched({});
      setIsLineItemsMatched({});
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }

    const Podata = result?.data?.payload?.data.fetchedData;
    if (Podata) {
      const fillMissingValues = (field) => {
        if (!Podata[field] && Podata?.lineItems?.length > 0) {
          const firstLineItem = Podata?.lineItems[0];
          const item = firstLineItem?.find((item) => item?.name === field);
          if (item) {
            Podata[field] = item?.value;
          }
        }
      };

      fillMissingValues('userCostId');
      fillMissingValues('userJobId');
      fillMissingValues('userPhases');
      fillMissingValues('GlAccount');
      fillMissingValues('userCostType');

      setLineItem(Podata.lineItems);
      const dataObject = Podata?.codeContent?.reduce((acc, item) => {
        acc[item?.name] = item?.value;
        return acc;
      }, {});
      const { userCostId, userJobId, userPhases, GlAccount, userCostType } = dataObject || {};

      setFields((prev) => ({
        ...prev,
        costType: userCostType,
        jobId: userJobId,
        costId: userCostId,
        glAccount: GlAccount,
        phase: userPhases
      }));
      // Update actualaccountCodeValues
      setActualaccountCodeValues((prevValues) => {
        const updatedValues = [...prevValues];
        const updateValue = (name, value) => {
          const index = accountCodeArray?.findIndex((item) => item.name === name);
          if (index !== -1) updatedValues[index] = value;
        };
        updateValue('userCostId', userCostId);
        updateValue('userJobId', userJobId);
        updateValue('userPhases', userPhases);
        updateValue('GlAccount', GlAccount);
        updateValue('userCostType', userCostType);
        return updatedValues;
      });
    }

    setOpenPrompt(false);
    setFetchingPO(false);
  };
  const handlePoMatch = (callSrc) => {
    setMatchPoLoading(true);
    checkPoMatching(callSrc);
    setMatchPoLoading(false);
  };
  const handleDtMatch = (callSrc) => {
    setMatchDtLoading(true);
    checkPoMatching(callSrc);
    setMatchDtLoading(false);
  };

  const combineItemsChange = async (event) => {
    setIsInitialized(true);

    setConsolidating(true);
    setCombineItems(event.target.checked);
    const dataFound = await getDocumentDetails(id, callSource);
    if (dataFound?.status === 200) {
      const data = dataFound?.data?.payload?.data?.document;
      if (event.target.checked) {
        const consolidatedItems = consolidateItems(
          data?.lineItems,
          totalAmount?.replace(/,/g, ''),
          headers,
          fields.costId,
          fields.jobId,
          fields.glAccount,
          fields.phase,
          fields.costType,
          fields?.serviceCode,
          fields?.equipmentNo,
          fields?.glDivision,
          fields?.division2,
          fields?.division3,
          fields?.division4
        );
        setLineItem([consolidatedItems]);
      } else {
        setLineItem(data?.detailLineItems);
        if (isEmpty(data?.detailLineItems)) {
          setObj(resetObjFields(obj));
        }
      }
    }
    setIsLineItemsMatched([]);
    setConsolidating(false);
  };

  const sameCodesForHeaderandLineItems = verifySameCodesForHeaderAndLineItems(
    lineItemsData,
    fields,
    accountCodeArray
  );

  return (
    <div className="buttons-bar">
      <Grid container item xs={12} sx={{ justifyContent: 'space-between', mt: '1rem' }}>
        <div
          style={{
            display: 'flex',
            color: Colors.WHITE,
            alignItems: 'center',
            fontSize: '17px'
          }}>
          <Checkbox
            sx={{
              color: '#1AACAC !important',
              paddingLeft: '0px !important',
              marginLeft: '.9rem'
            }}
            classes={{
              colorPrimary: classes.primaryCheckbox
            }}
            indeterminate={
              (buttonColors?.length > 0 && buttonColors?.length < content?.length) ||
              allChecksAreEnabled ||
              false
            }
            indeterminateIcon={
              allChildSelected ? (
                <CheckBox />
              ) : isEmpty(buttonColors) && vendorNumberCheck === false && typeCheck === false ? (
                <CheckBoxOutlineBlank />
              ) : !isEmpty(buttonColors) && !buttonColors.includes(true) ? (
                <CheckBoxOutlineBlank />
              ) : (
                <IndeterminateCheckBox />
              )
            }
            checked={buttonColors?.length === content?.length}
            onChange={handleParentCheckboxChange}
          />
          {VERIFY}
        </div>
        <BottomDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          isPoMatched={isPoMatched}
          isInvoiceNumberMatched={isInvoiceNumberMatched}
          isLineItemsMatched={isLineItemsMatched}
          itemsData={arrangedLineitems(lineItem)}
          handleClick={handleClick}
          setRef={setRef}
          setLineItemsData={setLineItemsData}
          endIcon={<TaskOutlined sx={{ width: { xs: '13px', lg: '17px' } }} />}
          annotationType={annotationType}
          disabled={po === '' || po === '-' || totalAmount === '' || totalAmount === '-'}
          disableDt={
            invoiceNumber === '' ||
            invoiceNumber === '-' ||
            totalAmount === '' ||
            totalAmount === '-'
          }
          onClick={handlePoMatch}
          onDtClick={handleDtMatch}
          data={data}
          costType={fields.costType}
          jobId={fields.jobId}
          costId={fields.costId}
          phase={fields.phase}
          glAccount={fields.glAccount}
          obj={obj}
          setObj={setObj}
          namesArray={namesArray}
          isLastActivityEmpty={isLastActivityEmpty}
          fetchingPO={fetchingPO}
          lineItemInitialObj={lineItemInitialObj}
          openPrompt={openPrompt}
          setOpenPrompt={setOpenPrompt}
          showPrompt={showPrompt}
          valueChanged={valueChanged}
          fetchPo={FetchPo}
          lineItem={lineItem}
          id={id}
          callSource={callSource}
          setLineItem={setLineItem}
          matchPoLoading={matchPoLoading}
          setTolalItems={setTolalItems}
          totalItems={totalItems}
          headers={headers}
          handleFieldChange={handleFieldChange}
          handleBoxClick={handleBoxClick}
          imageDimensions={imageDimensions}
          setClickedBoxIndex={setClickedBoxIndex}
          matchDtLoading={matchDtLoading}
          refId={refId}
          serviceCode={fields.serviceCode}
          equipmentNo={fields.equipmentNo}
          serviceAndEquipmentPermission={serviceAndEquipmentPermission}
          setIsLineItemsMatched={setIsLineItemsMatched}
          matchingCallsrc={matchingCallsrc}
          combineItems={combineItems}
          setCombineItems={setCombineItems}
          handleChange={combineItemsChange}
          disableConsolidate={disableConsolidate}
          handleAmountCalculator={handleAmountCalculator}
          hasLineItemErrors={hasLineItemErrors}
          glDivision={fields.glDivision}
          isInitialized={isInitialized}
          division2={fields.division2}
          division3={fields.division3}
          division4={fields.division4}
        />
        {(annotationType === INVOICE || annotationType === G702 || annotationType === RECEIPT) && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControlLabel
              sx={{
                color: Colors.WHITE,
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: Colors.WHITE
                }
              }}
              control={
                <StyledSwitch
                  size="small"
                  checked={isInitialized ? combineItems : disableConsolidate}
                  onChange={(event) => combineItemsChange(event)}
                />
              }
              label="Consolidate Line items"
            />
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12}>
        {annotationType !== ESTIMATE &&
          annotationType !== DELIVERY_TICKET &&
          annotationType !== 'Po' && (
            <Grid
              container
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <ButtonDropdown
                text="Fetch"
                width="7rem"
                disable={!userDetails?.user?.poMatchPermission}
                showPrompt={true}
                icon={<GetAppOutlined sx={{ width: { xs: '13px', lg: '25px' } }} />}
                color={Colors.WHITE}
                openPrompt={openPrompt}
                setOpenPrompt={setOpenPrompt}
                helperText="Fetch Line Items"
                loading={fetchingPO}
                options={[
                  {
                    name: 'Purchase Order',
                    disable: diablePOandSC,
                    action: handleOpen,
                    promptTitle: FETCH_PO,
                    promptDescription: FETCH_PO_DESCRIPTION,
                    promptFunction: () => FetchPo('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Subcontract',
                    disable: diablePOandSC,
                    action: handleOpen,
                    promptTitle: 'Fetch Subcontract',
                    promptDescription: FETCH_SC_DESCRIPTION,
                    promptFunction: () => FetchPo('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
              <ButtonDropdown
                text="Match"
                width="7rem"
                disable={
                  !userDetails?.user?.poMatchPermission && !userDetails?.user?.deliveryTicketUpload
                }
                icon={<TaskOutlined sx={{ width: { xs: '13px', lg: '19px' } }} />}
                color={Colors.WHITE}
                showPrompt={false}
                helperText="Match Line Items"
                options={[
                  {
                    name: 'Purchase Order',
                    disable: diablePOandSC,
                    action: () => handlePoMatch('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Delivery Ticket',
                    disable:
                      invoiceNumber === '' ||
                      invoiceNumber === '-' ||
                      totalAmount === '' ||
                      totalAmount === '-' ||
                      isEmpty(refId),
                    action: () => handleDtMatch('DeliveryTicket'),
                    showButton:
                      (annotationType === 'Invoice' || annotationType === 'G702') &&
                      userDetails?.user?.deliveryTicketUpload
                  },
                  {
                    name: 'Subcontract',
                    disable: diablePOandSC,
                    action: () => handlePoMatch('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
            </Grid>
          )}
        {annotationType === DELIVERY_TICKET && isEmpty(lineItem) && (
          <Typography variant="caption" sx={{ color: Colors.RED, ml: '.2rem' }}>
            {DT_LINEITEM}
          </Typography>
        )}
        {sameCodesForHeaderandLineItems && (
          <Typography variant="caption" sx={{ color: Colors.RED, ml: '.2rem' }}>
            {SAME_CODES}
          </Typography>
        )}
        {!lineItemsTotalMatch && annotationType !== DELIVERY_TICKET && (
          <Prompt
            title="Approve Document"
            description={TOTAL_PROMPT_MESSAGE}
            leftButton="Cancel"
            rightButton="Continue"
            setOpen={setOpenApprovePrompt}
            open={openApprovePrompt}
            opacity={false}
            handleClick={(e) => handleButtonClick(e, 'Approved')}
            loading={loadingButtons}
          />
        )}

        <Button
          fullWidth
          variant="contained"
          disabled={
            (!isvendorValueMatching(glInfo?.termsList, paymentTerms) &&
              erpType === 'Foundation' &&
              annotationType !== DELIVERY_TICKET &&
              paymentTerms?.trim() !== '' &&
              paymentTerms !== null) ||
            loadingButtons ||
            sameCodesForHeaderandLineItems ||
            (annotationType === DELIVERY_TICKET && isEmpty(lineItem)) ||
            duplicateDocumentNoErrorObj?.match ||
            vendorValueMatchForERP(glInfo, vendors, erpType, userDetails) ||
            hasErrors ||
            hasPaymentErrors ||
            hasLineItemErrors ||
            isTextNotChecked ||
            typeCheck === false ||
            vendorNumberCheck === false
          }
          sx={{
            height: '2.4rem',
            marginBottom: '7px',
            borderRadius: '10px',
            fontWeight: 'bold',
            position: 'relative',
            padding: '0',
            '&:disabled': {
              color: 'gray',
              cursor: 'not-allowed'
            }
          }}>
          <div
            onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
            style={{
              width: '90%',
              padding: '.2rem'
            }}
            onClick={(e) =>
              !lineItemsTotalMatch && annotationType !== DELIVERY_TICKET
                ? handleOpenPrompt('main')
                : handleButtonClick(e, 'Approved', 'main')
            }>
            {APPROVE}
          </div>
          {(userRole === ACCOUNTANT || userRole === OWNER) &&
            annotationType !== ESTIMATE &&
            annotationType !== DELIVERY_TICKET && (
              <div style={{ width: '10%', padding: '.2rem' }}>
                <MuiModal
                  text="Approve"
                  show="manualUsers"
                  setMails={setSelectedEmails}
                  mails={selectedEmails}
                  apiData={manualEmails}
                  marginBottom="1rem"
                  variant="contained"
                  btnHeight="2.5rem"
                  submitData={(e) => {
                    lineItemsTotalMatch
                      ? handleButtonClick(e, 'Approved', 'manual')
                      : handleOpenPrompt('manual');
                  }}
                  loadingButtons={loadingButtons}
                />
              </div>
            )}
        </Button>
        <MuiPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          startMessage="Approved"
          approvers={approvers?.pending?.length > 0 ? approvers?.pending[0] : []}
          startPosition="top"
          endPosition="bottom"
        />
      </Grid>
    </div>
  );
}
