import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Grid } from '@mui/material';

import { Colors } from '../config/default';
import { getActivity } from '../services/services';
import ActivityDetailCard from './activityDetailCard';
import MuiModal from './muiModel';
import EmailVendor from './emailVendor';

export default function ActivityCard({ id, documentType, documentName }) {
  const [actData, setActData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null); // Ref for scrolling

  const selectedData = async () => {
    setLoading(true);
    const result = await getActivity(id, documentType);
    if (result?.status === 200) {
      setActData(result?.data?.payload?.data?.activity);
    } else if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    setLoading(false);
  };

  useEffect(() => {
    selectedData();
  }, []);

  const handleCommentAdded = () => {
    selectedData();
  };

  // Ensure auto-scroll happens after data is fully rendered
  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTo({
          top: scrollContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 100); // Small delay to allow UI updates before scrolling
    }
  }, [actData]);

  return (
    <Grid
      sx={{
        height: '91vh',
        width: { xs: '20em', sm: '30em', xl: '42em' },
        borderRadius: '1em',
        backgroundColor: Colors.BG_BLACK,
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1em 0.8em'
      }}>
      <Grid
        item
        ref={scrollContainerRef} // Attach ref to this container
        sx={{
          height: '92%',
          overflowY: 'auto',
          padding: '0em 1em',
          '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '10px',
            backgroundColor: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#555',
            borderRadius: '10px'
          }
        }}>
        {loading ? (
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
            <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
          </Grid>
        ) : (
          actData?.map(
            (item, index) =>
              (index !== 1 || documentType === 'Po') && (
                <ActivityDetailCard
                  key={index}
                  timelineLeftContent={item}
                  timelineRightContent={item?.updatedObj}
                  documentType={documentType}
                  activityIndex={index}
                  id={id}
                />
              )
          )
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ padding: '1.5em 1em 0em 1em', display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={6}>
          <MuiModal
            width="100%"
            text="Add Comment"
            show="comment"
            onAddComment={handleCommentAdded}
            id={id}
          />
        </Grid>
        <Grid item xs={6}>
          <EmailVendor documentName={documentName} selectedData={selectedData} id={id}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
