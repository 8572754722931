import React from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { formatDateOnly } from '../services/common';

const CustomDatePicker = ({ value, onChange, idx, stateValue, valueObj, error }) => {
  const handleDateChange = (newValue) => {
    if (!newValue) {
      onChange('', idx);
      if (stateValue) {
        stateValue(valueObj, '');
      }
    } else {
      const formattedDate = formatDateOnly(newValue);
      onChange(formattedDate, idx);
      if (stateValue) {
        stateValue(valueObj, formattedDate);
      }
    }
  };
  function convertToDate(dateStr) {
    const regex = /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/;

    const match = dateStr?.match(regex);

    if (match) {
      const month = parseInt(match[1], 10) - 1; // Adjust for 0-based month
      const day = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);

      return new Date(year, month, day);
    } else {
      return '';
    }
  }

  return (
    <div className={error ? 'datePicker-field-style-error' : ''}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          value={convertToDate(value)}
          onChange={handleDateChange}
          dateFormat="MM/DD/YYYY"
          placeholderText="MM/DD/YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              value={convertToDate(value)}
              onChange={handleDateChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none'
                  }
                },
                '& .MuiInputBase-input, & .MuiSvgIcon-root': {
                  color: 'white',
                  fontSize: '0.8em'
                }
              }}
            />
          )}
          PopperProps={{
            sx: {
              '& .MuiCalendarPicker-root , & .MuiPickersDay-root, & .MuiPickersMonth-root, & .MuiButtonBase-root, & .MuiTypography-root':
                {
                  backgroundColor: '#272727',
                  color: 'white'
                },
              '& .MuiPickersDay-root:hover': {
                backgroundColor: '#121212'
              },
              '& .MuiPickersDay-daySelected': {
                backgroundColor: '#216AA4',
                color: 'white'
              }
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatePicker;
