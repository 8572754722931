import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Button, CircularProgress } from '@mui/material';
import { Add, Sync } from '@mui/icons-material';

import { Colors } from '../config/default';
import MuiModal from './muiModel';
import { AdminTable } from '../constants/appConstants';
import Prompt from './prompt';

function UserHearder({ erpLoading, getUsers, handleSyncErp }) {
  const { SYNC_USER, FETCH_ACTION, FETCH_FROM_ERP } = AdminTable;

  const erpType = useSelector((state) => state?.userInfo?.userInfo?.user?.erpType);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);

  const [open, setOpen] = useState(false);
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        height: { xs: 'max-content', md: '3.3rem' },
        justifyContent: 'flex-end',
        marginTop: { xs: '1em', md: '0' }
      }}>
      {erpType === 'Foundation' && (
        <>
          <Button
            disabled={activeIntegration === '' || erpLoading}
            onClick={() => setOpen(true)}
            sx={{
              textTransform: 'unset !important',
              marginRight: '1rem',
              color: Colors.WHITE,
              height: '2rem',
              width: '10rem',
              fontSize: '.875rem',
              borderRadius: '10px',
              borderColor: Colors.DEEPBLUE,
              '&:hover': {
                borderColor: Colors.DEEPBLUE
              }
            }}
            variant="outlined">
            {erpLoading ? (
              <CircularProgress size={22} color="inherit" />
            ) : (
              <>
                <Sync
                  sx={{
                    fontSize: '18px',
                    marginRight: '.2rem',
                    color: Colors.DEEPBLUE
                  }}
                />
                {SYNC_USER}
              </>
            )}
          </Button>
          <Prompt
            title={FETCH_FROM_ERP}
            description={FETCH_ACTION}
            leftButton="Cancel"
            rightButton="Continue"
            setOpen={setOpen}
            open={open}
            handleClick={async () => {
              await handleSyncErp(activeIntegration);
              setOpen(false);
            }}
            loading={erpLoading}
          />
        </>
      )}

      <MuiModal
        text="Add User"
        show="Add_Users"
        btnHeight="2rem"
        width="8rem"
        smallScreenWidth="70%"
        mediumScreenWidth="50%"
        getUsers={getUsers}
        icon={<Add sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }} />}
      />
    </Grid>
  );
}

export default UserHearder;
