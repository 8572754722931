import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Button, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Colors } from '../config/default';
import theme from '../theme';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  DescriptionOutlined,
  CallReceivedOutlined,
  TopicOutlined,
  SensorsOutlined,
  HighlightAltOutlined,
  CheckCircleOutlined,
  HourglassTopRounded,
  ErrorOutlineRounded,
  BrowserUpdatedOutlined,
  PersonOutline,
  AutorenewOutlined,
  FileDownloadOutlined,
  CheckOutlined,
  DownloadOutlined
} from '@mui/icons-material';
import {
  getAllExportBatches,
  downloadExportedCsvBatch,
  updateExportCsvStatus,
  regenerateCsvByBatch
} from '../services/services';
import { useToast } from '../toast/toastContext';
import { formatDate } from '../services/common';
import CustomDrawer from '../components/customDrawer';
import { exportBatches } from '../constants/appConstants';
import { HtmlTooltip } from '../services/common';

const ExportBatches = () => {
  const [page, setPage] = useState(0);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const erpType = userDetails?.user?.erpType;
  const [rows, setRows] = useState([]);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const {
    CREATED_AT,
    NO_OF_DOCUMENTS,
    BATCH_NAME,
    GENERATED_BY,
    UPDATED_AT,
    STATUS,
    GENERATED,
    PENDING,
    ERROR,
    ACTIONS,
    REGENERATE,
    DOWNLOAD,
    DOWNLOADED
  } = exportBatches;

  const fetchExportBatches = async () => {
    if (companyId) {
      try {
        const response = await getAllExportBatches(companyId);
        if (response?.status === 200) {
          setRows(response?.data?.payload?.data);
        } else {
          console.error('Error fetching export batches:', response?.response?.data || response);
        }
      } catch (error) {
        console.error('Error fetching export batches:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchExportBatches();

    const intervalId = setInterval(() => {
      fetchExportBatches();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [companyId]);

  const handleRegenerateCsv = async (batchId) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row._id === batchId ? { ...row, status: 'Pending' } : row))
    );

    try {
      const response = await regenerateCsvByBatch({ batchId, erpType });
      if (response?.status === 200) {
        showToast(response?.data?.metadata?.message, 'success');
        fetchExportBatches();
      } else {
        showToast(response?.data?.metadata?.message, 'error');
      }
    } catch (error) {
      showToast('An error occurred while regenerating CSV', 'error');
    }
  };

  const handleBatchCsvDownload = async (batchId, s3Key, companyId) => {
    try {
      const response = await downloadExportedCsvBatch(batchId, s3Key, companyId);
      if (response?.status === 200) {
        const updateResponse = await updateExportCsvStatus({ batchId });
        if (updateResponse?.status === 200) {
          const files = response?.data?.payload?.data;
          if (files?.length === 1) {
            const file = files[0];
            window.open(file.signedUrl, '_blank');
          } else {
            const zip = new JSZip();
            await Promise.all(
              files.map(async (file) => {
                const fileBlob = await fetch(file.signedUrl).then((res) => res.blob());
                zip.file(file.s3_file_name, fileBlob);
              })
            );
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, 'downloaded_batch_files.zip');
          }
        }
      } else if (response?.response?.status === 401) {
        sessionStorage.clear();
        navigate('/');
      } else {
        showToast(
          response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
          'error'
        );
      }
    } catch (error) {
      showToast(error?.message || 'An error occurred', 'error');
    } finally {
    }
  };

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Created At',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CallReceivedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {CREATED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 90,
      renderCell: (params) => (
        <HtmlTooltip
          title={
            <Typography color="inherit" sx={{ fontSize: '1em' }}>
              {formatDate(params?.row?.createdAt)}
            </Typography>
          }>
          {formatDate(params?.row?.createdAt)}
        </HtmlTooltip>
      )
    },
    {
      field: 'noOfDocuments',
      headerName: 'No of Document',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <TopicOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {NO_OF_DOCUMENTS}
        </div>
      ),
      flex: 1,
      minWidth: 90
    },
    {
      field: 'batchName',
      headerName: 'Batch Name',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {BATCH_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 90
    },
    {
      field: 'generatedBy',
      headerName: 'Generated By',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PersonOutline sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {GENERATED_BY}
        </div>
      ),
      flex: 1,
      minWidth: 200
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <BrowserUpdatedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />

          {UPDATED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 90,
      renderCell: (params) => (
        <HtmlTooltip
          title={
            <Typography color="inherit" sx={{ fontSize: '1em' }}>
              {formatDate(params?.row?.updatedAt)}
            </Typography>
          }>
          {formatDate(params?.row?.updatedAt)}
        </HtmlTooltip>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <SensorsOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {STATUS}
        </div>
      ),
      flex: 1,
      minWidth: 70,
      renderCell: (params) => {
        if (params?.row?.status === 'Generated') {
          return (
            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
              <CheckCircleOutlined
                sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
              />
              {GENERATED}
            </div>
          );
        }
        if (params?.row?.status === 'Pending') {
          return (
            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
              <HourglassTopRounded
                sx={{ color: Colors.LIGHTGREY, fontSize: '17px', marginRight: '4px' }}
              />
              {PENDING}
            </div>
          );
        }
        if (params?.row?.status === 'Error') {
          return (
            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
              <ErrorOutlineRounded
                sx={{ color: Colors.RED, fontSize: '17px', marginRight: '4px' }}
              />
              {ERROR}
            </div>
          );
        }
        return null;
      }
    },
    {
      field: 'downloaded',
      headerName: 'Downloaded',
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DownloadOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {DOWNLOADED}
        </div>
      ),
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}>
            {params?.row?.downloadedAt !== null ? (
              <CheckOutlined sx={{ color: Colors.TOURQUISE }} />
            ) : (
              '-'
            )}
          </div>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 350,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTIONS}
        </div>
      ),
      renderCell: (params) => {
        const { status, _id, s3FileName, documentRefrences } = params.row;
        if (status === 'Pending' || status === null || status === '') return '-';

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {status !== 'Pending' && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<AutorenewOutlined />}
                onClick={() => handleRegenerateCsv(_id)}
                sx={{
                  minWidth: '110px',
                  color: 'white',
                  borderColor: Colors.DEEPBLUE,
                  borderRadius: '10px',
                  padding: '0.35rem',
                  textTransform: 'none'
                }}>
                {REGENERATE}
              </Button>
            )}

            {(status === 'Completed' || status === 'Generated') && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<FileDownloadOutlined />}
                onClick={() => handleBatchCsvDownload(_id, s3FileName, companyId)}
                sx={{
                  minWidth: '110px',
                  color: 'white',
                  borderRadius: '10px',
                  padding: '0.35rem',
                  textTransform: 'none'
                }}>
                {DOWNLOAD}
              </Button>
            )}

            <CustomDrawer show="downloaddetails" documentRefrences={documentRefrences} />
          </div>
        );
      }
    }
  ];

  const datagridSx = {
    border: 'none',
    color: Colors.TEXTGREY,
    paddingX: '1rem',
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-main': { borderRadius: '10px' },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      borderBottom: 'none !important',
      '& .MuiDataGrid-root': { border: 'none !important' },
      '& .MuiDataGrid-row': {
        width: '99%',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: '#24658D !important',
          color: Colors.WHITE
        },
        '&:nth-of-type(2n-1)': {
          backgroundColor: '#1E1E1E'
        }
      },
      '& div': { border: 'none' }
    },

    '& .MuiDataGrid-columnCheckbox': {
      backgroundColor: '#fdfdfd',
      width: '20px',
      height: '20px'
    },

    '& .MuiDataGrid-columnHeaders': {
      border: 'none !important',
      color: Colors.WHITE
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid sx={{ width: '100%', height: { xs: 'auto', sm: '60vh', lg: '70vh' } }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <DataGrid
            sx={{
              ...datagridSx,
              overflow: 'auto',
              '& .MuiDataGrid-virtualScroller': {
                '&::-webkit-scrollbar': { height: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.WHITE,
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-track': { backgroundColor: Colors.BG_BLACK }
              }
            }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row._id}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            disableRowSelectionOnClick
            autoPageSize
            hideFooterSelectedRowCount
          />
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default ExportBatches;
