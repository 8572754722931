import React from 'react';
import { isEmpty } from 'lodash';

import { InputLabel, FormControl, TextField, Autocomplete, Paper } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { documentTypes } from '../constants/appConstants';


import { Colors } from '../config/default';

export default function BusinessAccountsList({
  permission,
  setPermission,
  question,
  color,
  data,
  allowInput,
  font,
  placeHolder,
  getUpdatedApproversAccordingtoBasePolicy,
  policyBase,
  name,
  documentType
}) {
  const { ESTIMATE, DELIVERY_TICKET } = documentTypes;

  const handleChange = (event, value) => {
    setPermission(value === null ? '' : value);
    if (name === 'vendor' && policyBase === 'vendor' && (documentType !== ESTIMATE && documentType !== DELIVERY_TICKET)) {
      getUpdatedApproversAccordingtoBasePolicy(value === null ? '' : value);
    }
  };

  const handleInputChange = (event, value) => {
    if (allowInput) {
      setPermission(value === null ? '' : value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));

  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-standard-label">{question}</InputLabel>
        <Autocomplete
          value={permission || null}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={data}
          disableClearable={!!(font || !permission)}
          isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
          clearIcon={<Clear size="small" sx={{ color: Colors.WHITE, fontSize: '1rem' }} />}
          classes={{ option: classes.option }}
          ListboxProps={{
            className: 'myCustomList'
          }}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              color: Colors.TEXTGREY
            }
          }}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                backgroundColor: Colors.NAV_BLACK,
                color: Colors.TEXTGREY,
                borderRadius: '10px',
                padding: '0px 8px'
              }}>
              {children}
            </Paper>
          )}
          renderOption={(props, option) => {
            const { key } = props;

            return (
              <li {...props} key={props['data-option-index'] + '-' + key}>
                {option}
              </li>
            );
          }}
          onKeyDown={(event) => {
            const filteredOptions = data.filter((option) =>
              option.toLowerCase().includes(event.target.value?.toLowerCase() || '')
            );

            if (event.key === 'Tab' && !isEmpty(filteredOptions)) {
              const firstOption = filteredOptions[0];
              handleChange(null, firstOption);
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                placeholder={placeHolder || ''}
                sx={{
                  marginTop: '.3rem'
                }}
                {...params}
                variant="standard"
                label=""
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    color: color,
                    fontSize: font || '13px'
                  }
                }}
              />
            );
          }}
        />
      </FormControl>
    </div>
  );
}
