import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  Table,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TableBody,
  Autocomplete,
  TextField,
  IconButton,
  Button
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Colors } from '../config/default';
import { TooltipMessages, documentTypes } from '../constants/appConstants';
import {
  Error,
  Remove,
  AttachMoney,
  ShoppingCart,
  DescriptionOutlined,
  RequestQuoteOutlined,
  InsertDriveFileOutlined,
  Close,
  Add,
  ContentCopy,
  Clear,
  Info,
  SquareFoot
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { replaceName } from '../services/common';
import { arrangedLineitems } from '../services/common';
import { validateLineItems } from '../services/validationService';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: `white !important`,
    borderBottom: 'none',
    backgroundColor: Colors.Canvas_Left_BG,
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
  [`&.${tableCellClasses.body}`]: {
    color: `${Colors.WHITE} !important`,
    borderBottom: 'none',
    padding: '.5rem'
  }
}));
const iconMap = {
  Description: DescriptionOutlined,
  Amount: AttachMoney,
  Unit_Price: AttachMoney,
  Quantity: ShoppingCart,
  Product_Code: DescriptionOutlined,
  userCostType: RequestQuoteOutlined,
  userCostId: RequestQuoteOutlined,
  userJobId: InsertDriveFileOutlined,
  GlAccount: DescriptionOutlined,
  userPhases: InsertDriveFileOutlined,
  equipmentNo: DescriptionOutlined,
  serviceCode: DescriptionOutlined,
  glDivision: RequestQuoteOutlined,
  division2: RequestQuoteOutlined,
  division3: RequestQuoteOutlined,
  division4: RequestQuoteOutlined,
  UOM: SquareFoot
};
export default function LineItems({
  setDrawerOpen,
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  obj,
  setObj,
  annotationType,
  data,
  lineItem,
  setTolalItems,
  totalItems,
  headers,
  handleFieldChange,
  handleBoxClick,
  imageDimensions,
  setClickedBoxIndex,
  isInvoiceNumberMatched,
  matchingCallsrc,
  handleAmountCalculator
}) {
  const { LINEITEMS_NOT_FOUND, DUPLICATE_LINEITEM, DESCRIPTION_LIMIT_MESSAGE } = TooltipMessages;
  const { INVOICE, DELIVERY_TICKET, ESTIMATE, PURCHASE_ORDER } = documentTypes;

  let filteredOptions = [];
  const smallScreen = useMediaQuery('(max-width:1000px)');
  const smallToLarge = useMediaQuery('(max-width:2200px)');
  const findItemProperty = (ref, property) => {
    const lineItems = arrangedLineitems(lineItem);
    for (let parentIndex = 0; parentIndex < lineItems.length; parentIndex++) {
      const elements = lineItems[parentIndex];
      for (let idx = 0; idx < elements.length; idx++) {
        const items = elements[idx];

        if ('box_' + items?.value + '**%**' + parentIndex + idx === ref) {
          if (property === 'height') {
            return items.top * imageDimensions.height + items.height;
          } else if (property === 'page') {
            return items?.page;
          } else if (property === 'confidence') {
            return items?.confidence;
          } else {
            return null;
          }
        }
      }
    }

    return null;
  };
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const isConstructionCompany = userDetails?.user?.constructionCompany;

  const handleSelectChange = (value, index, fieldName) => {
    const updatedValue = value !== null ? value : '';
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      newObj[fieldName][index] = updatedValue || '';
      setLineItemsData(newObj);
      return newObj;
    });
  };
  const createNewLineItem = (headers) => {
    return Array.from({ length: headers?.length || 0 }, (_, index) => {
      if (headers[index]) {
        return {
          name: headers[index],
          value: '',
          width: null,
          height: null,
          left: null
        };
      }
      return null;
    });
  };
  const AddNewItem = () => {
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      headers?.forEach((fieldName) => {
        if (isEmpty(lineItem)) {
          newObj[fieldName] = [''];
        } else {
          newObj[fieldName]?.push('');
        }
      });
      setLineItemsData(newObj);
      setTolalItems((prevArr) => [...prevArr, 0]);
      const newLineItem = createNewLineItem(headers)?.filter(Boolean);
      lineItem.push(newLineItem);
      return newObj;
    });
  };

  const duplicateLineItem = (index) => {
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      let filledValues = {};
      headers?.forEach((fieldName) => {
        if (
          fieldName !== 'userCostType' &&
          fieldName !== 'userCostId' &&
          fieldName !== 'userJobId' &&
          fieldName !== 'userPhases'
        ) {
          newObj[fieldName].splice(index + 1, 0, newObj[fieldName][index]);
        } else {
          filledValues[fieldName] = prevObj[fieldName][index];
          newObj[fieldName].splice(index + 1, 0, filledValues[fieldName]);
        }
      });
      setLineItemsData(newObj);
      setTolalItems((prevArr) => [...prevArr, 0]);
      const newLineItem = Array.from({ length: headers?.length || 0 }, (_, idx) => {
        return { ...lineItem[index][idx] };
      });
      lineItem.splice(index + 1, 0, newLineItem);
      return newObj;
    });
  };

  const useStyles = makeStyles((theme) => ({
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));
  const classes = useStyles();
  const handleRemoveRow = (indexToRemove) => {
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      headers?.forEach((fieldName) => {
        newObj[fieldName]?.splice(indexToRemove, 1);
      });
      setLineItemsData(newObj);
      lineItem?.splice(indexToRemove, 1);
      const updatedTotalItems = [...totalItems];
      updatedTotalItems?.splice(indexToRemove, 1);
      setTolalItems(updatedTotalItems);

      return newObj;
    });
  };

  const filteredHeaders = headers?.filter((header) => header !== undefined);
  const headerCount = filteredHeaders?.length;
  const cellWidth = `${100 / headerCount}%`;

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: Colors.NAV_BLACK,
        height: '77%',
        marginTop: '.2rem'
      }}>
      <TableContainer
        sx={{
          marginBottom: '1rem',
          height: '100%',
          overflowX: 'auto',
          overflowY: itemsData?.length > 2 ? 'scroll' : '',
          '&::-webkit-scrollbar': {
            width: '0.2em',
            height: '.4em'
          }
        }}>
        <Table
          size="medium"
          sx={{
            width:
              annotationType === INVOICE &&
              erpType === 'Foundation' &&
              isConstructionCompany &&
              smallToLarge
                ? '120vw'
                : '100%',
            backgroundColor: Colors.NAV_BLACK,
            color: Colors.TEXTGREY
          }}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers?.map((element, i) => {
                return (
                  element && (
                    <StyledTableCell
                      sx={{
                        width: cellWidth
                      }}
                      key={i}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          cursor: 'default',
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                        {iconMap[element]
                          ? React.createElement(iconMap[element], {
                              style: { fontSize: '1.2rem', color: Colors.TOURQUISE }
                            })
                          : null}
                        {replaceName(element, erpType)}
                        {element === 'Description' && erpType === 'Foundation' && (
                          <Tooltip title={DESCRIPTION_LIMIT_MESSAGE}>
                            <Info
                              sx={{
                                marginLeft: '0.5rem',
                                color: Colors.DISABLEDGRAY
                              }}
                            />
                          </Tooltip>
                        )}
                      </span>
                    </StyledTableCell>
                  )
                );
              })}
              <StyledTableCell
                sx={{
                  width: '20px',
                  p: 0,
                  border: 'none',
                  position: 'sticky',
                  right: 0,
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 1
                }}>
                <div style={{ display: 'flex' }}>
                  <IconButton sx={{ marginRight: '1rem' }} onClick={() => setDrawerOpen(false)}>
                    <Close sx={{ color: Colors.TEXTGREY, cursor: 'pointer' }} />
                  </IconButton>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: Colors.NAV_BLACK }}>
            {totalItems &&
              Array.isArray(totalItems) &&
              totalItems?.map((x, index) => (
                <TableRow
                  key={index}
                  sx={{
                    border: 'none',
                    backgroundColor: index % 2 === 0 ? Colors.NAV_BLACK : Colors.GREY,
                    borderRadius: '20px',
                    margin: '0rem 2rem !important'
                  }}>
                  {headers?.map((items, idx) => {
                    let isMatched;
                    let isAmountMatched;
                    let isDescriptionMatched;
                    let isQuantityMatched;
                    isPoMatched || isInvoiceNumberMatched?.status
                      ? ((isMatched = isLineItemsMatched && isLineItemsMatched[index]?.match),
                        (isAmountMatched =
                          isLineItemsMatched && isLineItemsMatched[index]?.data?.total),
                        (isDescriptionMatched =
                          isLineItemsMatched && isLineItemsMatched[index]?.data?.description),
                        (isQuantityMatched =
                          isLineItemsMatched && isLineItemsMatched[index]?.data?.qty))
                      : '';
                    const itemValue =
                      obj && obj[items] && obj[items][index] !== undefined ? obj[items][index] : '';
                    const iteMdata = itemsData && itemsData[index] ? itemsData[index] : '';
                    const boxIndex = `${index}${idx}`;
                    const isError = validateLineItems(
                      items,
                      itemValue,
                      annotationType,
                      erpType,
                      isConstructionCompany
                    );
                    return (
                      items && (
                        <StyledTableCell
                          key={idx}
                          sx={{
                            flex: 1
                          }}
                          ref={setRef('button_' + itemValue + '**%**' + index + idx)}
                          onClick={() => {
                            if (!smallScreen) {
                              setClickedBoxIndex(boxIndex);
                              const documentTypeIndex =
                                annotationType !== INVOICE && annotationType !== 'Estimate'
                                  ? idx + 1
                                  : idx;
                              handleBoxClick(
                                items,
                                itemValue,
                                'box_' + itemValue + '**%**' + index + documentTypeIndex,
                                findItemProperty(
                                  'box_' + itemValue + '**%**' + index + documentTypeIndex,
                                  'height'
                                )
                              );
                              handleClick(
                                itemValue + '**%**' + index + idx,
                                itemValue + '**%**' + index + documentTypeIndex,
                                documentTypeIndex,
                                iteMdata,
                                false,
                                findItemProperty(
                                  'box_' + itemValue + '**%**' + index + documentTypeIndex,
                                  'page'
                                ),
                                findItemProperty(
                                  'box_' + itemValue + '**%**' + index + documentTypeIndex,
                                  'confidence'
                                )
                              );
                            }
                          }}>
                          {items === 'userCostType' ||
                          items === 'userCostId' ||
                          items === 'userJobId' ||
                          items === 'GlAccount' ||
                          items === 'userPhases' ||
                          items === 'serviceCode' ||
                          items === 'equipmentNo' ||
                          items === 'Product_Code' ||
                          items === 'glDivision' ? (
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              {items === 'Product_Code' &&
                                (annotationType === INVOICE ||
                                  annotationType === ESTIMATE ||
                                  annotationType === DELIVERY_TICKET) && (
                                  <Tooltip title={DUPLICATE_LINEITEM}>
                                    <IconButton onClick={() => duplicateLineItem(index)}>
                                      <ContentCopy
                                        sx={{ color: Colors.TOURQUISE, cursor: 'pointer' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {data &&
                                data?.map((acc, i) => {
                                  if (acc?.name === items) {
                                    return acc?.value?.length > 0 && !acc?.value?.includes(null) ? (
                                      <Grid
                                        key={i}
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%'
                                        }}>
                                        <Autocomplete
                                          value={obj[items][index] || null}
                                          onChange={(event, value) =>
                                            handleSelectChange(value, index, items)
                                          }
                                          inputValue={obj[items][index]}
                                          onInputChange={(event, newInputValue) => {
                                            handleSelectChange(newInputValue, index, items);
                                          }}
                                          options={acc?.value}
                                          renderOption={(props, option) => {
                                            const { key } = props;
                                            filteredOptions.push(option);
                                            return (
                                              <li
                                                {...props}
                                                key={props['data-option-index'] + '-' + key}>
                                                {option}
                                              </li>
                                            );
                                          }}
                                          onKeyDown={(event) => {
                                            if (event.key === 'Tab' && !isEmpty(filteredOptions)) {
                                              const firstOption = filteredOptions[0];
                                              handleSelectChange(firstOption, index, items);
                                            }
                                          }}
                                          disableClearable={!obj[items][index]}
                                          clearIcon={
                                            <Clear
                                              size="small"
                                              sx={{ color: Colors.WHITE, fontSize: '1rem' }}
                                            />
                                          }
                                          classes={{
                                            option: classes.option
                                          }}
                                          ListboxProps={{
                                            className: 'myCustomList'
                                          }}
                                          sx={{
                                            marginBottom: '.4rem',
                                            width: '100%',
                                            '& .MuiAutocomplete-popupIndicator': {
                                              color: Colors.WHITE
                                            }
                                          }}
                                          PaperComponent={({ children }) => (
                                            <Paper
                                              style={{
                                                backgroundColor: Colors.NAV_BLACK,
                                                color: Colors.WHITE,
                                                borderRadius: '10px',
                                                padding: '0px 8px'
                                              }}>
                                              {children}
                                            </Paper>
                                          )}
                                          renderInput={(params) => {
                                            return (
                                              <TextField
                                                {...params}
                                                variant="standard"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  sx: {
                                                    color: Colors.WHITE,
                                                    fontSize: '13px',
                                                    width: !isError?.status ? '9vw' : '100%'
                                                  }
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                        {!isError?.status && (
                                          <Tooltip title={isError?.message}>
                                            <Error
                                              sx={{
                                                fontSize: '20px',
                                                color: Colors.RED
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Grid>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%'
                                        }}>
                                        <input
                                          style={{
                                            border: 'none',
                                            whiteSpace: 'nowrap',
                                            color: Colors.WHITE,
                                            backgroundColor: 'transparent',
                                            outline: 'none',
                                            padding: '0px !important',
                                            width: '100%',
                                            borderBottom: '1px solid black'
                                          }}
                                          value={obj[items][index]}
                                          onChange={(e) => handleFieldChange(e, index, items)}
                                        />
                                        {!isError?.status && (
                                          <Tooltip title={isError?.message}>
                                            <Error
                                              sx={{
                                                fontSize: '20px',
                                                color: Colors.RED
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {items === 'Description' &&
                                annotationType !== INVOICE &&
                                annotationType !== 'Estimate' &&
                                annotationType !== DELIVERY_TICKET && (
                                  <Tooltip title={DUPLICATE_LINEITEM}>
                                    <IconButton onClick={() => duplicateLineItem(index)}>
                                      <ContentCopy
                                        sx={{ color: Colors.TOURQUISE, cursor: 'pointer' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              <input
                                style={{
                                  border: 'none',
                                  whiteSpace: 'nowrap',
                                  color: Colors.WHITE,
                                  backgroundColor: 'transparent',
                                  outline: 'none',
                                  padding: '0px !important',
                                  width: '100%',
                                  borderBottom: '1px solid black'
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' && items === 'Amount') {
                                    handleAmountCalculator(e, index, 'lineitem', items);
                                  }
                                }}
                                onBlur={(e) => {
                                  if (items === 'Amount') {
                                    handleAmountCalculator(e, index, 'lineitem', items);
                                  }
                                }}
                                value={obj[items][index]}
                                onChange={(e) => handleFieldChange(e, index, items)}
                              />
                              {!isError?.status ? (
                                <Tooltip title={isError?.message}>
                                  <Error
                                    sx={{
                                      fontSize: '20px',
                                      color: Colors.RED
                                    }}
                                  />
                                </Tooltip>
                              ) : !isMatched &&
                                (isPoMatched || isInvoiceNumberMatched?.status) &&
                                annotationType !== ESTIMATE &&
                                annotationType !== DELIVERY_TICKET &&
                                annotationType !== PURCHASE_ORDER ? (
                                items === 'Description' &&
                                isDescriptionMatched?.status === false ? (
                                  <Tooltip
                                    title={
                                      isDescriptionMatched?.value === ''
                                        ? LINEITEMS_NOT_FOUND
                                        : `Description is "${isDescriptionMatched?.value}" in the supporting document.`
                                    }>
                                    <Error
                                      sx={{
                                        fontSize: '20px',
                                        color: Colors.YELLOW
                                      }}
                                    />
                                  </Tooltip>
                                ) : (matchingCallsrc === 'subContract' ||
                                    matchingCallsrc === 'Po') &&
                                  items === 'Amount' &&
                                  isDescriptionMatched?.status &&
                                  isAmountMatched?.status === false ? (
                                  <Tooltip
                                    title={`Amount is "${isAmountMatched?.value}" in the supporting document`}>
                                    <Error
                                      sx={{
                                        fontSize: '20px',
                                        color: Colors.YELLOW
                                      }}
                                    />
                                  </Tooltip>
                                ) : matchingCallsrc === DELIVERY_TICKET &&
                                  items === 'Quantity' &&
                                  isDescriptionMatched?.status &&
                                  isQuantityMatched?.status === false ? (
                                  <Tooltip
                                    title={`Quantity is "${isQuantityMatched?.value}" in the supporting document`}>
                                    <Error
                                      sx={{
                                        fontSize: '20px',
                                        color: Colors.YELLOW
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ''
                                )
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </StyledTableCell>
                      )
                    );
                  })}
                  <StyledTableCell
                    sx={{
                      p: 0,
                      border: 'none'
                    }}>
                    <Tooltip title="Remove Line item">
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <Remove sx={{ color: Colors.RED, cursor: 'pointer' }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            height: !isEmpty(lineItem) ? 'auto' : '140px'
          }}>
          <Button
            startIcon={<Add />}
            sx={{
              color: Colors.TOURQUISE,
              textTransform: 'none',
              marginTop: isEmpty(lineItem) ? '1.5rem' : '0rem'
            }}
            onClick={AddNewItem}>
            Add New Line Item
          </Button>
        </Grid>
      </TableContainer>
    </Grid>
  );
}
