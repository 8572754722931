import React, { useState } from 'react';

import { useNavigate } from 'react-router';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, Button, Typography, CircularProgress, Hidden } from '@mui/material';

import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import logo from '../../src/assets/BLogo.svg';
import { changePaymentStatus, changeManualPaymentStatus } from '../services/services';
import { Stripe, headings } from '../constants/appConstants';
import { Colors } from '../config/default';
import { useToast } from '../toast/toastContext';

export default function CheckoutForm({ price }) {
  const queryParams = new URLSearchParams(location.search);
  const manualCustomerEmail = queryParams.get('manualCustomerEmail');
  const callSource = sessionStorage.getItem('callSource');

  const customerEmail = sessionStorage.getItem('stripeEmail');
  const { HEADING } = Stripe;
  const { AUTH_HEADING, BEIING_HUMAN, PAYMENT_DONE } = headings;
  const navigate = useNavigate();
  const stripe = useStripe();

  const elements = useElements();
  const formatPrice = price / 100;
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost'
      },
      redirect: 'if_required'
    });
    if (result?.error) {
      showToast(result.error.message, 'error');
    } else {
      showToast(PAYMENT_DONE, 'success');

      if (manualCustomerEmail) {
        const manualUser = { email: manualCustomerEmail };
        const result = await changeManualPaymentStatus(manualUser);
        if (result?.status === 200) {
          navigate('/paymentSuccessful');
        }
      } else {
        const params = { customerEmail: customerEmail, callSource: callSource };
        const response = await changePaymentStatus(params);
        if (response?.status === 200) {
          navigate('/');
        }
      }
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.light',
          height: '100vh',
          backgroundSize: 'cover'
        }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '3rem',
              flexDirection: { xs: 'column', md: 'row' }
            }}>
            <Hidden mdDown>
              <Typography variant="h1">{AUTH_HEADING}</Typography>
            </Hidden>
            <img src={logo} alt="Logo" style={{ margin: '0 .7rem', width: '5em' }} />
            <Typography variant="h1">{BEIING_HUMAN}</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                sx={{
                  flexDirection: 'column'
                }}>
                <Typography
                  sx={{ marginBottom: '1.5rem', color: Colors.TEXTGREY, fontSize: '1.2rem' }}>
                  {HEADING}
                </Typography>
                <PaymentElement
                  options={{
                    paymentRequest: {
                      payment_methods: {
                        googlePay: false,
                        applePay: false
                      }
                    }
                  }}
                  style={{
                    borderRadius: '4px',
                    padding: '10px',
                    marginBottom: '1rem'
                  }}
                />
                <Grid container>
                  <Button
                    fullWidth
                    variant="outlined"
                    type="submit"
                    disabled={!stripe || loading}
                    sx={{
                      marginTop: '2rem',
                      borderRadius: '10px',
                      float: 'right',
                      height: '3.125rem',
                      color: Colors.WHITE
                    }}>
                    {loading ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      `Pay $${parseFloat(formatPrice).toFixed(2)}`
                    )}
                  </Button>
                </Grid>
                {errorMessage && <div>{errorMessage}</div>}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
