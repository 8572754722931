import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Check, Error, Info } from '@mui/icons-material';
import { Hidden, Button, Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import ViewDocument from './viewDocument';
import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';
import CustomDatePicker from './customDatePicker';
import BusinessAccountsList from './businessAccountsList';
import { replaceContentNames } from '../services/validationService';
import { validateData } from '../services/validationService';
import { canvasDetails, documentTypes, userRoles } from '../constants/appConstants';
import {
  vendorValueMatchForERP,
  isvendorValueMatching,
  getInputFieldClass
} from '../constants/utils';
import { InvoiceNumberKeys, formatDateOnly, getDocumentType } from '../services/common';
import '../css/canvas.css';

function ContentFields({
  content,
  vendors,
  actualValues,
  glInfo,
  annotationType,
  smallScreen,
  handleClick,
  handleTextClick,
  buttonColors,
  isPoMatched,
  isDateMatched,
  invoiceNumber,
  paymentTerms,
  setPaymentTerms,
  setOpen,
  open,
  images,
  checkDuplicationDumentNo,
  shouldCheckPoMatching,
  checkPoMatching,
  handleValueChange,
  handleSetValueState,
  handleBoxClick,
  setRef,
  imageDimensions,
  prepaid,
  paymentType,
  setVendors,
  policyBase,
  isAmountMatched,
  getUpdatedApproversAccordingtoBasePolicy,
  lineItemsTotalMatch,
  po,
  duplicateDocumentNoErrorObj,
  handleInputChange,
  isInvoiceNumberMatched,
  lineItemAmountSum,
  setDocumentoaded,
  documentoaded,
  setTypeCheck,
  typeCheck,
  documentType,
  setDocumentType,
  vendorsData
}) {
  const {
    CONTENT_VENDOR,
    CONTENT_TOTAL,
    CONTENT_PURCHASEORDER,
    DISCOUNT_PERCENTAGE,
    INVALID_VENDOR_NAME,
    FROM_CONTRACTOR,
    DOC_TYPE
  } = canvasDetails;
  const { ACCOUNTANT, OWNER } = userRoles;
  const { DELIVERY_TICKET, ESTIMATE } = documentTypes;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const duplicateDocNo = userDetails?.company?.duplicateDocNo;
  const erpType = userDetails?.user?.erpType;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );

  const paymentTermsError =
    !isvendorValueMatching(glInfo?.termsList, paymentTerms) &&
    erpType === 'Foundation' &&
    paymentTerms?.trim() !== '' &&
    paymentTerms !== null;

  const getTooltipMessage = (
    value,
    isError,
    annotationType,
    lineItemsTotalMatch,
    userDetails,
    duplicateDocumentNoErrorObj
  ) => {
    if (!isError?.status) return isError?.message;
    switch (value.name) {
      case CONTENT_TOTAL:
      case 'current_payment_due':
        return annotationType !== DELIVERY_TICKET && !lineItemsTotalMatch
          ? `Line items total is ${lineItemAmountSum}`
          : '';

      case CONTENT_PURCHASEORDER:
        return po !== '' && !isPoMatched && userDetails?.user?.poMatchPermission && isError?.status
          ? 'This does not exist in ERP'
          : '';

      case 'payment_terms':
        return erpType === 'Foundation' && paymentTerms?.trim() !== '' && paymentTerms !== null
          ? INVALID_VENDOR_NAME
          : '';

      default:
        return InvoiceNumberKeys.includes(value?.name) && duplicateDocumentNoErrorObj?.match
          ? duplicateDocumentNoErrorObj?.message
          : '';
    }
  };
  useEffect(() => {
    if (!documentoaded) {
      content?.forEach((value, idx) => {
        if (!smallScreen && value?.name === 'vendor' && !isEmpty(imageDimensions)) {
          const calculatedTop = value.top * imageDimensions.height + value.height;
          handleBoxClick(value?.name, value?.value, 'box_' + value.name, calculatedTop);
          handleClick(value.name, value.name, idx, content, true, value?.page, value?.confidence);
          setDocumentoaded(true);
        }
      });
    }
  }, [imageDimensions]);
  return (
    <>
      {content?.map((value, idx) => {
        const isError = validateData(
          idx === 0 ? vendors : actualValues[idx],
          value?.name,
          annotationType,
          prepaid,
          paymentType
        );
        const isErrorCondition =
          !isError?.status ||
          ((value.name === 'invoice_date' || value.name === 'receipt_date') &&
            isPoMatched &&
            isDateMatched?.status === false);

        const vendorValueError =
          vendorValueMatchForERP(glInfo, vendors, erpType, userDetails) || !isError?.status;

        return (
          <>
            {idx === 0 && (
              <Hidden mdUp>
                <Button
                  className="showMore_button"
                  variant="contained"
                  disabled={isEmpty(images)}
                  onClick={() => {
                    setOpen(true);
                  }}>
                  Show Document
                </Button>
                <ViewDocument selectedImage={images} setOpen={setOpen} open={open} />
              </Hidden>
            )}
            <div
              className="value-button"
              key={idx}
              onClick={() => {
                if (!smallScreen) {
                  handleBoxClick(
                    value?.name,
                    value?.value,
                    'box_' + value.name,
                    value.top * imageDimensions.height + value.height
                  );
                  handleClick(
                    value.name,
                    value.name,
                    idx,
                    content,
                    true,
                    value?.page,
                    value?.confidence
                  );
                }
              }}>
              <Box ref={setRef('button_' + value.name)} className="box-style">
                <p className="paragrph_style">
                  <Check
                    sx={{
                      fontSize: '1.5em',
                      marginRight: '.5rem',
                      color: buttonColors[idx] === true ? Colors.DARKBLUE : ''
                    }}
                    onClick={() => handleTextClick(idx, 'success')}
                  />
                  {replaceContentNames(value.name)}
                </p>
              </Box>

              {value?.name?.toLowerCase()?.includes('date') ? (
                <div className="input-wrapper">
                  <CustomDatePicker
                    value={actualValues[idx]}
                    onChange={handleValueChange}
                    stateValue={handleSetValueState}
                    idx={idx}
                    valueObj={value}
                    error={isErrorCondition}
                  />
                  {isErrorCondition && (
                    <HtmlTooltip
                      title={
                        <Typography color="inherit" sx={{ fontSize: '1em' }}>
                          {!isError?.status
                            ? isError?.message
                            : `Date is "${formatDateOnly(
                                isDateMatched?.value
                              )}" in the supporting document.`}
                        </Typography>
                      }>
                      <Error
                        sx={{
                          color: Colors.RED
                        }}
                      />
                    </HtmlTooltip>
                  )}
                </div>
              ) : value.name === 'payment_terms' && !isEmpty(glInfo?.termsList) ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={paymentTermsError ? 'dropdown-with-error' : 'first-input'}>
                    <BusinessAccountsList
                      permission={paymentTerms}
                      setPermission={setPaymentTerms}
                      question=""
                      data={glInfo?.termsList?.map((term) => term)}
                      color={Colors.WHITE}
                      allowInput={false}
                      name="paymentTerms"
                    />
                  </div>
                  {paymentTermsError && (
                    <HtmlTooltip
                      title={
                        <Typography color="inherit" sx={{ fontSize: '1em' }}>
                          {INVALID_VENDOR_NAME}
                        </Typography>
                      }>
                      <Error
                        sx={{
                          color: Colors.RED
                        }}
                      />
                    </HtmlTooltip>
                  )}
                </div>
              ) : value.name === CONTENT_VENDOR || value.name === FROM_CONTRACTOR ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!isEmpty(glInfo?.vendor) ? (
                    <div className={vendorValueError ? 'dropdown-with-error' : 'first-input'}>
                      <BusinessAccountsList
                        permission={vendors}
                        setPermission={setVendors}
                        question=""
                        data={glInfo?.vendor?.map((vendor) => vendor?.name)}
                        color={Colors.WHITE}
                        allowInput={false}
                        policyBase={policyBase}
                        getUpdatedApproversAccordingtoBasePolicy={
                          getUpdatedApproversAccordingtoBasePolicy
                        }
                        name="vendor"
                        documentType={documentType}
                      />
                    </div>
                  ) : (
                    <input
                      className={vendorValueError ? 'input-field-style-error' : 'input-field-style'}
                      value={actualValues[idx]}
                      onChange={(e) => {
                        handleValueChange(e.target.value, idx);
                        handleSetValueState(value, e.target.value);
                      }}
                      onBlur={() => {
                        if (
                          InvoiceNumberKeys.includes(value?.name) &&
                          duplicateDocNo &&
                          !isEmpty(invoiceNumber) &&
                          invoiceNumber !== '-' &&
                          vendors !== '' &&
                          vendors !== '-'
                        ) {
                          checkDuplicationDumentNo();
                        }
                        if (shouldCheckPoMatching(value)) {
                          checkPoMatching('Po', true);
                        }
                      }}
                    />
                  )}
                  {vendorValueError && (
                    <HtmlTooltip
                      title={
                        <Typography color="inherit" sx={{ fontSize: '1em' }}>
                          {!isError?.status ? isError?.message : INVALID_VENDOR_NAME}
                        </Typography>
                      }>
                      <Error
                        sx={{
                          color: Colors.RED
                        }}
                      />
                    </HtmlTooltip>
                  )}
                </div>
              ) : (
                <div className="input-wrapper">
                  <input
                    className={getInputFieldClass(
                      value,
                      isError,
                      userDetails,
                      isAmountMatched,
                      lineItemsTotalMatch,
                      annotationType,
                      duplicateDocumentNoErrorObj,
                      paymentTerms,
                      InvoiceNumberKeys
                    )}
                    value={actualValues[idx]}
                    onChange={(e) => {
                      handleValueChange(e.target.value, idx);
                      handleSetValueState(value, e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'Enter' &&
                        (value.name === 'total' || value.name === 'current_payment_due')
                      ) {
                        handleInputChange(e, idx, 'header');
                      }
                    }}
                    onBlur={(e) => {
                      if (value.name === 'total' || value.name === 'current_payment_due') {
                        handleInputChange(e, idx, 'header');
                      }
                      if (
                        InvoiceNumberKeys.includes(value?.name) &&
                        duplicateDocNo &&
                        !isEmpty(invoiceNumber) &&
                        invoiceNumber !== '-' &&
                        vendors !== '' &&
                        vendors !== '-'
                      ) {
                        checkDuplicationDumentNo();
                      }
                      if (shouldCheckPoMatching(value)) {
                        checkPoMatching('Po', true);
                      }
                    }}
                  />
                  {(((value.name === CONTENT_TOTAL || value.name === 'current_payment_due') &&
                    userDetails?.user?.poMatchPermission &&
                    isAmountMatched?.status === false) ||
                    value.name === 'discount' ||
                    ((value?.name === 'invoice_number' || value?.name === 'from_contractor') &&
                      isInvoiceNumberMatched?.status === false &&
                      userDetails?.user?.deliveryTicketUpload &&
                      isError?.status)) && (
                    <HtmlTooltip
                      title={
                        <Typography color="inherit" sx={{ fontSize: '1em' }}>
                          {value.name === 'discount'
                            ? DISCOUNT_PERCENTAGE
                            : value?.name === 'invoice_number' || value?.name === 'from_contractor'
                            ? `This is "${isInvoiceNumberMatched?.value}" in the supporting document`
                            : `Total is "${isAmountMatched?.value}" in the supporting document.`}
                        </Typography>
                      }>
                      {value.name === 'discount' ? (
                        <Info
                          sx={{
                            marginRight:
                              value.name === 'discount' && isError?.status ? '0' : '.5rem',
                            color: Colors.DISABLEDGRAY
                          }}
                        />
                      ) : (
                        <Error
                          sx={{
                            marginRight: '.5rem',
                            color: Colors.YELLOW
                          }}
                        />
                      )}
                    </HtmlTooltip>
                  )}
                  {getTooltipMessage(
                    value,
                    isError,
                    annotationType,
                    lineItemsTotalMatch,
                    userDetails,
                    duplicateDocumentNoErrorObj
                  ) && (
                    <HtmlTooltip
                      title={
                        <Typography color="inherit" sx={{ fontSize: '1em' }}>
                          {getTooltipMessage(
                            value,
                            isError,
                            annotationType,
                            lineItemsTotalMatch,
                            userDetails,
                            duplicateDocumentNoErrorObj
                          )}
                        </Typography>
                      }>
                      <Error
                        sx={{
                          color:
                            !isError?.status ||
                            (InvoiceNumberKeys.includes(value?.name) &&
                              duplicateDocumentNoErrorObj?.match) ||
                            value?.name === 'payment_terms'
                              ? Colors.RED
                              : Colors.YELLOW
                        }}
                      />
                    </HtmlTooltip>
                  )}
                </div>
              )}
            </div>
          </>
        );
      })}
      <div className="value-button">
        <Box className="box-style">
          <p className="paragraph-style">
            <Check
              sx={{
                fontSize: '1.5em',
                marginRight: '.5rem',
                color: typeCheck === true ? Colors.DARKBLUE : ''
              }}
              onClick={() => setTypeCheck(!typeCheck)}
            />
            {DOC_TYPE}
          </p>
        </Box>
        {userRole === ACCOUNTANT || userRole === OWNER ? (
          <div className="first-input">
            <BusinessAccountsList
              permission={documentType}
              setPermission={setDocumentType}
              question=""
              data={getDocumentType(
                userDetails?.user,
                vendorsData?.document?.mimeType === 'application/pdf' &&
                  vendorsData?.document?.convertedS3Keys?.length > 1
              )?.map((doc) => doc)}
              color={Colors.WHITE}
              allowInput={false}
            />
          </div>
        ) : (
          <div style={{ marginLeft: '.5rem' }} className="input-field" disabled>
            {annotationType === ESTIMATE
              ? annotationType?.replace(ESTIMATE, 'Quote')
              : annotationType === DELIVERY_TICKET
              ? annotationType?.replace(DELIVERY_TICKET, 'Delivery Ticket')
              : annotationType === 'Po'
              ? 'PO'
              : annotationType}
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(ContentFields);
