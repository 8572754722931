import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Box, CircularProgress, Typography, Hidden } from '@mui/material';

import LoadNextAndPrevDoc from './loadNextAndPrevDoc';
import BusinessAccountsList from './businessAccountsList';
import CustomDatePicker from './customDatePicker';
import Check from '@mui/icons-material/Check';
import { Colors } from '../config/default';
import { InvoiceNumberKeys, HtmlTooltip } from '../services/common';
import { canvasDetails, documentTypes } from '../constants/appConstants';
import { getApprovers } from '../services/services';
import { useToast } from '../toast/toastContext';
import '../css/canvas.css';

const RightSection = ({
  patternBackground,
  drawLine,
  imageDimensions,
  setToggleDraw,
  clickedBox,
  setClickedBoxRefrence,
  setClickedBox,
  isLoading,
  id,
  setCurrentId,
  resetStates,
  handleButtonClick,
  images,
  index,
  imgElement,
  content,
  ocrContent,
  boxPosition,
  replaceContentNames,
  actualValues,
  handleValueChange,
  handleSetValueState,
  handleInputChange,
  invoiceNumber,
  vendors,
  setVendors,
  checkDuplicationDumentNo,
  shouldCheckPoMatching,
  checkPoMatching,
  lineItem,
  arrangedLineitems,
  headers,
  clickedBoxIndex,
  obj,
  handleFieldChange,
  isDrawerOpen,
  glInfo,
  policyBase,
  getUpdatedApproversAccordingtoBasePolicy,
  paymentTerms,
  setPaymentTerms,
  scrollContainerRef,
  annotationType,
  setRef,
  buttonColors,
  handleTextClick,
  setApprovers,
  setImageDimensions,
  setCanvasDimensions,
  setIsDocumentSaved,
  currentStatus,
  documentType
}) => {
  const { CONTENT_VENDOR, FROM_CONTRACTOR, ZOOMING_ERROR } = canvasDetails;
  const { ESTIMATE, DELIVERY_TICKET } = documentTypes;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const duplicateDocNo = userDetails?.company?.duplicateDocNo;
  const { showToast } = useToast();
  const getAllApprover = async () => {
    const res = await getApprovers(id);
    if (res?.status === 200) {
      setApprovers(res?.data?.payload?.data);
    }
  };
  useEffect(() => {
    getAllApprover();
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === '=' || event.key === '-' || event.key === '0')
      ) {
        event.preventDefault();
        triggerZoomBlockMessage();
      }
    };
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        triggerZoomBlockMessage();
      }
    };
    const triggerZoomBlockMessage = () => {
      showToast(ZOOMING_ERROR, 'error');
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  const handleImageLoad = (imageRef, i) => {
    const dimensions = {
      width: imageRef.width,
      height: imageRef.height
    };
    const canvasSize = {
      width: window.innerWidth - 25,
      height: window.innerHeight - 87
    };
    setIsDocumentSaved(true);
    setImageDimensions(dimensions);
    setCanvasDimensions(canvasSize);
  };
  return (
    <Hidden mdDown>
      <Grid
        sx={{
          backgroundImage: `url(${patternBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 'calc( 100vh - 4.2rem )',
          width: 'calc( 100% - 450px )',
          display: 'flex',
          flex: '1 1',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Colors.Canvas_BG,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0.3em',
            height: '0em',
            borderRadius: '20px',
            zIndex: '999'
          }
        }}
        onClick={() => {
          drawLine('', '', '', '', imageDimensions);
          setToggleDraw('');
          if (clickedBox !== null) {
            setClickedBoxRefrence('');
            setClickedBox(null);
          }
        }}
        ref={scrollContainerRef}>
        {isLoading ? (
          <div style={{ display: 'flex' }}>
            <CircularProgress size={75} sx={{ color: Colors.DARKBLUE }} />
          </div>
        ) : (
          <>
            <LoadNextAndPrevDoc
              id={id}
              setCurrentId={setCurrentId}
              drawline={drawLine}
              imageDimensions={imageDimensions}
              resetStates={resetStates}
              handleSave={handleButtonClick}
              currentStatus={currentStatus}
            />
            <Grid
              sx={{
                maxHeight: '85.3vh',
                width: `${90}%`,
                position: 'relative'
              }}>
              {images?.length > 0 && (
                <>
                  <img
                    id="i"
                    src={images[index]}
                    alt={index}
                    ref={imgElement}
                    onClick={() => {
                      if (clickedBox !== null) {
                        setClickedBoxRefrence('');
                        setClickedBox(null);
                      }
                    }}
                    onLoad={() => handleImageLoad(imgElement.current)}
                    style={{
                      objectFit: 'contain',
                      width:
                        annotationType === 'Po'
                          ? '60%'
                          : annotationType === 'Receipt'
                          ? '80%'
                          : 'inherit',
                      marginBottom: '5em'
                    }}
                  />
                </>
              )}
              {content?.map(
                (value, idx) =>
                  (value?.page === index || value?.page === null) && (
                    <Box
                      ref={setRef('box_' + value.name)}
                      key={idx}
                      style={{
                        cursor: 'pointer',
                        height: value.height * imageDimensions.height,
                        background: '#00FF3C',
                        width: value.width * imageDimensions.width,
                        position: 'absolute',
                        top: value.top * imageDimensions.height,
                        left: value.left * imageDimensions.width,
                        opacity: 0.4,
                        zIndex: '1000'
                      }}></Box>
                  )
              )}
              {ocrContent?.map(
                (value, idx) =>
                  (value?.page === index || value?.page === null) && (
                    <HtmlTooltip
                      title={
                        <Typography color="black" sx={{ fontSize: '1.4em' }}>
                          {value.value}
                        </Typography>
                      }
                      backgroundColor={Colors.OCR_BG}
                      border={Colors.OCR_BORDER}>
                      <Box
                        key={idx}
                        style={{
                          cursor: 'pointer',
                          height: value.height * imageDimensions.height,
                          background: 'transparent',
                          width: value.width * imageDimensions.width,
                          position: 'absolute',
                          top: value.top * imageDimensions.height,
                          left: value.left * imageDimensions.width,
                          opacity: 0.4,
                          zIndex: '1000'
                        }}></Box>
                    </HtmlTooltip>
                  )
              )}
              {clickedBox && (
                <div
                  style={{
                    position: 'absolute',
                    top: boxPosition.top,
                    left: boxPosition.left,
                    backgroundColor: 'white',
                    padding: '10px 20px',
                    zIndex: 1000,
                    backgroundColor: Colors.Canvas_BG,
                    borderRadius: '15px',
                    width: '11rem'
                  }}
                  onClick={(e) => e.stopPropagation()}>
                  <p
                    style={{
                      fontSize: '.9rem',
                      color: Colors.TOURQUISE,
                      borderBottom: '1px solid #2E97A7'
                    }}>
                    <strong>{replaceContentNames(clickedBox.name)}</strong>
                  </p>
                  {content?.map(
                    (value, idx) =>
                      value.name === clickedBox.name && (
                        <>
                          <p key={idx} style={{ margin: '1.5rem 0rem' }}>
                            {idx === 0 ? (
                              (value.name === CONTENT_VENDOR || value.name === FROM_CONTRACTOR) &&
                              !isEmpty(glInfo?.vendor) ? (
                                <div
                                  style={{
                                    width: '100%'
                                  }}>
                                  <BusinessAccountsList
                                    permission={vendors}
                                    setPermission={setVendors}
                                    question=""
                                    data={glInfo?.vendor?.map((vendor) => vendor?.name)}
                                    color={Colors.WHITE}
                                    allowInput={false}
                                    font="1.3rem"
                                    policyBase={policyBase}
                                    getUpdatedApproversAccordingtoBasePolicy={
                                      getUpdatedApproversAccordingtoBasePolicy
                                    }
                                    name="vendor"
                                    documentType={documentType}
                                  />
                                </div>
                              ) : (
                                <input
                                  className="right_section_input"
                                  value={actualValues[idx]}
                                  onBlur={() => {
                                    if (
                                      (value.name === CONTENT_VENDOR ||
                                        value.name === FROM_CONTRACTOR) &&
                                      policyBase === 'vendor' &&
                                      documentType !== ESTIMATE &&
                                      documentType !== DELIVERY_TICKET
                                    ) {
                                      getUpdatedApproversAccordingtoBasePolicy(actualValues[idx]);
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleValueChange(e.target.value, idx);
                                    if (
                                      value.name === CONTENT_VENDOR ||
                                      value.name === FROM_CONTRACTOR
                                    ) {
                                      setVendors(e.target.value);
                                    }
                                    handleSetValueState(value, e.target.value);
                                  }}
                                />
                              )
                            ) : value.name.toLowerCase().includes('date') ? (
                              <div className="input-wrapper">
                                <CustomDatePicker
                                  value={actualValues[idx]}
                                  onChange={handleValueChange}
                                  idx={idx}
                                />
                              </div>
                            ) : value.name === 'payment_terms' && !isEmpty(glInfo?.termsList) ? (
                              <div
                                style={{
                                  width: '100%'
                                }}>
                                <BusinessAccountsList
                                  permission={paymentTerms}
                                  setPermission={setPaymentTerms}
                                  question=""
                                  data={glInfo?.termsList?.map((term) => term)}
                                  color={Colors.WHITE}
                                  allowInput={false}
                                  font="1.3rem"
                                  name="paymentTerms"
                                />
                              </div>
                            ) : (
                              <input
                                className="right_section_input"
                                value={actualValues[idx]}
                                onChange={(e) => {
                                  handleValueChange(e.target.value, idx);
                                  handleSetValueState(value, e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === 'Enter' &&
                                    (value.name === 'total' || value.name === 'current_payment_due')
                                  ) {
                                    handleInputChange(e, idx, 'header');
                                  }
                                }}
                                onBlur={(e) => {
                                  if (
                                    value.name === 'total' ||
                                    value.name === 'current_payment_due'
                                  ) {
                                    handleInputChange(e, idx, 'header');
                                  }
                                  if (
                                    InvoiceNumberKeys.includes(value?.name) &&
                                    duplicateDocNo &&
                                    !isEmpty(invoiceNumber) &&
                                    invoiceNumber !== '-' &&
                                    vendors !== '' &&
                                    vendors !== '-'
                                  ) {
                                    checkDuplicationDumentNo();
                                  }
                                  if (shouldCheckPoMatching(value)) {
                                    checkPoMatching('Po', true);
                                  }
                                }}
                              />
                            )}
                          </p>
                          <div style={{ float: 'right' }}>
                            <Check
                              sx={{
                                fontSize: '1.8rem',
                                marginRight: '.5rem',
                                cursor: 'pointer',
                                color: buttonColors[idx] === true ? Colors.DARKBLUE : Colors.WHITE
                              }}
                              onClick={() => {
                                handleTextClick(idx, 'success');
                                drawLine('', '', '', '', imageDimensions);
                                setToggleDraw('');
                                setClickedBoxRefrence('');
                                setClickedBox(null);
                              }}
                            />
                          </div>
                        </>
                      )
                  )}
                  {arrangedLineitems(lineItem)?.map((element, index) => {
                    return element?.map((elem, idxs) => {
                      const boxIndex = `${index}${idxs}`;
                      return headers?.map((items, idx) => {
                        if (items === clickedBox.name && boxIndex === clickedBoxIndex) {
                          return (
                            <>
                              <input
                                key={`${items}-${index}`}
                                className="right_section_input"
                                value={obj[items][index]}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' && items === 'Amount') {
                                    handleInputChange(e, index, 'lineitem', items);
                                  }
                                }}
                                onBlur={(e) => {
                                  if (items === 'Amount') {
                                    handleInputChange(e, index, 'lineitem', items);
                                  }
                                }}
                                onChange={(e) => handleFieldChange(e, index, items)}
                              />
                              <div style={{ float: 'right' }}>
                                <Check
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '1.8rem',
                                    color: Colors.DARKBLUE
                                  }}
                                  onClick={() => {
                                    drawLine('', '', '', '', imageDimensions);
                                    setToggleDraw('');
                                    setClickedBoxRefrence('');
                                    setClickedBox(null);
                                  }}
                                />
                              </div>
                            </>
                          );
                        }
                        return null;
                      });
                    });
                  })}
                </div>
              )}
              {arrangedLineitems(lineItem)?.map((element, parentIndex) => {
                return element?.map((items, idx) => {
                  if (
                    items?.page === index ||
                    items?.page === null ||
                    items?.page === '-' ||
                    items?.page === ''
                  ) {
                    return (
                      <Box
                        ref={setRef('box_' + items?.value + '**%**' + parentIndex + idx)}
                        key={idx}
                        style={{
                          height: items.height * imageDimensions.height,
                          background: '#00FF3C',
                          width: items.width * imageDimensions.width,
                          position: 'absolute',
                          top: items.top * imageDimensions.height,
                          left: items.left * imageDimensions.width,
                          opacity: 0.4,
                          cursor: 'pointer'
                        }}></Box>
                    );
                  }
                  return '';
                });
              })}
              {isDrawerOpen && <Grid sx={{ height: '280px' }}></Grid>}
            </Grid>
          </>
        )}
      </Grid>
    </Hidden>
  );
};

export default React.memo(RightSection);
