import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Box, IconButton, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import { Colors } from '../config/default';
import Btn from './button';
import { truncateFileName } from '../services/common';
import Prompt from './prompt';
import { useToast } from '../toast/toastContext';
import { removeAttachment, getDocumentDetails } from '../services/services';
import MuiModal from './muiModel';
import { userRoles } from '../constants/appConstants';

export default function ActivityAttachment({ id, userRole }) {
  const { ADMIN } = userRoles;

  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);  
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const { showToast } = useToast();
  const [openPrompt, setOpenPrompt] = useState(false);
  const [currentItemId, setCurrentItemId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const scrollContainerRef = useRef(null); 
  const [enlargedStates, setEnlargedStates] = useState([]);
  const [currentAttachmentIndices, setCurrentAttachmentIndices] = useState([]);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  const selectedData = async () => {
    setLoading(true);
    let result;
    if (userDetails?.role === ADMIN) {
      result = await getErrorDocument(id);
    } else {
      result = await getDocumentDetails(id, callSource);
    }
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else if (result?.response?.status === 403) {
      navigate('/unauthorized');
    }
    setAttachments(result?.data?.payload?.data?.attachments || []);
    setLoading(false); 
  };

  useEffect(() => {
    selectedData();
  }, []);

  useEffect(() => {
    setCurrentAttachmentIndices(Array(attachments?.length).fill(0));
    setEnlargedStates(Array(attachments?.length).fill(false));

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [attachments]); 

  const handleDelete = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    const result = await removeAttachment(id, currentItemId, companyId);
    if (result?.status === 200) {
      const response = await getDocumentDetails(id, callSource);
      if (response?.status === 200) {
        setAttachments(response?.data?.payload?.data?.attachments);
        showToast(result?.data?.metadata?.message, 'success');
        setOpenPrompt(false);
      }
    } else {
      setOpenPrompt(false);
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };
  const handleNextAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      newIndices[i] = (newIndices[i] + 1) % attachments[i]?.convertedS3Keys?.length;
      return newIndices;
    });
  };

  const handlePrevAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      const nextIndex = newIndices[i] - 1;
      newIndices[i] = nextIndex < 0 ? attachments[i]?.convertedS3Keys?.length - 1 : nextIndex;
      return newIndices;
    });
  };

  const handleImageClick = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    setEnlargedStates((prev) => {
      const newEnlargedStates = [...prev];
      newEnlargedStates[i] = !newEnlargedStates[i];
      return newEnlargedStates;
    });
  };

  const smallImageStyle = {
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
    opacity: 1,
    zIndex: 1,
    aspectRatio: 1
  };

  const largeImageStyle = {
    width: '100vw',
    height: '100vh',
    objectFit: 'contain',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    cursor: 'pointer',
    opacity: 1
  };

  return (
    <Grid
      sx={{
        height: '91vh',
        width: { xs: '20em', sm: '30em', xl: '42em' },
        borderRadius: '1em',
        backgroundColor: Colors.BG_BLACK,
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1em 0.8em'
      }}>
      

      {loading ? ( 
        <Grid container sx={{ height: '92%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
        </Grid>
      ) : attachments?.length === 0 ? (
        <Grid
          container
          sx={{
            height: '92%',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.TEXTGREY
          }}>
          <Typography>No attachments uploaded yet</Typography>
        </Grid>
      ) : (
        <Grid
          item
          sx={{
            height: '92%',
            padding: '0em 1em',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em',
              borderRadius: '10px',
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#555',
              borderRadius: '10px'
            }
          }}
          ref={scrollContainerRef}
        >
          {attachments?.map((item, i) => (
            <Grid key={i} item xs={12} sx={{ marginBottom: '1rem' }}>
              <Box
                sx={{
                  backgroundColor: Colors.Canvas_BG,
                  height: '35vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '20px',
                  position: 'relative',
                  padding: '.5rem 0'
                }}>
                {item?.convertedS3Keys?.length > 1 && (
                  <>
                    <IconButton
                      sx={{ position: 'absolute', left: '3%', color: Colors.WHITE }}
                      onClick={(e) => handlePrevAttachment(e, i)}>
                      <ChevronLeft />
                    </IconButton>
                    <IconButton
                      sx={{ position: 'absolute', right: '3%', color: Colors.WHITE }}
                      onClick={(e) => handleNextAttachment(e, i)}>
                      <ChevronRight />
                    </IconButton>
                  </>
                )}
                <>
                    <>
                      <img
                        alt="attachment"
                        src={item?.convertedS3Keys[currentAttachmentIndices[i]]}
                        style={smallImageStyle}
                        onClick={(e) => handleImageClick(e, i)}
                      />
                      {enlargedStates[i] && (
                        <img
                          alt="attachment"
                          src={item?.convertedS3Keys[currentAttachmentIndices[i]]}
                          style={largeImageStyle}
                          onClick={(e) => handleImageClick(e, i)}
                        />
                      )}
                    </>
                  </>
              </Box>
              <Grid container item sx={{ justifyContent: 'space-between', padding: '.5rem' }}>
                <Typography sx={{ color: Colors.WHITE }}>
                  {truncateFileName(item?.originalFileName, 16)}
                </Typography>
                <Btn
                  text="Remove"
                  color={Colors.WHITE}
                  height="2rem"
                  width="6rem"
                  backgroundColor={Colors.RED}
                  radius="10px"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentItemId(item?._id);
                    setOpenPrompt(true);
                  }}
                  sx={{
                    padding: '0.5rem',
                    fontSize: '0.875rem',
                    textTransform: 'none',
                    fontWeight: 'bold'
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
  
      <Grid
        sx={{
          padding: '1.5em 1em 0em 1em'
        }}>
        <MuiModal
          width="100%"
          text="Upload Attachment"
          show="attachment"
          refreshAttachments={selectedData}
          id={id}
        />
      </Grid>
  
      <Prompt
        title="Delete Attachment"
        description="Are you sure you want to delete this attachment?"
        leftButton="Cancel"
        rightButton="Confirm"
        setOpen={setOpenPrompt}
        open={openPrompt}
        handleClick={(e) => handleDelete(e)}
        loading={isLoading}
        opacity={true}
      />
    </Grid>
  );
  
}

