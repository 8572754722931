import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, CircularProgress, Typography, Box } from '@mui/material';
import { Sync, FileUpload, FileDownload, Download } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../theme';
import TabPanel from './tabs';
import { Colors } from '../config/default';
import {
  dropzoneData,
  AdminTable,
  TooltipMessages,
  ConstructionCompanySyncArray,
  UserSyncArray,
  userRoles
} from '../constants/appConstants';
import SearchBar from './searchBar';
import { getReport } from '../services/services';
import UploadManualFile from './uploadManualFile';
import { HtmlTooltip } from '../services/common';
import MuiModal from './muiModel';
import { useToast } from '../toast/toastContext';
import DownloadFile from './downloadFile';
import CustomSelect from './customSelect';
import UserHearder from './userHeader';
import DocumentHeader from './documentHeader';

function HeaderSection({
  currentTabs,
  setActiveTab,
  activeTab,
  intervalCall,
  checkedIds,
  setCheckedIds,
  filteredData,
  recordExist,
  setRecordExist,
  tab,
  integrationChildTab,
  handleSyncErp,
  erpLoading,
  currentUser,
  searchProducts,
  setSearchProducts,
  getAllVendors,
  getUsers,
  getManualUser,
  dataAccordingTabs,
  handleClearErpSearch,
  searchedCheques,
  isChequeSearched,
  chequeNum,
  handleSearchFromErp,
  setChequeNum,
  selectedRowsHasPO,
  documentCount,
  allQueuesData,
  setFilteredData,
  setGetDocument,
  amount,
  setAmount,
  recieivedAtDate,
  setRecieivedAtDate,
  byEmail,
  setByEmail,
  recieivedFromDate,
  setRecieivedFromDate,
  permission,
  setPermission,
  selectedOptions,
  setSelectedOptions,
  getAppliedFilters,
  handleFilterandSearchClear,
  selectedRowsHasReview,
  splitDocIds,
  getbatches,
  byPaymentStatus,
  setByPaymentStatus,
  setParentActiveTab
}) {
  const navigate = useNavigate();
  const [loadingCheckbox, setLoadingCheckbox] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const SyncArray = isConstructionCompany ? ConstructionCompanySyncArray : UserSyncArray;
  const erpType = userDetails?.user?.erpType;
  const { showToast } = useToast();

  const { SYNC, DOWNLOAD_REPORT, HELPER_FOR_REPORT } = AdminTable;
  const { INFO } = dropzoneData;
  const { ADMIN, VENDOR } = userRoles;
  const { SYNC_DATA, MANUAL_UPLOAD, MANUAL_DOWNLOAD } = TooltipMessages;

  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const searchedData = sessionStorage.getItem('searchedKey');

  useEffect(() => {
    if (searchedData && searchedData !== '' && searchedData !== 'undefined') {
      setGetDocument((prev) => ({
        ...prev,
        search: true
      }));
      setSearchProducts(searchedData);
      allQueuesData(searchedData);
    }
  }, []);

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleSyncClose = (event, selectedValue) => {
    setAnchorEl(null);
    if (typeof selectedValue !== 'string') {
      handleSyncErp(activeIntegration, selectedValue);
    }
  };

  const downloadReport = async () => {
    setDownloading(true);
    const response = await getReport();
    if (response?.status === 200) {
      window.open(response?.data?.payload?.data?.signedUrl, '_blank');
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setDownloading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          borderBottom: { xs: 'none', md: '2px solid #2A2A2A' },
          alignItems: { lg: 'center' },
          justifyContent: 'space-between',
          height: { xs: 'max-content', lg: '3.3rem' },
          paddingX: { xs: '1em', lg: '2rem' }
        }}>
        {tab === 'documents' ? (
          <TabPanel
            tabs={currentTabs}
            peddingBottom="1.5rem"
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            filteredData={filteredData}
            recordExist={recordExist}
            handleClear={handleFilterandSearchClear}
            tab="child"
            setCheckedIds={setCheckedIds}
            handleClearErpSearch={handleClearErpSearch}
            getAppliedFilters={getAppliedFilters}
          />
        ) : (
          <TabPanel
            tabs={currentTabs}
            peddingBottom="1.5rem"
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setCheckedIds={setCheckedIds}
            handleClearErpSearch={handleClearErpSearch}
          />
        )}

        {tab === 'documents' ? (
          <DocumentHeader
            activeTab={activeTab}
            intervalCall={intervalCall}
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
            filteredData={filteredData}
            recordExist={recordExist}
            setRecordExist={setRecordExist}
            currentUser={currentUser}
            searchProducts={searchProducts}
            setSearchProducts={setSearchProducts}
            dataAccordingTabs={dataAccordingTabs}
            selectedRowsHasPO={selectedRowsHasPO}
            allQueuesData={allQueuesData}
            setFilteredData={setFilteredData}
            setGetDocument={setGetDocument}
            amount={amount}
            setAmount={setAmount}
            recieivedAtDate={recieivedAtDate}
            setRecieivedAtDate={setRecieivedAtDate}
            byEmail={byEmail}
            setByEmail={setByEmail}
            recieivedFromDate={recieivedFromDate}
            setRecieivedFromDate={setRecieivedFromDate}
            permission={permission}
            setPermission={setPermission}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getAppliedFilters={getAppliedFilters}
            selectedRowsHasReview={selectedRowsHasReview}
            splitDocIds={splitDocIds}
            getbatches={getbatches}
            byPaymentStatus={byPaymentStatus}
            setByPaymentStatus={setByPaymentStatus}
            setLoadingCheckbox={setLoadingCheckbox}
            loadingCheckbox={loadingCheckbox}
            setActiveTab={setParentActiveTab}
          />
        ) : tab === 'Dashboard' && userRole !== ADMIN && userRole !== VENDOR ? (
          <Grid
            container
            item
            xs={12}
            lg={7.5}
            sx={{
              gap: { xs: '8px', sm: '3px' },
              height: { xs: 'max-content', md: '3.3rem' },
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', lg: '0' },
              marginBottom: '.8rem'
            }}>
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {HELPER_FOR_REPORT}
                </Typography>
              }>
              <Button
                disabled={
                  documentCount?.totalDocuments === documentCount?.Approved ||
                  documentCount?.totalDocuments === 0 ||
                  downloading
                }
                sx={{
                  textTransform: 'unset !important',
                  marginRight: '1rem',
                  color: Colors.WHITE,
                  height: '2.2rem',
                  width: '5.5rem',
                  fontSize: '.875rem',
                  borderRadius: '10px'
                }}
                variant="outlined"
                onClick={downloadReport}>
                {downloading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  <>
                    <Download
                      sx={{
                        fontSize: '18px',
                        marginRight: '.2rem',
                        color:
                          documentCount?.totalDocuments === documentCount?.Approved ||
                          documentCount?.totalDocuments === 0
                            ? 'gray'
                            : Colors.TOURQUISE
                      }}
                    />
                    {DOWNLOAD_REPORT}
                  </>
                )}
              </Button>
            </HtmlTooltip>
          </Grid>
        ) : tab === 'Users' && userRole !== ADMIN ? (
          <UserHearder getUsers={getUsers} erpLoading={erpLoading} handleSyncErp={handleSyncErp} />
        ) : tab === 'integration' && integrationChildTab === 'My Integration' ? (
          <Grid
            item
            sx={{
              display: 'flex',
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', sm: '0em' }
            }}>
            {erpType !== 'Foundation' ? ( // Added This to show Dropdown for Foundation
              <Button
                disabled={(activeIntegration === '' && erpType !== 'RentalMan') || erpLoading}
                sx={{
                  textTransform: 'unset !important',
                  height: '2.5em',
                  color: Colors.WHITE,
                  borderRadius: '10px',
                  width: '6rem',
                  borderColor: Colors.DEEPBLUE,
                  '&:hover': {
                    borderColor: Colors.DEEPBLUE
                  }
                }}
                variant="outlined"
                onClick={() => handleSyncErp(activeIntegration)}>
                {erpLoading ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {SYNC_DATA}
                      </Typography>
                    }>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <Sync
                        sx={{ color: Colors.DEEPBLUE, fontSize: '1.4em', marginRight: '.3em' }}
                      />
                      {SYNC}
                    </Box>
                  </HtmlTooltip>
                )}
              </Button>
            ) : (
              <>
                {/* <MuiModal   // Enable it for QBO when needed
                  text="Queue"
                  width="5.2rem"
                  show="QBD Queue"
                  mr=".5rem"
                  color={Colors.TOURQUISE}
                /> */}
                <CustomSelect
                  disabled={activeIntegration === ''}
                  text={SYNC}
                  handleClose={handleSyncClose}
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  options={SyncArray}
                  height="2.25rem"
                  width="50%"
                  backgroundColor="none"
                  variant="outlined"
                  loadingCheckbox={erpLoading}
                  show="syncCall"
                />
              </>
            )}
          </Grid>
        ) : tab === 'integration' && integrationChildTab === 'Manual Syncing' ? (
          <Grid
            item
            sx={{
              display: 'flex',
              gap: { xs: '1em', md: '0' },
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end'
            }}>
            <DownloadFile
              tooltipText={MANUAL_DOWNLOAD}
              width="9rem"
              text={'Sample Files'}
              height="2.2rem"
              icon={
                <FileDownload
                  sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }}
                />
              }
            />
            <UploadManualFile
              handleClose={handleClose}
              setAnchorEl={setAnchorEl}
              loadingCheckbox={loadingCheckbox}
              tooltipText={MANUAL_UPLOAD}
              anchorEl={anchorEl}
              width="9rem"
              text={INFO}
              height="2.2rem"
              icon={
                <FileUpload
                  sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }}
                />
              }
            />
          </Grid>
        ) : tab === 'integration' && integrationChildTab === 'Search Integration' ? (
          <Box sx={{ mt: { xs: '1rem', lg: '0rem' }, mb: '1.5rem' }}>
            <SearchBar
              width="600px"
              setSearchProducts={setChequeNum}
              text="Search Invoices by check Number..."
              searchProducts={chequeNum}
              tooltipText="You can Search Invoices by check Number"
              handleKeyPress={handleSearchFromErp}
              handleClear={handleClearErpSearch}
              recordExist={isChequeSearched}
              filteredData={searchedCheques}
            />
          </Box>
        ) : activeTab === 'Manual Users' && userRole === ADMIN ? (
          <Grid
            item
            sx={{
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', md: '0' }
            }}>
            <MuiModal
              text="Invite User"
              width="8rem"
              show="manual users"
              getManualUser={getManualUser}
            />
          </Grid>
        ) : activeTab === 'All Vendors' && userRole === ADMIN ? (
          <Grid
            item
            sx={{
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', md: '0' }
            }}>
            <MuiModal
              text="Add Vendor"
              width="8rem"
              show="register vendor"
              getAllVendors={getAllVendors}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default HeaderSection;
