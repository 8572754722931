import { isEmpty } from 'lodash';

export const codingfieldStates = {
  serviceCode: '',
  equipmentNo: '',
  glDivision: '',
  costType: '',
  costId: '',
  jobId: '',
  phase: '',
  glAccount: '',
  division2: '',
  division3: '',
  division4: ''
};

export const verifySameCodesForHeaderAndLineItems = (lineItems, fields, accountCodeArray) => {
  if (!lineItems || Object.keys(lineItems)?.length === 0 || isEmpty(accountCodeArray)) return false;

  // Mapping fields to corresponding lineItem names
  const fieldToLineItemMap = {
    serviceCode: 'serviceCode',
    equipmentNo: 'equipmentNo',
    glDivision: 'glDivision',
    costType: 'userCostType',
    costId: 'userCostId',
    jobId: 'userJobId',
    phase: 'userPhases',
    glAccount: 'GlAccount',
    division2: 'division2',
    division3: 'division3',
    division4: 'division4'
  };

  // Check if any field value is different from the corresponding lineItem value
  return Object.entries(fieldToLineItemMap)?.some(([fieldKey, lineItemKey]) => {
    if (!lineItemKey) return false; // Ignore fields without a mapping
    if (!lineItems[lineItemKey] || lineItems[lineItemKey]?.length !== 1) return false; // Ignore if array length ≠ 1

    const fieldVal = fields[fieldKey] || '';
    const lineItemVal = lineItems[lineItemKey][0] || ''; // Extract first value or default to ""

    if (fieldVal !== lineItemVal) {
      return true; // Mismatch found
    }
    return false;
  });
};
