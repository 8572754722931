import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Button, Grid, CircularProgress, Typography } from '@mui/material';
import { FileUploadOutlined, InsertPageBreak, Download } from '@mui/icons-material';
import { get_tabs } from '../redux/actions/action';

import UploadFileButton from './uploadFileButton';
import { Colors } from '../config/default';
import {
  dropzoneData,
  AdminTable,
  TooltipMessages,
  userRoles,
  tableComponent
} from '../constants/appConstants';
import SearchBar from './searchBar';
import {
  downloadCSV,
  downloadFile,
  deleteDocument,
  deleteBatchDoc,
  updateExportCsvStatus
} from '../services/services';
import { HtmlTooltip } from '../services/common';
import CustomDrawer from './customDrawer';
import Prompt from './prompt';
import { useToast } from '../toast/toastContext';

export default function DocumentHeader({
  activeTab,
  intervalCall,
  checkedIds,
  setCheckedIds,
  filteredData,
  recordExist,
  setRecordExist,
  currentUser,
  searchProducts,
  setSearchProducts,
  dataAccordingTabs,
  selectedRowsHasPO,
  allQueuesData,
  setFilteredData,
  setGetDocument,
  amount,
  setAmount,
  recieivedAtDate,
  setRecieivedAtDate,
  byEmail,
  setByEmail,
  recieivedFromDate,
  setRecieivedFromDate,
  permission,
  setPermission,
  selectedOptions,
  setSelectedOptions,
  getAppliedFilters,
  selectedRowsHasReview,
  splitDocIds,
  getbatches,
  byPaymentStatus,
  setByPaymentStatus,
  setLoadingCheckbox,
  loadingCheckbox,
  setActiveTab
}) {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const docSplitterPermission = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.docSpliterPermission
  );
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const searchedData = sessionStorage.getItem('searchedKey');

  const [uploadDocScreen, setUploadDocScreen] = useState(false);
  const [uploadPdfScreen, setUploadPdfScreen] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [downloadAll, setDownloadAll] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [open, setOpen] = useState(false);
  const { EXPORTABLE_FILE } = AdminTable;
  const { INFO, SPLIT_PDF } = dropzoneData;
  const { OWNER, ACCOUNTANT } = userRoles;
  const { DELETE_DOCUMENTS, DELETE_SELECTED_DOCUMENTS, DELETE } = tableComponent;
  const {
    SPLIT_PDF_MESSAGE,
    UPLOAD_FILE_MESSAGE,
    SEARCH_MESSAGE,
    DOWNLOAD_FILES,
    DOWNLOAD_ALL_FILES
  } = TooltipMessages;


  const handleClear = () => {
    setSearchProducts('');
    sessionStorage?.setItem('searchedKey', '');
    setFilteredData([]);
    setGetDocument((prev) => ({
      ...prev,
      search: false
    }));
    setRecordExist(false);
    setCheckedIds([]);
  };
  const handleCsvDownload = async () => {
    setLoadingCheckbox(true);
    const params = {
      documentIds: checkedIds,
      erpType: erpType
    };
    const response = await downloadCSV(params);
    if (response?.status === 200) {
      setCheckedIds([]);
      intervalCall();
      setActiveTab('Downloads');
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setLoadingCheckbox(false);
  };

  const handleSearch = () => {
    setGetDocument((prev) => ({
      ...prev,
      search: searchProducts && searchProducts !== '' && searchProducts !== 'undefined'
    }));
  };

  const deleteDocs = async () => {
    setDeleting(true);
    const params = {
      documentIds: checkedIds
    };
    const result = await deleteDocument(params);
    if (result?.status === 200) {
      allQueuesData(searchedData);
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
    setOpenPrompt(false);
  };

  const deleteSplitDocs = async () => {
    setDeleting(true);
    const params = {
      docIds: splitDocIds
    };
    const result = await deleteBatchDoc(params);
    if (result?.status === 200) {
      getbatches();
      allQueuesData(searchedData);
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
    setOpenPrompt(false);
  };

  const extractDocumentIds = () => {
    return dataAccordingTabs?.map((doc) => doc?._id);
  };

  const handleDownloadFiles = async (e, ids) => {
    const params = {
      documentIds: ids
    };
    const response = await downloadFile(params);
    if (response?.status === 200) {
      if (response?.data?.payload?.data?.length === 1) {
        response?.data?.payload?.data?.forEach((file) => {
          DownloadDocument(file?.s3_file_name, file?.signedUrl);
        });
      } else {
        const zip = new JSZip();
        await Promise?.all(
          response?.data?.payload?.data?.map(async (file) => {
            const fileBlob = await fetch(file?.signedUrl).then((res) => res?.blob());
            zip?.file(file?.s3_file_name, fileBlob);
          })
        );
        const zipBlob = await zip?.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'downloaded_files.zip');
      }
      setCheckedIds([]);
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setDownloadFiles(false);
    setDownloadAll(false);
  };
  const DownloadDocument = (s3FileName, signedUrl) => {
    const link = document.createElement('a');
    link.href = signedUrl;
    link.setAttribute('download', s3FileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAllFiles = (e) => {
    setDownloadAll(true);
    const documentIds = extractDocumentIds();
    handleDownloadFiles(e, documentIds);
  };

  return (
    <Grid
      container
      item
      xs={12}
      lg={7}
      sx={{
        gap: { xs: '8px', sm: '3px' },
        height: { xs: 'max-content', md: '3.3rem' },
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: { xs: '1em', lg: '0' },
        marginBottom: '.8rem'
      }}>
      {(!isEmpty(filteredData) || recordExist) &&
        isEmpty(checkedIds) &&
        currentUser !== 'Vendor' && (
          <>
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {DOWNLOAD_ALL_FILES}
                </Typography>
              }>
              <span>
                <Button
                  disabled={downloadFiles || isEmpty(dataAccordingTabs) || downloadAll}
                  variant="outlined"
                  sx={{
                    textTransform: 'unset !important',
                    marginRight: '1rem',
                    marginLeft: '.5rem',
                    width: '7.5rem',
                    height: '2.2rem',
                    color: Colors.WHITE,
                    borderRadius: '10px',
                    fontSize: '.875rem',
                    borderRadius: '10px',
                    '&:hover': {
                      borderColor: Colors.DEEPGREY
                    }
                  }}
                  onClick={handleDownloadAllFiles}>
                  {downloadAll ? (
                    <CircularProgress size={22} sx={{ color: Colors.TEXTGREY }} />
                  ) : (
                    'Download All'
                  )}
                </Button>
              </span>
            </HtmlTooltip>
          </>
        )}
      {(currentUser === 'Customer' || currentUser === 'Accountant') && isEmpty(checkedIds) && (
        <>
          {docSplitterPermission ? (
            <UploadFileButton
              icon={
                <InsertPageBreak
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    marginRight: '.3rem',
                    color: Colors.RED,
                    fontSize: '1.3em'
                  }}
                />
              }
              text={SPLIT_PDF}
              borderColor={Colors.RED}
              uploadDocScreen={uploadDocScreen}
              uploadPdfScreen={uploadPdfScreen}
              setUploadDocScreen={setUploadDocScreen}
              setUploadPdfScreen={setUploadPdfScreen}
              setOpen={setOpen}
              tooltipText={SPLIT_PDF_MESSAGE}
              open={open}
              onClick={() => {
                setOpen(true);
                setUploadDocScreen(false);
                setUploadPdfScreen(true);
              }}
            />
          ) : (
            ''
          )}
          <UploadFileButton
            icon={
              <FileUploadOutlined
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  marginRight: '.1rem',
                  color: 'secondary.light',
                  fontSize: '1.5em'
                }}
              />
            }
            text={INFO}
            uploadDocScreen={uploadDocScreen}
            uploadPdfScreen={uploadPdfScreen}
            setUploadDocScreen={setUploadDocScreen}
            setUploadPdfScreen={setUploadPdfScreen}
            tooltipText={UPLOAD_FILE_MESSAGE}
            setOpen={setOpen}
            open={open}
            onClick={() => {
              setOpen(true);
              setUploadPdfScreen(false);
              setUploadDocScreen(true);
            }}
          />
        </>
      )}

      {((currentUser === 'Customer' || currentUser === 'Accountant') && isEmpty(checkedIds)) ||
      currentUser === 'Vendor' ? (
        ''
      ) : (
        <>
          {!isEmpty(checkedIds) &&
            !selectedRowsHasReview &&
            (userRole === OWNER || userRole === ACCOUNTANT) && (
              <>
                <Button
                  disabled={isEmpty(checkedIds) || deleting}
                  sx={{
                    textTransform: 'unset !important',
                    marginRight: '.5rem',
                    color: Colors.WHITE,
                    height: '2.2rem',
                    width: '4.8rem',
                    fontSize: '.875rem',
                    borderRadius: '10px'
                  }}
                  variant="outlined"
                  onClick={() => setOpenPrompt(true)}>
                  {DELETE}
                </Button>
                <Prompt
                  title={DELETE_DOCUMENTS}
                  description={DELETE_SELECTED_DOCUMENTS}
                  leftButton="Cancel"
                  rightButton="Delete"
                  setOpen={setOpenPrompt}
                  open={openPrompt}
                  opacity={true}
                  handleClick={deleteDocs}
                  loading={deleting}
                />
              </>
            )}
          {!isEmpty(checkedIds) && (
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {DOWNLOAD_FILES}
                </Typography>
              }>
              <span>
                <Button
                  disabled={isEmpty(checkedIds) || downloadFiles}
                  variant="outlined"
                  sx={{
                    textTransform: 'unset !important',
                    marginRight: '.5rem',
                    width: '8.5rem',
                    height: '2.2rem',
                    color: Colors.WHITE,
                    borderRadius: '10px',
                    fontSize: '.875rem',
                    borderRadius: '10px',
                    '&:hover': {
                      borderColor: Colors.DEEPGREY
                    }
                  }}
                  onClick={(e) => {
                    handleDownloadFiles(e, checkedIds);
                    setDownloadFiles(true);
                  }}>
                  {downloadFiles ? (
                    <CircularProgress size={22} sx={{ color: Colors.TEXTGREY }} />
                  ) : (
                    'Download Files'
                  )}
                </Button>
              </span>
            </HtmlTooltip>
          )}
          {!selectedRowsHasPO && (
            <Button
              disabled={isEmpty(checkedIds) || loadingCheckbox}
              sx={{
                textTransform: 'unset !important',
                marginRight: '.5rem',
                color: Colors.WHITE,
                height: '2.2rem',
                width: '10rem',
                fontSize: '.875rem',
                borderRadius: '10px'
              }}
              variant="outlined"
              onClick={handleCsvDownload}>
              {loadingCheckbox ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                <>
                  <Download
                    sx={{
                      fontSize: '18px',
                      marginRight: '.2rem',
                      color: isEmpty(checkedIds) || loadingCheckbox ? 'gray' : Colors.TOURQUISE
                    }}
                  />
                  {EXPORTABLE_FILE}
                </>
              )}
            </Button>
          )}
        </>
      )}
      {activeTab !== 'Classify/Split' ? (
        <>
          <SearchBar
            width="230px"
            setSearchProducts={setSearchProducts}
            text="Search Documents..."
            searchProducts={searchProducts}
            tooltipText={SEARCH_MESSAGE}
            handleKeyPress={handleSearch}
            handleClear={handleClear}
            recordExist={recordExist}
            filteredData={filteredData}
          />
          <CustomDrawer
            amount={amount}
            setAmount={setAmount}
            recieivedAtDate={recieivedAtDate}
            setRecieivedAtDate={setRecieivedAtDate}
            byEmail={byEmail}
            setByEmail={setByEmail}
            recieivedFromDate={recieivedFromDate}
            setRecieivedFromDate={setRecieivedFromDate}
            permission={permission}
            setPermission={setPermission}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getAppliedFilters={getAppliedFilters}
            byPaymentStatus={byPaymentStatus}
            setByPaymentStatus={setByPaymentStatus}
            show="filters"
          />
        </>
      ) : (
        (userRole === OWNER || userRole === ACCOUNTANT) && (
          <>
            <Button
              disabled={isEmpty(splitDocIds) || deleting}
              sx={{
                textTransform: 'unset !important',
                marginRight: '.5rem',
                color: Colors.WHITE,
                height: '2.2rem',
                width: '7rem',
                fontSize: '.875rem',
                borderRadius: '10px'
              }}
              variant="outlined"
              onClick={() => setOpenPrompt(true)}>
              {DELETE}
            </Button>
            <Prompt
              title={DELETE_DOCUMENTS}
              description={DELETE_SELECTED_DOCUMENTS}
              leftButton="Cancel"
              rightButton="Delete"
              setOpen={setOpenPrompt}
              open={openPrompt}
              opacity={true}
              handleClick={deleteSplitDocs}
              loading={deleting}
            />
          </>
        )
      )}
    </Grid>
  );
}
