import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Paper,
  Typography
} from '@mui/material';
import { Check, Clear, Error } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

import { Colors } from '../config/default';
import { HtmlTooltip, replaceName } from '../services/common';
import { getDivision, getGlList } from '../constants/utils';
import { validateAccountDetails } from '../services/validationService';

export default function CoddingFields({
  glInfo,
  buttonColors,
  accountCodeArray,
  handleSetValueState,
  allowInput,
  setValueChanged,
  getUpdatedApproversAccordingtoBasePolicy,
  policyBase,
  annotationType,
  setActualaccountCodeValues,
  actualaccountCodeValues,
  handleTextClick,
  setFields
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const glDivisionPermission = userDetails?.company?.glDivisionPermission;

  const useStyles = makeStyles(() => ({
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));

  const classes = useStyles();

  const JobIdCalls = (name, value) => {
    if (name === 'userJobId' && value && value !== '') {
      if (policyBase === 'jobId') {
        getUpdatedApproversAccordingtoBasePolicy(value);
      }
      if (glDivisionPermission) {
        getDivision(value, setFields, setActualaccountCodeValues, accountCodeArray);
      }
    }
  };

  const handleValueChange = (value, index, name) => {
    let updatedArray = [...actualaccountCodeValues];
    updatedArray[index] = value;
    setActualaccountCodeValues(updatedArray);
    JobIdCalls(name, value);
  };

  return (
    <div>
      <p className="details-heading">Account Details</p>
      {accountCodeArray?.map((value, idx) => {
        const isError = validateAccountDetails(
          actualaccountCodeValues[idx],
          value.name,
          annotationType,
          erpType,
          isConstructionCompany
        );
        const data = getGlList(value?.name, glInfo) || [];
        return (
          <div className="value-button">
            <Box className="box-style">
              <p className="paragraph-style">
                <Check
                  sx={{
                    fontSize: '1.5em',
                    marginRight: '.5rem',
                    color: buttonColors[idx] === true ? Colors.DARKBLUE : ''
                  }}
                  onClick={() => handleTextClick(idx, 'success')}
                />
                {replaceName(value.name, erpType)}
              </p>
            </Box>
            <div style={{ display: 'flex', marginBottom: '.5rem' }}>
              {data && data?.length > 0 && !data?.includes(null) ? (
                <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
                  <div>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                      <Autocomplete
                        value={actualaccountCodeValues[idx] || null}
                        onChange={(event, newValue) => {
                          setValueChanged(true);
                          handleValueChange(newValue, idx, value.name);
                          handleSetValueState(value, newValue);
                        }}
                        options={data}
                        isOptionEqualToValue={(option, value) =>
                          option?.toLowerCase() === value?.toLowerCase()
                        }
                        renderOption={(props, option) => {
                          const { key } = props;
                          return (
                            <li {...props} key={props['data-option-index'] + '-' + key}>
                              {option}
                            </li>
                          );
                        }}
                        clearIcon={
                          <Clear size="small" sx={{ color: Colors.WHITE, fontSize: '1rem' }} />
                        }
                        disableClearable={!actualaccountCodeValues[idx]}
                        classes={{ option: classes.option }}
                        ListboxProps={{
                          className: 'myCustomList'
                        }}
                        onKeyDown={(event) => {
                          const filteredOptions = data?.filter((option) =>
                            option?.toLowerCase().includes(event.target.value?.toLowerCase() || '')
                          );
                          if (event.key === 'Tab' && !isEmpty(filteredOptions)) {
                            const firstOption = filteredOptions[0];
                            setValueChanged(true);
                            handleValueChange(firstOption, idx, value.name);
                            handleSetValueState(value, firstOption);
                          }
                        }}
                        sx={{
                          '& .MuiAutocomplete-popupIndicator': {
                            color: Colors.WHITE
                          }
                        }}
                        PaperComponent={({ children }) => (
                          <Paper
                            style={{
                              backgroundColor: Colors.NAV_BLACK,
                              color: Colors.WHITE,
                              borderRadius: '10px',
                              padding: '0px 8px'
                            }}>
                            {children}
                          </Paper>
                        )}
                        renderInput={(params) => {
                          return (
                            <TextField
                              onBlur={(e) => {
                                JobIdCalls(value.name, e.target.value);
                                if (allowInput) {
                                  setValueChanged(true);
                                  handleValueChange(e.target.value, idx, value.name);
                                  handleSetValueState(value, e.target.value);
                                }
                              }}
                              value={actualaccountCodeValues[idx]}
                              sx={{
                                marginTop: { lg: '.7rem' }
                              }}
                              {...params}
                              variant="standard"
                              label=""
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  color: Colors.WHITE,
                                  fontSize: '13px'
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              ) : (
                <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
                  <input
                    style={{ marginTop: '.5rem' }}
                    className="input-field"
                    value={actualaccountCodeValues[idx]}
                    onChange={(e) => {
                      setValueChanged(true);
                      handleValueChange(e.target.value, idx, value.name);
                    }}
                    onBlur={(e) => {
                      JobIdCalls(value.name, e.target.value);
                      handleSetValueState(value, e.target.value);
                    }}
                  />
                </div>
              )}
              {!isError?.status && (
                <HtmlTooltip
                  title={
                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                      {isError?.message}
                    </Typography>
                  }>
                  <Error
                    sx={{
                      color: Colors.RED,
                      marginTop: '.7rem'
                    }}
                  />
                </HtmlTooltip>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
