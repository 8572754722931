import React from "react";
import { Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Colors } from "../config/default";
import BatchDocumentsDrawer from "./batchDocumentsDrawer";
import {exportBatches} from '../constants/appConstants'


const DownloadDetails = ({ documents, toggleDrawer, anchor }) => {
  const { BATCH_DOCUMENTS, BATCH_MESSAGE } = exportBatches;

  return (
    <Grid
      sx={{
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#1E1E1E",
      }}
    >
      <>
        <Grid
          container
          sx={{
            padding: "0em 2em",
            mt: ".7em",
            mb: ".7em",
            justifyContent: "flex-end",
          }}
        >
          <Close
            onClick={toggleDrawer(anchor, false)}
            sx={{ cursor: "pointer", fontSize: "1.5em", color: "#B0B0B0" }}
          />
        </Grid>
        <Grid
          container
          sx={{
            height: "90%",
            width: { xs: "20em", sm: "28em", xl: "42em" },
            padding: "2em 0.8em",
            borderRadius: "1em",
            backgroundColor: "#141414",
            flexDirection: "column",
            justifyContent: "flex-start",
            m: "0em 2em",
          }}
        >
          <Typography sx={{ color: Colors.TOURQUISE, fontSize: "1em", ml: "1.1em" }}>
            {BATCH_DOCUMENTS}
          </Typography>
          <Grid
            container
            sx={{
              marginTop: ".5rem",
              height: "75vh",
              overflow: "auto",
              width: "98%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {documents?.length === 0 ? (
              <Grid
                container
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  color: "#B0B0B0",
                }}
              >
                <Typography>{BATCH_MESSAGE}</Typography>
              </Grid>
            ) : (
              <BatchDocumentsDrawer
                option1="File Name"
                option2="Dcoument Number"
                option3="In ERP"
                dataList={documents.map(doc => ({
                  fileName: doc?.originalFileName,
                  docNumber: doc?.standardizeContent?.document_number,
                  sendErpStatus: doc?.sendErpStatus,
                }))}
              />
            )}
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default DownloadDetails;
