import React, { useState, useMemo } from 'react';
import { Grid, IconButton, Typography, TextField, Button } from '@mui/material';
import { Send, AttachFile, DeleteOutline } from '@mui/icons-material';
import { Colors } from '../config/default';
import { addComment } from '../services/services';
import { useSelector } from 'react-redux';
import { commentsInput } from '../constants/appConstants';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px dashed #bdbdbd',
  padding: '20px',
  color: '#bdbdbd',
  outline: 'none',
  cursor: 'pointer',
  fontSize: '.9rem',
  borderRadius: '10px',
  textAlign: 'center'
};

const focusedStyle = { borderColor: Colors.DEEPGREY };
const acceptStyle = { borderColor: Colors.TOURQUISE };
const rejectStyle = { borderColor: Colors.RED };

const CommentsInput = ({ onAddComment, id, setOpenCommentModal, status, handleSubmitClick }) => {
  const [attachments, setAttachments] = useState([]);
  const [inputVal, setInputVal] = useState('');
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    setAttachments((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: true
  });

  const handleDeleteAttachment = (fileName) => {
    setAttachments((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputVal.trim() && attachments.length === 0) return;
    setLoading(true);

    const newComment = {
      comment: inputVal,
      time: Date.now(),
      userName: userDetails?.user?.userName,
      email: userDetails?.user?.email,
      role: userDetails?.role
    };

    await addComment(id, newComment, attachments);

    setInputVal('');
    setAttachments([]);
    if (onAddComment) {
      onAddComment();
    }
    setOpenCommentModal(false);
    if (handleSubmitClick) {
      handleSubmitClick(e, status);
    }
    setLoading(false);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <form onSubmit={handleSubmit} style={{ margin: 'auto' }}>
      <Typography
        variant="h6"
        sx={{ color: Colors.WHITE, marginBottom: '1em', textAlign: 'center' }}>
        {commentsInput.COMMENT}
      </Typography>

      <TextField
        multiline
        fullWidth
        minRows={5}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        placeholder={commentsInput.COMMENT_PLACEHOLDER}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: {
            color: Colors.WHITE,
            backgroundColor: Colors.NAV_BLACK,
            fontSize: '1rem',
            borderRadius: '10px'
          }
        }}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '10px',
            overflow: 'hidden'
          },
          '& .MuiInputBase-input': {
            padding: '1em'
          },
          marginBottom: '1em'
        }}
      />

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} type="file" accept="application/pdf" />
        <Typography variant="body2" sx={{ color: Colors.WHITE }}>
          {commentsInput.ATTACHMENT_MESSAGE}
        </Typography>
      </div>

      {attachments?.length > 0 && (
        <Grid container direction="column" sx={{ marginTop: '1em' }}>
          {attachments?.map((file, index) => (
            <Grid
              key={index}
              container
              alignItems="center"
              sx={{
                backgroundColor: Colors.NAV_BLACK,
                padding: '5px',
                borderRadius: '10px',
                marginBottom: '0.5em'
              }}>
              <AttachFile sx={{ color: Colors.WHITE, marginRight: '0.5em' }} />
              <Typography
                variant="body2"
                color="white"
                sx={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '0.8rem'
                }}>
                {file.name}
              </Typography>
              <IconButton sx={{ color: 'red' }} onClick={() => handleDeleteAttachment(file.name)}>
                <DeleteOutline />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      )}

      <Grid container justifyContent="center">
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={loading || (inputVal?.trim() === '' && attachments?.length === 0)}
          sx={{
            marginTop: '1.5em',
            border: '1.5px solid',
            borderRadius: '10px',
            padding: '0.5em 2em',
            textTransform: 'none'
          }}>
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </Grid>
    </form>
  );
};

export default CommentsInput;
