import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Gravatar from 'react-gravatar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Hidden,
  TextField
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  PersonRounded,
  MailOutlineRounded,
  LocalPhoneRounded,
  ArrowBack,
  DashboardOutlined,
  InsertDriveFileOutlined,
  Attachment,
  CorporateFare,
  EditOutlined
} from '@mui/icons-material';

import { user_information, get_tabs } from '../redux/actions/action';
import theme from '../theme';
import SwitchComponent from '../components/switch';
import { ValidationMessages, Profile, signupOptions, userRoles } from '../constants/appConstants';
import Layout from '../components/layout';
import { updateProfile, getUserInfo, checkAvailableEmail } from '../services/services';
import { Colors } from '../config/default';
import { AdminParentTabs, clientParentTabs, getParentTabs } from '../services/common';
import { useToast } from '../toast/toastContext';

function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { REQUIRED, INVALID_USERNAME } = ValidationMessages;
  const { OWNER, ACCOUNTANT, ADMIN, VENDOR } = userRoles;
  const {
    PROFILE_HEADING,
    TOTAL_PAGES,
    TOTAL_INVOICE,
    SELECT_ERP,
    DUPLICATE_CHECK,
    G702_PERMISSION,
    PO_MATCHING,
    ENABLE_JOB,
    DOC_SPLITTER,
    BACK,
    BEIING_HUMAN,
    ENABLE_DUPLICATION,
    DOC_LIMIT,
    DISCONNECT_INTEGRATION_MESSAGE,
    RECEIPT_UPLOAD,
    QUOTE_UPLOAD,
    ADD_PATH,
    ADD_FOLDER_ID,
    DELIVERY_TICKET_UPLOAD,
    DEFAULT_CODE,
    LINEITEM_TOTAL_MATCHING,
    SERVICE_AND_EQUIPMENT,
    QUICKBOOKS_NOTFICATION,
    QUICKBOOKS_PERMISSION,
    SAVE_VENDOR_CODE,
    VENDOR_CODE_TOOLTIP,
    ACCURACY_TOOLTIP,
    ACCURACY_BASED_POINTING,
    CONSOLIDATE_LINEITEMS,
    GL_DIVISION,
    COMPANY_EMAIL,
    VALIDATE,
    CANCEL,
    EDIT_EMAIL
  } = Profile;
  const inputStyle = {
    WebkitBoxShadow: '0 0 0 1000px #1E1E1E inset',
    WebkitTextFillColor: '#fff'
  };
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const name = userDetails?.user?.userName;
  const currentErp = userDetails?.user?.erpType;
  const erpConfig = userDetails?.erpConfiguration;
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const parentTabs = getParentTabs(userRole);
  const user = useSelector((state) => state.signIn?.signIn?.cognitoRes?.idToken?.payload);
  const isUnmounting = useRef(true);
  const [loading, setLoading] = useState(false);
  const [G702Permission, setG702Permission] = useState(userDetails?.user?.G702);
  const [poMatching, setPoMatching] = useState(userDetails?.user?.poMatchPermission);
  const [docSplitter, setDocSplitter] = useState(userDetails?.user?.docSpliterPermission);
  const [erpValue, setErpValue] = useState(userDetails?.user?.erpType);
  const [receiptPermission, setReceiptPermission] = useState(userDetails?.user?.receiptUpload);
  const [quotePermission, setQuotePermission] = useState(userDetails?.user?.quotePermission);
  const [dtPermission, setDtPermission] = useState(userDetails?.user?.deliveryTicketUpload);
  const [activeTab, setActiveTab] = useState('');
  const [folderPath, setFolderPath] = useState(userDetails?.user?.folderPath);
  const [folderId, setFolderId] = useState(userDetails?.user?.driveFolderId);
  const [jobcostPermission, setJobcostPermission] = useState(
    userDetails?.user?.constructionCompany
  );
  const [allowDuplicate, setAllowDuplicate] = useState(userDetails?.user?.duplicateDocNo);
  const [enableDuplicate, setEnableDuplicate] = useState(userDetails?.user?.duplicateDocProcess);
  const [defaultCoding, setDefaultCoding] = useState(userDetails?.company?.defaultCoding);
  const [serviceAndEquipment, setServiceAndEquipment] = useState(
    userDetails?.company?.serviceCodeAndEquipmentNo
  );
  const [lineitemsTotalMatching, setLineitemsTotalMatching] = useState(
    userDetails?.company?.lineItemTotalCalculationPermission
  );
  const [vendorCodes, setVendorCodes] = useState(userDetails?.company?.saveVendorCode);
  const [accuracyPoitning, setAccuracyPoitning] = useState(userDetails?.company?.accuracyPointing);
  const [consolidateLineItems, setConsolidateLineItems] = useState(
    userDetails?.company?.consolidateLineItems
  );
  const [glDivision, setGlDivision] = useState(userDetails?.company?.glDivisionPermission);
  const initialValues = {
    userName: name
  };
  const companyId = userDetails?.company?._id;

  useEffect(() => {
    const setPermissions = (permissions, companyPermission) => {
      setG702Permission(permissions?.G702);
      setPoMatching(permissions?.poMatchPermission);
      setDocSplitter(permissions?.docSpliterPermission);
      setReceiptPermission(permissions?.receiptUpload);
      setQuotePermission(permissions?.quotePermission);
      setJobcostPermission(permissions?.constructionCompany);
      setAllowDuplicate(permissions?.duplicateDocNo);
      setEnableDuplicate(permissions?.duplicateDocProcess);
      setDtPermission(permissions?.deliveryTicketUpload);
      setDefaultCoding(companyPermission?.defaultCoding);
      setServiceAndEquipment(companyPermission?.serviceCodeAndEquipmentNo);
      setLineitemsTotalMatching(companyPermission?.lineItemTotalCalculationPermission);
      setVendorCodes(companyPermission?.saveVendorCode);
      setAccuracyPoitning(companyPermission?.accuracyPointing);
      setConsolidateLineItems(companyPermission?.consolidateLineItems);
      setGlDivision(companyPermission?.glDivisionPermission);
    };

    const permissions = currentErp !== erpValue ? erpConfig[erpValue] : userDetails?.user;
    const companyPermission = currentErp !== erpValue ? erpConfig[erpValue] : userDetails?.company;
    setPermissions(permissions, companyPermission);
  }, [erpValue]);
  const validationSchema = Yup.object({
    userName: Yup.string()
      .trim()
      .required(REQUIRED)
      .matches(/^[a-zA-Z0-9_\s]+$/, INVALID_USERNAME)
  });
  const onSubmit = (values) => {
    handleSubmit(values, isUnmounting.current);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });
  const handleERPChange = (event) => {
    setErpValue(event.target.value);
  };
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );
  const userInformation = async () => {
    setLoading(true);
    const result = await getUserInfo(cognitoUsername);
    if (result?.status === 200) {
      dispatch(user_information(result?.data?.payload?.data));
    }
    setLoading(false);
  };
  useEffect(() => {
    userInformation();
  }, []);
  const [companyMail, setCompanyMail] = useState(userDetails?.company?.companyEmail);

  const handleSubmit = async (values, isNotUnmounting) => {
    const data = {
      G702: G702Permission || false,
      erpType: erpValue,
      constructionCompany: jobcostPermission || false,
      costIdPermission: jobcostPermission || false,
      jobIdPermission: jobcostPermission || false,
      docSpliterPermission: docSplitter || false,
      duplicateDocNo: allowDuplicate || false,
      poMatchPermission: poMatching || false,
      duplicateDocProcess: enableDuplicate || false,
      receiptUpload: receiptPermission || false,
      quotePermission: quotePermission || false,
      folderPath: folderPath,
      driveFolderId: folderId,
      deliveryTicketUpload: dtPermission || false,
      defaultCoding: defaultCoding || false,
      serviceCodeAndEquipmentNo: serviceAndEquipment || false,
      lineItemTotalCalculationPermission: lineitemsTotalMatching || false,
      saveVendorCode: vendorCodes || false,
      accuracyPointing: accuracyPoitning || false,
      consolidateLineItems: consolidateLineItems || false,
      glDivisionPermission: glDivision || false,
      companyEmail: companyMail || ''
    };
    if (values?.userName) {
      const result = await updateProfile(data);
      if (result?.status === 200) {
        showToast(result?.data?.metadata?.message, 'success');
        const getUserresult = await getUserInfo(cognitoUsername);
        if (erpValue === 'DeltekComputerEase') {
          dispatch(
            get_tabs({
              activeTab: globalActiveTab?.activeTab,
              documentChildActiveTab: globalActiveTab?.documentChildActiveTab,
              IntegrationChildActiveTab: 'Manual Syncing'
            })
          );
        }
        if (getUserresult?.status === 200) {
          dispatch(user_information(getUserresult?.data?.payload?.data));
          sessionStorage.setItem('erpType', getUserresult?.data?.payload?.data?.user?.erpType);
        }
      } else {
        if (isNotUnmounting) {
          showToast(
            result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
            'error'
          );
        }
      }
    }
  };
  useEffect(() => {
    if (userDetails?.role === OWNER) {
      return () => {
        isUnmounting.current = false;
        formik.handleSubmit();
      };
    }
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  const [apiMessage, setApiMessage] = useState('');
  const [apiMessageType, setApiMessageType] = useState('');
  const [emailPrefix, setEmailPrefix] = useState(
    userDetails?.company?.companyEmail?.split('@')[0] || ''
  );
  const emailDomain = userDetails?.company?.companyEmail?.includes('@')
    ? `@${userDetails.company.companyEmail.split('@')[1]}`
    : '';

  const handlePrefixChange = (e) => {
    setEmailPrefix(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    
  };

  const handleCancelClick = () => {
    setEmailPrefix(userDetails?.company?.companyEmail?.split('@')[0] || '');
    setIsEditing(false);
    setApiMessage('');
    setApiMessageType('');
  };

  const handleSaveClick = async () => {
    const updatedEmail = `${emailPrefix}${emailDomain}`;

    try {
      const response = await checkAvailableEmail(companyId, updatedEmail);
      if (
        response?.data?.metadata?.status === 'SUCCESS' &&
        response?.data?.payload?.data === true
      ) {
        setApiMessage('');
        setApiMessageType('');
        setCompanyMail(updatedEmail);
        setIsEditing(false);
      } else {
        setApiMessage(response?.data?.metadata?.message || 'This email is already taken.');
        setApiMessageType('error');
      }
    } catch (error) {
      setApiMessage('Email must be a valid email address.');
      setApiMessageType('error');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundColor: 'primary.light'
        }}>
        <Layout
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={
            userRole === ADMIN
              ? AdminParentTabs
              : userRole === VENDOR
              ? clientParentTabs
              : parentTabs
          }
          path="profile"
          show={userDetails?.role === OWNER ? 'showUser' : ''}
        />
        <Grid xs={12}>
          <Button
            variant="outlined"
            sx={{
              border: `1px solid ${Colors.DARKBLUE}`,
              borderRadius: '10px',
              height: '2rem',
              width: '5rem',
              ml: { xs: '2.85em', sm: '6.25em' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '.8em',
              textTransform: 'none',
              color: Colors.WHITE,
              '&:hover': {
                background: 'none',
                border: `1px solid ${Colors.DARKBLUE}`
              }
            }}
            onClick={() =>
              userDetails?.role === ADMIN
                ? navigate('/admin')
                : userDetails?.role === VENDOR
                ? navigate('/vendor')
                : navigate('/home')
            }>
            <ArrowBack sx={{ color: Colors.DARKBLUE, fontSize: '1.4em', mr: '.3em' }} />
            {BACK}
          </Button>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: { xs: '65vh', sm: '80vh' },
            backgroundColor: 'primary.light',
            overflowY: { xs: 'scroll', lg: 'visible' },
            '&::-webkit-scrollbar': {
              width: '0em',
              height: '0em',
              borderRadius: '20px'
            }
          }}>
          <Grid item xs={10} lg={9} xl={6} xxl={5}>
            {loading ? (
              <Grid
                container
                sx={{
                  height: '80vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={150} color="primary" />
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: { xs: '1rem', md: '.7rem', lg: '1.5rem' },
                    borderRadius: '15px',
                    backgroundColor: Colors.NAV_BLACK,
                    marginTop: { xs: '1em', lg: '0em' },
                    height: { xs: 'max-content', md: '' }
                  }}>
                  <Typography
                    sx={{ color: Colors.WHITE, display: 'inline-flex', alignItems: 'center' }}>
                    <PersonRounded sx={{ color: 'primary.main', mr: 0.5 }} />
                    {PROFILE_HEADING}
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={(e) => {
                      isUnmounting.current = true;
                      formik.handleSubmit(e);
                    }}
                    sx={{ width: '100%' }}>
                    <Grid container item xs={12}>
                      <Grid container item xs={12} sx={{ marginTop: '.5rem' }}>
                        <Gravatar
                          email={user?.email}
                          size={150}
                          style={{ borderRadius: '20px', marginBottom: '.5rem' }}
                        />
                        {(userDetails?.role === OWNER || userDetails?.role === ACCOUNTANT) && (
                          <Hidden mdUp>
                            <Grid item xs={3} sx={{ marginLeft: { xs: '.5em', sm: '2em' } }}>
                              <Grid item>
                                <Typography sx={{ color: Colors.TEXTGREY }}>
                                  {TOTAL_INVOICE}
                                </Typography>
                                <Typography sx={{ color: Colors.WHITE }}>
                                  {userDetails?.user?.totalInvoices}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography sx={{ color: Colors.TEXTGREY, marginTop: '1rem' }}>
                                  {TOTAL_PAGES}
                                </Typography>
                                <Typography sx={{ color: Colors.WHITE }}>
                                  {userDetails?.user?.totalNumberOfPages}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography sx={{ color: Colors.TEXTGREY, marginTop: '1rem' }}>
                                  {COMPANY_EMAIL}
                                </Typography>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    borderBottom: `2px solid ${Colors.Canvas_BG} `,
                                    paddingBottom: '4px',
                                    width: 'fit-content'
                                  }}>
                                  {isEditing ? (
                                    <>
                                      <TextField
                                        value={emailPrefix}
                                        onChange={handlePrefixChange}
                                        variant="standard"
                                        size="small"
                                        InputProps={{
                                          disableUnderline: true
                                        }}
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            color: Colors.WHITE,
                                            textAlign: 'center'
                                          },
                                          minWidth: '90px'
                                        }}
                                      />
                                      <Typography sx={{ color: Colors.WHITE }}>
                                        {emailDomain}
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography sx={{ color: Colors.WHITE }}>
                                      {emailPrefix} {emailDomain}
                                    </Typography>
                                  )}
                                </Box>

                                {/* API Response Message */}
                                {apiMessage && (
                                  <Typography
                                    sx={{
                                      color: apiMessageType === 'error' ? Colors.RED : Colors.GREEN,
                                      marginTop: '5px',
                                      fontSize: '12px'
                                    }}>
                                    {apiMessage}
                                  </Typography>
                                )}

                                {isEditing ? (
                                  <Grid sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={handleSaveClick}
                                      sx={{
                                        backgroundColor: Colors.DEEPBLUE,
                                        '&:hover': { backgroundColor: Colors.DEEPBLUE },
                                        color: 'white',
                                        width: '80px',
                                        textTransform: 'none',
                                        borderRadius: '10px'
                                      }}>
                                      {VALIDATE}
                                    </Button>

                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={handleCancelClick}
                                      sx={{
                                        backgroundColor: Colors.RED,
                                        '&:hover': { backgroundColor: Colors.RED },
                                        color: 'white',
                                        width: '80px',
                                        textTransform: 'none',
                                        borderRadius: '10px'
                                      }}>
                                      {CANCEL}
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                      textTransform: 'none',
                                      borderRadius: '10px'
                                    }}
                                    onClick={handleEditClick}>
                                    <EditOutlined fontSize="small" />
                                    {EDIT_EMAIL}
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Hidden>
                        )}
                        <Grid item xs={12} sm={4} lg={3} xl={3} sx={{ marginLeft: { md: '2rem' } }}>
                          <Grid item>
                            <Typography variant="h6" sx={{ color: 'white', fontWeight: 700 }}>
                              {user?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#BABABA' }}>
                              {BEIING_HUMAN}
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mt: { xs: 1, md: 4 } }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#BABABA',
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                              <MailOutlineRounded
                                sx={{ color: 'primary.main', mr: 0.5, fontSize: '16px' }}
                              />
                              {user?.email}
                            </Typography>
                            {userRole === 'Vendor' ? (
                              ''
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: '#BABABA',
                                  mt: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <LocalPhoneRounded
                                  sx={{
                                    color: 'primary.main',
                                    mr: 0.5,
                                    fontSize: '16px'
                                  }}
                                />
                                {user?.phone_number}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        {(userDetails?.role === OWNER ||
                          userDetails?.role === ACCOUNTANT ||
                          userDetails?.role === VENDOR) && (
                          <Hidden mdDown>
                            <Grid item xs={3}>
                              <Grid item>
                                <Typography sx={{ color: Colors.TEXTGREY }}>
                                  {TOTAL_INVOICE}
                                </Typography>
                                <Typography sx={{ color: Colors.LIGHTGREY }}>
                                  {userDetails?.user?.totalInvoices ||
                                    userDetails?.user?.totalNumberOfDocs}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography sx={{ color: Colors.TEXTGREY, marginTop: '1rem' }}>
                                  {TOTAL_PAGES}
                                </Typography>
                                <Typography sx={{ color: Colors.LIGHTGREY }}>
                                  {userDetails?.user?.totalNumberOfPages}
                                </Typography>
                              </Grid>
                              {userDetails?.role === VENDOR && (
                                <Grid item>
                                  <Typography sx={{ color: Colors.TEXTGREY, marginTop: '1rem' }}>
                                    {DOC_LIMIT}
                                  </Typography>
                                  <Typography sx={{ color: Colors.WHITE }}>
                                    {userDetails?.user?.docPlanLimit}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Hidden>
                        )}
                        {userDetails?.role !== VENDOR && (
                          <Hidden mdDown>
                            <Grid item xs={4}>
                              <Typography sx={{ color: Colors.TEXTGREY, marginTop: '1rem' }}>
                                {COMPANY_EMAIL}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                  borderBottom: `2px solid ${Colors.Canvas_BG} `,
                                  paddingBottom: '4px',
                                  width: 'fit-content'
                                }}>
                                {isEditing ? (
                                  <>
                                    <TextField
                                      value={emailPrefix}
                                      onChange={handlePrefixChange}
                                      variant="standard"
                                      size="small"
                                      InputProps={{
                                        disableUnderline: true
                                      }}
                                      sx={{
                                        '& .MuiInputBase-input': {
                                          color: Colors.WHITE,
                                          textAlign: 'center'
                                        },
                                        minWidth: '50px'
                                      }}
                                    />
                                    <Typography sx={{ color: Colors.WHITE }}>
                                      {emailDomain}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography sx={{ color: Colors.WHITE }}>
                                    {emailPrefix} {emailDomain}
                                  </Typography>
                                )}
                              </Box>

                              {/* API Response Message */}
                              {apiMessage && (
                                <Typography
                                  sx={{
                                    color: apiMessageType === 'error' ? Colors.RED : Colors.GREEN,
                                    marginTop: '5px',
                                    fontSize: '12px'
                                  }}>
                                  {apiMessage}
                                </Typography>
                              )}

                              {isEditing ? (
                                <Grid sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleSaveClick}
                                    sx={{
                                      backgroundColor: Colors.DEEPBLUE,
                                      '&:hover': { backgroundColor: Colors.DEEPBLUE },
                                      color: 'white',
                                      width: '80px',
                                      textTransform: 'none',
                                      borderRadius: '10px'
                                    }}>
                                    {VALIDATE}
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleCancelClick}
                                    sx={{
                                      backgroundColor: Colors.RED,
                                      '&:hover': { backgroundColor: Colors.RED },
                                      color: 'white',
                                      width: '80px',
                                      textTransform: 'none',
                                      borderRadius: '10px'
                                    }}>
                                    {CANCEL}
                                  </Button>
                                </Grid>
                              ) : (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    textTransform: 'none',
                                    borderRadius: '10px'
                                  }}
                                  onClick={handleEditClick}>
                                  <EditOutlined fontSize="small" />
                                  {EDIT_EMAIL}
                                </Button>
                              )}
                            </Grid>
                          </Hidden>
                        )}
                      </Grid>
                      {userDetails?.role === OWNER ? (
                        <>
                          <Grid container item xs={12}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}>
                              <SwitchComponent
                                description={DUPLICATE_CHECK}
                                setChecked={setAllowDuplicate}
                                checked={allowDuplicate}
                              />
                              <SwitchComponent
                                description={DOC_SPLITTER}
                                setChecked={setDocSplitter}
                                checked={docSplitter}
                              />
                              <SwitchComponent
                                description={PO_MATCHING}
                                setChecked={setPoMatching}
                                checked={poMatching}
                                disable={erpValue !== 'Foundation' && erpValue !== 'RentalMan'}
                              />
                              <SwitchComponent
                                description={RECEIPT_UPLOAD}
                                setChecked={setReceiptPermission}
                                checked={receiptPermission}
                              />
                              <SwitchComponent
                                description={G702_PERMISSION}
                                setChecked={setG702Permission}
                                checked={G702Permission}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'max-content'
                              }}>
                              <SwitchComponent
                                description={ENABLE_JOB}
                                setChecked={setJobcostPermission}
                                checked={jobcostPermission}
                              />
                              <SwitchComponent
                                description={ENABLE_DUPLICATION}
                                setChecked={setEnableDuplicate}
                                checked={enableDuplicate}
                              />
                              <SwitchComponent
                                description={QUOTE_UPLOAD}
                                setChecked={setQuotePermission}
                                checked={quotePermission}
                                disable={erpValue !== 'Foundation'}
                              />
                              <SwitchComponent
                                description={DELIVERY_TICKET_UPLOAD}
                                setChecked={setDtPermission}
                                checked={dtPermission}
                                disable={erpValue !== 'Foundation'}
                              />
                              {erpValue === 'Foundation' && (
                                <>
                                  <InputLabel
                                    id="demo-simple-select-standard-label"
                                    sx={{
                                      fontSize: '12px',
                                      marginTop: '.3rem'
                                    }}>
                                    {ADD_FOLDER_ID}
                                  </InputLabel>
                                  <TextField
                                    sx={{ width: { xs: '80%', xl: '71%' }, fontSize: '11px' }}
                                    variant="standard"
                                    value={folderId}
                                    onChange={(e) => setFolderId(e.target.value)}
                                    fullWidth
                                    id="path"
                                    type="text"
                                    placeholder="Folder id"
                                    name="path"
                                    inputProps={{ style: inputStyle }}
                                  />
                                </>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'max-content'
                              }}>
                              <SwitchComponent
                                description={SERVICE_AND_EQUIPMENT}
                                setChecked={setServiceAndEquipment}
                                checked={serviceAndEquipment}
                                disable={erpValue !== 'Foundation'}
                              />
                              <SwitchComponent
                                description={DEFAULT_CODE}
                                setChecked={setDefaultCoding}
                                checked={defaultCoding}
                                disable={erpValue !== 'Foundation' && erpValue !== 'RentalMan'}
                              />
                              <SwitchComponent
                                description={LINEITEM_TOTAL_MATCHING}
                                setChecked={setLineitemsTotalMatching}
                                checked={lineitemsTotalMatching}
                              />
                              <SwitchComponent
                                description={SAVE_VENDOR_CODE}
                                setChecked={setVendorCodes}
                                checked={vendorCodes}
                                message={VENDOR_CODE_TOOLTIP}
                                tooltip={true}
                              />
                              <SwitchComponent
                                description={ACCURACY_BASED_POINTING}
                                setChecked={setAccuracyPoitning}
                                checked={accuracyPoitning}
                                message={ACCURACY_TOOLTIP}
                                tooltip={true}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} md={8} sx={{ mt: 1, alignItems: 'center' }}>
                            <Grid item xs={12} md={6}>
                              <FormControl
                                variant="standard"
                                sx={{ textAlign: 'left', width: { xs: '80%', xl: '71%' }, mt: 0.3 }}
                                disabled={userDetails?.activeIntegration !== ''}>
                                <InputLabel
                                  id="demo-simple-select-standard-label"
                                  sx={{
                                    fontSize: '16px'
                                  }}>
                                  {SELECT_ERP}
                                </InputLabel>
                                <Select
                                  sx={{
                                    border: 'none !important',
                                    fontSize: '13px',
                                    padding: '0 !important'
                                  }}
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={erpValue}
                                  onChange={(e) => handleERPChange(e)}
                                  label="Permission">
                                  {signupOptions?.map((option) => (
                                    <MenuItem
                                      key={option}
                                      value={
                                        option === 'Accounting Seed'
                                          ? option
                                          : option?.replace(/\s+/g, '')
                                      }>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {(erpValue === 'QuickBooksOnline' ||
                                erpValue === 'QuickBooksDesktop') && (
                                <Typography
                                  sx={{
                                    color: Colors.LIGHTGREY,
                                    fontSize: '11px'
                                  }}>
                                  {QUICKBOOKS_NOTFICATION}
                                  <br />
                                  {QUICKBOOKS_PERMISSION}
                                </Typography>
                              )}
                              {userDetails?.activeIntegration !== '' && (
                                <Typography
                                  sx={{
                                    color: Colors.TOURQUISE,
                                    fontSize: '11px'
                                  }}>
                                  {DISCONNECT_INTEGRATION_MESSAGE}
                                </Typography>
                              )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                              {erpValue === 'Foundation' && (
                                <>
                                  <InputLabel
                                    id="demo-simple-select-standard-label"
                                    sx={{
                                      fontSize: '12px'
                                    }}>
                                    {ADD_PATH}
                                  </InputLabel>
                                  <TextField
                                    sx={{ width: { xs: '80%', xl: '71%' }, fontSize: '11px' }}
                                    variant="standard"
                                    value={folderPath}
                                    onChange={(e) => setFolderPath(e.target.value)}
                                    fullWidth
                                    id="path"
                                    type="text"
                                    placeholder="e.g. C:\folder"
                                    name="path"
                                    inputProps={{ style: inputStyle }}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <SwitchComponent
                              description={CONSOLIDATE_LINEITEMS}
                              setChecked={setConsolidateLineItems}
                              checked={consolidateLineItems}
                            />
                            <SwitchComponent
                              description={GL_DIVISION}
                              setChecked={setGlDivision}
                              checked={glDivision}
                              disable={erpValue !== 'Foundation'}
                            />
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UserProfile;
