import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import {
  useDynamicRefs,
  findVendorIndex,
  checkObjectValues,
  getLineItemsCopy,
  drawLinesByPosition,
  getLineItemsCoddingArray,
  generateLineItemsHeader,
  getButtonArray,
  regenerateCodeFormat,
  regenerateContentFormat,
  regeneratePaymentFormat
} from '../constants/utils';
import { Colors } from '../config/default';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  checkDocNoDuplication,
  FetchDefaultCoding,
  getDocumentDetails,
  matchPO,
  updateDocument,
  updateDocumentType,
  getUpdatedApprovers,
  getSelectedDocs
} from '../services/services';
import { canvasDetails, userRoles, documentTypes } from '../constants/appConstants';

import AccountsInput from '../components/accountsInput';
import {
  formatLineItems,
  arrangedLineitems,
  getPosition,
  filterDocuments,
  deepEqual,
  rearrangeFields,
  consolidateItems
} from '../services/common';
import {
  replaceContentNames,
  validateLineItems,
  validateData,
  isValidPage
} from '../services/validationService';
import Layout from '../components/layout';
import { get_pending_data, get_tabs, get_queue } from '../redux/actions/action';
import PaginationButtons from '../components/paginationButtons';
import { useToast } from '../toast/toastContext';
import '../image.css';
import { compareObjects } from '../services/validationService';
import patternBackground from '../assets/triangular-background-dark.png';
import PaymentFields from '../components/paymentFields';
import '../css/canvas.css';
import RightSection from '../components/rightSection';
import DocumentButtons from './documentButtons';
import CoddingFields from '../components/coddingFields';
import ContentFields from '../components/contentFields';
import { codingfieldStates } from '../utils/canvasUtils';
const ResponsivePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ESTIMATE, DELIVERY_TICKET, INVOICE, G702, RECEIPT } = documentTypes;
  const { ACCOUNTANT, OWNER, CLEVEL, MANAGER, VENDOR } = userRoles;
  const { VENDOR_NUMBER, FROM_CONTRACTOR, CONTENT_TOTAL, DOCUMENT_DETAILS } = canvasDetails;
  const scrollContainerRef = useRef(null);
  const reviewScrollContainerRef = useRef(null);
  const [setRef, getRef] = useDynamicRefs();
  const imgElement = useRef(null);
  const currentDocumentId = id;
  const callSaveState = useRef(true);
  const smallScreen = useMediaQuery('(max-width:1000px)');
  const { showToast } = useToast();
  const navigate = useNavigate();

  //redux states
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const savedFilters = useSelector((state) => state?.filters?.filters);
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const lineitemsMatchingPermission = userDetails?.company?.lineItemTotalCalculationPermission;
  const erpType = userDetails?.user?.erpType;
  const user = useSelector((state) => state?.userInfo?.userInfo?.user?.userName);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const allowConsolidateItems = userDetails?.company?.consolidateLineItems;
  const queue = useSelector((state) => state?.pending?.pending[0]);
  const documents = filterDocuments(queue);
  const currentIndex = documents?.findIndex((doc) => doc._id === currentDocumentId);

  const [validationErrors, setValidationErrors] = useState({});
  const [paymentValidationErrors, setPaymentValidationErrors] = useState({});
  const [validationLineItemsErrors, setValidationLineItemsErrors] = useState({});
  const [currentId, setCurrentId] = useState(id);
  const [imageDimensions, setImageDimensions] = useState({});
  const [actualValues, setActualValues] = useState([]);
  const [content, setContent] = useState([]);
  const [accountCodeArray, setAccountCodeArray] = useState([]);
  const [actualaccountCodeValues, setActualaccountCodeValues] = useState([]);
  const [lineItem, setLineItem] = useState([]);
  const [canvasDimensions, setCanvasDimensions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [annotationType, setAnnotationType] = useState('');
  const [index, setIndex] = useState(0);
  const [indexLength, setIndexLength] = useState(0);
  const array = Array(indexLength).fill(false);
  const [buttonColors, setButtonColors] = useState(array);
  const [typeCheck, setTypeCheck] = useState(false);
  const [vendorNumberCheck, setVendorNumberCheck] = useState(false);
  const [isPoMatched, setIsPoMatched] = useState(true);
  const [isInvoiceNumberMatched, setIsInvoiceNumberMatched] = useState({});
  const [open, setOpen] = useState(false);
  const [isAmountMatched, setIsAmountMatched] = useState({});
  const [isDateMatched, setIsDateMatched] = useState({});
  const [po, setPo] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [documentDate, setdocumentDate] = useState('');
  const [lineItemsData, setLineItemsData] = useState('');
  const [vendorNumber, setVendorNumber] = useState('');
  const [glInfo, setGlInfo] = useState({});
  const [vendorsData, setVendorsData] = useState({});
  const [vendors, setVendors] = useState('');
  const [isLineItemsMatched, setIsLineItemsMatched] = useState([]);
  const [manualEmails, setManualEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [docName, setDocName] = useState('');
  const [obj, setObj] = useState({});
  const [namesArray, setNamesArray] = useState([]);
  const [fetchingPO, setFetchingPO] = useState(false);
  const [activityObj, setActivityObj] = useState(false);
  const [lineItemInitialObj, setLineItemInitialObj] = useState({});
  const [openPrompt, setOpenPrompt] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [valueChanged, setValueChanged] = useState(false);
  const [toggleDraw, setToggleDraw] = useState('');
  const [clickedBox, setClickedBox] = useState(null);
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0 });
  const [clickedBoxRefrence, setClickedBoxRefrence] = useState('');
  const [clickedBoxIndex, setClickedBoxIndex] = useState(-1);
  const [matchingCallsrc, setMatchingCallsrc] = useState('');
  const [consolidating, setConsolidating] = useState(false);
  const [refId, setRefId] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [policyBase, setPolicyBase] = useState('');
  const [fields, setFields] = useState(codingfieldStates);
  const [buttonType, setButtonType] = useState('');
  const [lineItemsTotalMatch, setLineItemsTotalMatch] = useState(true);
  const [duplicateDocumentNoErrorObj, setDuplicateDocumentNoErrorObj] = useState({});
  const [lineItemAmountSum, setLineItemAmountSum] = useState(0);
  const [openApprovePrompt, setOpenApprovePrompt] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [isDocumentSaved, setIsDocumentSaved] = useState(false); // state to stop apis only initially
  const [savedStatefromDocument, setSavedStateFromDocument] = useState(false); // state from document
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [documentoaded, setDocumentoaded] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState('');
  const [prepaid, setPrepaid] = useState(annotationType === RECEIPT ? true : false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [paymentType, setPaymentType] = useState('');
  const [actualPaymentValues, setActualPaymentValues] = useState([]);
  const [documentData, setDocumentData] = useState({});
  var BoxReferenceId;
  var ButtonReferenceId;

  const isNotApPerson = userRole !== OWNER && userRole !== ACCOUNTANT;
  const consolidatedItemsFlag = allowConsolidateItems && !isNotApPerson;

  const disableConsolidate =
    lineItemInitialObj?.Description?.length === 1 &&
    lineItemInitialObj?.Description?.every((item) => item === 'Consolidated Items');
  const [combineItems, setCombineItems] = useState(
    disableConsolidate || consolidatedItemsFlag || false
  );
  const [isInitialized, setIsInitialized] = useState(false);

  let codes = generateLineItemsHeader(
    annotationType,
    isConstructionCompany,
    erpType,
    serviceAndEquipmentPermission,
    userDetails
  );
  let headers = isEmpty(lineItemInitialObj) ? codes : namesArray;
  let size =
    obj &&
    Object?.keys(obj)?.map((items) => {
      return obj[items] && obj[items]?.length;
    });
  const [totalItems, setTolalItems] = useState(new Array(size?.[0] || 0).fill(0));
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const resultArray = compareObjects(lineItemInitialObj, obj);
  const handleOpen = () => setOpenPrompt(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fetchCoding = async (venum) => {
    try {
      const fetchCode = await FetchDefaultCoding(venum);
      if (fetchCode?.status === 200) {
        const dataObject = fetchCode?.data?.payload?.data?.reduce((acc, item) => {
          acc[item?.name] = item?.value;
          return acc;
        }, {});
        const { userCostId, userJobId, userPhases, GlAccount, userCostType } = dataObject || {};
        setFields((prev) => ({
          ...prev,
          costType: userCostType,
          jobId: userJobId,
          costId: userCostId,
          glAccount: GlAccount,
          phase: userPhases
        }));
        // Update actualaccountCodeValues
        setActualaccountCodeValues((prevValues) => {
          const updatedValues = [...prevValues];
          const updateValue = (name, value) => {
            const index = accountCodeArray?.findIndex((item) => item.name === name);
            if (index !== -1) updatedValues[index] = value;
          };
          updateValue('userCostId', userCostId);
          updateValue('userJobId', userJobId);
          updateValue('userPhases', userPhases);
          updateValue('GlAccount', GlAccount);
          updateValue('userCostType', userCostType);
          return updatedValues;
        });
      }
    } catch (error) {
      console.error('Error fetching coding data:', error);
    }
  };

  const drawLine = (
    buttonPosition,
    boxPosition,
    idx,
    dataArray,
    imageDimensions,
    showLine,
    confidence
  ) => {
    drawLinesByPosition(
      buttonPosition,
      boxPosition,
      idx,
      dataArray,
      imageDimensions,
      canvasDimensions,
      userDetails,
      getRef,
      showLine,
      confidence,
      BoxReferenceId,
      ButtonReferenceId,
      scrollContainerRef,
      reviewScrollContainerRef
    );
  };
  const slideRight = () => {
    drawLine('', '', '', '', imageDimensions);
    setToggleDraw('');
    setClickedBox(null);
    setClickedBoxRefrence('');
    setIndex((index + 1) % images?.length);
  };
  const slideLeft = () => {
    drawLine('', '', '', '', imageDimensions);
    setToggleDraw('');
    setClickedBoxRefrence('');
    setClickedBox(null);
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images?.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  const handleInputChange = (event, idx, updateField, items) => {
    event.preventDefault();
    const input = event.target.value;
    if (/^[0-9+\-*/().\s]+$/.test(input)) {
      try {
        const result = new Function(`return ${input}`)();
        if (!isNaN(result)) {
          const isCalculation =
            input.includes('+') ||
            input.includes('-') ||
            input.includes('*') ||
            input.includes('/');
          const formattedResult = isCalculation
            ? result.toFixed(2)
            : input.includes('.')
            ? result?.toFixed(input?.split('.')[1]?.length || 0)
            : input;
          if (updateField === 'header') {
            if (policyBase === 'total') {
              getUpdatedApproversAccordingtoBasePolicy(formattedResult);
            }
            setTotalAmount(formattedResult);
            handleValueChange(formattedResult, idx);
          } else {
            handleFieldChange(event, idx, items, formattedResult);
          }
        }
      } catch (error) {}
    } else if (updateField === 'header' && policyBase === 'total') {
      getUpdatedApproversAccordingtoBasePolicy(input);
    }
  };
  const handleSetValueState = (value, input) => {
    const currentValue = { ...value };

    const fieldMapping = {
      purchase_order: setPo,
      subcontract_number: setPo,
      total: setTotalAmount,
      current_payment_due: setTotalAmount,
      payment_terms: setPaymentTerms,
      application_no: setInvoiceNumber,
      invoice_number: setInvoiceNumber,
      receipt_number: setInvoiceNumber,
      Estimate_no: setInvoiceNumber,
      Po_number: setInvoiceNumber,
      DeliveryTicket_no: setInvoiceNumber,
      invoice_date: setdocumentDate,
      receipt_date: setdocumentDate,
      period_to: setdocumentDate,
      payment_status: (input) => setPrepaid(input === 'P'),
      payment_type: setPaymentType,
      userPhases: (input) => setFields((prev) => ({ ...prev, phase: input })),
      GlAccount: (input) => setFields((prev) => ({ ...prev, glAccount: input })),
      userJobId: (input) => setFields((prev) => ({ ...prev, jobId: input })),
      userCostId: (input) => setFields((prev) => ({ ...prev, costId: input })),
      glDivision: (input) => setFields((prev) => ({ ...prev, glDivision: input })),
      equipmentNo: (input) => setFields((prev) => ({ ...prev, equipmentNo: input })),
      serviceCode: (input) => setFields((prev) => ({ ...prev, serviceCode: input })),
      userCostType: (input) => setFields((prev) => ({ ...prev, costType: input })),
      division2: (input) => setFields((prev) => ({ ...prev, division2: input })),
      division3: (input) => setFields((prev) => ({ ...prev, division3: input })),
      division4: (input) => setFields((prev) => ({ ...prev, division4: input }))
    };

    const updateField = fieldMapping[currentValue?.name];

    if (updateField) {
      updateField(input);
    } else {
    }
  };

  const checkDuplicationDumentNo = async (docNum, documentVendor) => {
    const result = await checkDocNoDuplication(
      id,
      userDetails?.company?._id,
      invoiceNumber || docNum,
      documentVendor || vendors
    );
    if (result?.status === 200) {
      setDuplicateDocumentNoErrorObj(result?.data?.payload?.data);
    } else {
      setDuplicateDocumentNoErrorObj({});
    }
  };

  const handleTextClick = (index) => {
    array[index] = !array[index];
    const newButtonColors = [...buttonColors];
    newButtonColors[index] = !buttonColors[index];
    setButtonColors(newButtonColors);
  };

  const fetchDocumentData = async (id) => {
    setIsLoading(true);
    const res = await getDocumentDetails(id, callSource);
    if (res?.status === 200) {
      setToggleDraw('');
      const data = res?.data?.payload?.data;
      const docType = data?.document?.documentType;
      const isStatePrevSaved = data?.document?.saveState;
      setSavedStateFromDocument(isStatePrevSaved);
      const combineItemsPermission =
        (allowConsolidateItems &&
          (userRole === OWNER || userRole === ACCOUNTANT) &&
          (docType === INVOICE || docType === RECEIPT || docType === G702) &&
          !isStatePrevSaved) ||
        isStatePrevSaved === undefined;

      const extractValue = (name) =>
        data?.document?.codeContent?.find((item) => item.name === name)?.value || '';
      const consolidatedItems = consolidateItems(
        data?.document?.lineItems,
        data?.document?.standardizeContent?.total,
        codes,
        extractValue('userCostId'),
        extractValue('userJobId'),
        extractValue('GlAccount'),
        extractValue('userPhases'),
        extractValue('userCostType'),
        extractValue('serviceCode'),
        extractValue('equipmentNo'),
        extractValue('glDivision'),
        extractValue('division2'),
        extractValue('division3'),
        extractValue('division4')
      );
      setLineItem(combineItemsPermission ? [consolidatedItems] : data?.document?.lineItems);
      const lastActivityIndex = data?.activity?.activity?.length - 1;
      const lastUpdatedObj = data?.activity?.activity[lastActivityIndex]?.updatedObj;
      const isLastActivityEmpty =
        !lastUpdatedObj ||
        !lastUpdatedObj?.lineItems ||
        !Array?.isArray(lastUpdatedObj?.lineItems) ||
        lastUpdatedObj?.lineItems?.length === 0 ||
        lastUpdatedObj?.lineItems?.every((item) => Object?.keys(item)?.length === 0);
      setActivityObj(isLastActivityEmpty);
      let content = data?.document?.standardContent;
      content.sort((a, b) => {
        if (a.name === FROM_CONTRACTOR && b.name !== FROM_CONTRACTOR) {
          return -1;
        } else if (a.name !== FROM_CONTRACTOR && b.name === FROM_CONTRACTOR) {
          return 1;
        } else {
          return 0;
        }
      });
      const matchVendorIndex = findVendorIndex(content[0]?.value, data?.glInformation);
      setVendors(
        matchVendorIndex !== -1 && !isEmpty(data?.glInformation?.vendor)
          ? data?.glInformation?.vendor[matchVendorIndex]?.name
          : content[0]?.value
      ); // match vendor if it exist in list populate else original
      content?.forEach((data) => {
        data.isEnable = 'false';
      });
      const filteredUsers = res?.data?.payload?.data?.companyUser?.filter((item) => {
        return item?.role !== 'Accountant' && item?.signUp === true;
      });
      const filteredmails = filteredUsers?.map((item) => item?.email);
      setManualEmails(filteredmails);
      setVendorsData(res?.data?.payload?.data);
      setIndexLength(content?.length);
      setImages(data?.document?.convertedS3Keys);
      setPo(data?.document?.standardizeContent?.purchase_order);
      setPolicyBase(data?.companyPolicy?.policies?.base);
      setAnnotationType(data?.document?.documentType);
      setDocumentType(data?.document?.documentType);
      setGlInfo(data?.glInformation);
      setRefId(data?.document?.documentReference || []);
      setDocumentData(data?.document);
      if (
        isNotApPerson ||
        erpType === 'DeltekComputerEase' ||
        erpType === 'Accounting Seed' ||
        erpType === 'Other' ||
        isStatePrevSaved
      ) {
        setVendorNumber(data?.document?.vendorNumber);
      }
      const docNum = data?.document?.standardizeContent?.document_number;
      if (
        userDetails?.company?.duplicateDocNo &&
        !isEmpty(docNum) &&
        docNum !== '-' &&
        content[0]?.value !== '' &&
        content[0]?.value !== '-'
      ) {
        checkDuplicationDumentNo(docNum, content[0]?.value);
      }
      //populating content,codes,payments
      const valuesArray = [];
      const namesArray = [];
      const paymentValuesArray = [];
      const codesValuesArray = [];
      content?.forEach((element) => {
        const value = element?.value?.trim();
        if (
          (element.name === CONTENT_TOTAL || element.name === 'current_payment_due') &&
          value !== '' &&
          value !== '-'
        ) {
          const cleanedValue = value?.replace(/,/g, '');
          const formattedValue = parseFloat(cleanedValue)?.toFixed(2);
          valuesArray?.push(formattedValue);
        } else {
          valuesArray?.push(value);
        }
        namesArray.push(element.name);
      });
      let paymentArrayObj = data?.document?.paymentContent;
      paymentArrayObj?.forEach((element) => {
        const value = element?.value?.trim();
        paymentValuesArray?.push(value);
      });
      paymentArrayObj?.map((val) => handleSetValueState(val, val?.value));
      let codeArrayObj = data?.document?.codeContent;
      codeArrayObj?.forEach((element) => {
        const value = element?.value?.trim();
        codesValuesArray.push(value);
      });
      codeArrayObj?.map((val) => handleSetValueState(val, val?.value));
      content?.map((val) => handleSetValueState(val, val?.value));
      setActualValues(valuesArray);
      setActualPaymentValues(paymentValuesArray);
      setActualaccountCodeValues(codesValuesArray);
      if (!isEmpty(paymentArrayObj)) {
        setPaymentArray(rearrangeFields(paymentArrayObj));
      }
      setContent(content);
      setAccountCodeArray(data?.document?.codeContent || []);
      setDocName(data?.document?.originalFileName);
      const purchaseOrder = data?.document?.standardizeContent?.purchase_order;
      if (
        purchaseOrder !== '' &&
        purchaseOrder !== '-' &&
        userDetails?.user?.poMatchPermission &&
        (data?.document?.documentType === INVOICE || data?.document?.documentType === RECEIPT)
      ) {
        checkPoMatching('Po', true, purchaseOrder);
      }
      setIsLoading(false);
    } else if (res?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else if (res?.response?.status === 403) {
      navigate('/unauthorized');
    }
  };

  useEffect(() => {
    if (lineItem?.length > 0) {
      let newNamesArray = [...namesArray];
      const arragedData = arrangedLineitems(lineItem);
      arragedData?.map((items) => {
        items?.map((element) => {
          if (isEmpty(newNamesArray)) {
            newNamesArray.push(element?.name);
          } else {
            if (!newNamesArray?.includes(element?.name)) {
              newNamesArray.push(element?.name);
            }
          }
        });
      });
      const initialObj = newNamesArray?.reduce((accumulator, value) => {
        return { ...accumulator, [value]: [] };
      }, {});
      arragedData?.forEach((items) => {
        let newArray = [];
        items?.forEach((element) => {
          initialObj[element?.name].push(element?.value);
          newArray.push(element?.name);
        });
      });
      setObj(initialObj);
      setNamesArray(newNamesArray);
      if (Object.keys(lineItemInitialObj)?.length === 0 && !consolidating && !fetchingPO) {
        setLineItemInitialObj(initialObj);
      }
    }
  }, [lineItem, lineItemInitialObj]);

  useEffect(() => {
    setLineItemsData(obj);
    let newArr = new Array(obj?.Quantity?.length || 0).fill(0);
    setTolalItems(newArr);
  }, [lineItemInitialObj, obj]);

  useEffect(() => {
    setLineItemsData(obj);
    const sum = obj?.Amount?.reduce((total, amount) => {
      const numericValue = parseFloat(String(amount).replace(/[^\d.-]/g, ''));
      return total + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);
    setLineItemAmountSum(sum);
  }, [obj]);

  useEffect(() => {
    fetchDocumentData(id);
  }, [id]);

  useEffect(() => {
    const permission = lineitemsMatchingPermission && obj?.Amount?.length >= 1;
    if (permission && annotationType !== 'DeliveryTicket') {
      const sanitizeAmount = (amount) => parseFloat(String(amount).replace(/[^0-9.-]+/g, ''));
      const parsedTotalAmount = sanitizeAmount(totalAmount);
      const parsedLineItemAmountSum = sanitizeAmount(lineItemAmountSum);
      setLineItemsTotalMatch(parsedTotalAmount?.toFixed(2) === parsedLineItemAmountSum?.toFixed(2));
    }
  }, [totalAmount, lineItemAmountSum]);

  useEffect(() => {
    const newValidationErrors = {};
    const newValidationLineItemsErrors = {};

    // Validate actual values
    actualValues?.forEach((value, idx) => {
      const validation = validateData(
        idx === 0 ? vendors : value,
        content[idx]?.name,
        annotationType,
        prepaid,
        paymentType
      );
      if (!validation.status) {
        newValidationErrors[content[idx]?.name] = validation.message;
      }
    });

    // Validate line items
    lineItem?.forEach((item, index) => {
      headers?.forEach((header) => {
        const itemValue = obj?.[header]?.[index];
        const validation = validateLineItems(
          header,
          itemValue,
          annotationType,
          erpType,
          isConstructionCompany
        );
        if (!validation.status) {
          if (!newValidationLineItemsErrors[index]) {
            newValidationLineItemsErrors[index] = {};
          }
          newValidationLineItemsErrors[index][header] = validation.message;
        }
      });
    });

    setValidationErrors(newValidationErrors);
    setValidationLineItemsErrors(newValidationLineItemsErrors);
  }, [
    actualValues,
    annotationType,
    vendors,
    lineItem,
    obj,
    erpType,
    isConstructionCompany,
    content
  ]);

  useEffect(() => {
    if (
      (userRole === OWNER || userRole === ACCOUNTANT) &&
      (fields.jobId === undefined || fields.jobId?.trim() === '') &&
      vendorNumber &&
      userDetails?.company?.defaultCoding &&
      ((isDocumentSaved && savedStatefromDocument) ||
        !savedStatefromDocument ||
        savedStatefromDocument === undefined)
    ) {
      setValueChanged(true);
      fetchCoding(vendorNumber);
    }
  }, [vendorNumber]);
  useEffect(() => {
    if (
      glInfo?.vendor &&
      vendorsData?.document &&
      vendors &&
      ((isDocumentSaved && savedStatefromDocument) ||
        !savedStatefromDocument ||
        savedStatefromDocument === undefined) &&
      (userRole === OWNER || userRole === ACCOUNTANT)
    ) {
      const matchVendorIndex = findVendorIndex(vendors, glInfo);
      if (matchVendorIndex !== -1) {
        if (!isEmpty(glInfo?.vendor)) {
          setVendorNumber(glInfo?.vendor[matchVendorIndex]?.id);
        } else {
          setVendorNumber(vendorsData?.document?.vendorNumber);
        }
      }
    }
    if (
      userDetails?.company?.duplicateDocNo &&
      !isEmpty(invoiceNumber) &&
      invoiceNumber !== '-' &&
      vendors !== '' &&
      vendors !== '-'
    ) {
      checkDuplicationDumentNo();
    }
  }, [glInfo?.vendor, vendors, vendorsData?.document]);
  useEffect(async () => {
    if (
      (documentType?.toLowerCase() !== annotationType?.toLowerCase() ||
        (documentType === 'Quote' && annotationType !== ESTIMATE) ||
        (documentType === 'Delivery Ticket' && annotationType !== DELIVERY_TICKET)) &&
      documentType !== '' &&
      documentType !== null
    ) {
      setIsLoading(true);
      const payload = {
        document_id: id,
        document_type:
          documentType === 'Quote'
            ? ESTIMATE
            : documentType === 'Delivery Ticket'
            ? DELIVERY_TICKET
            : documentType
      };
      const res = await updateDocumentType(payload);
      if (res?.status === 200) {
        callSaveState.current = false;
        if (documentType === 'Split') {
          dispatch(get_tabs({ ...globalActiveTab, documentChildActiveTab: 'Classify/Split' }));
        }
        navigate('/home');
      } else {
        showToast(res?.response?.data?.metadata?.message || res?.data?.metadata?.message, 'error');
      }
      setIsLoading(false);
    }
  }, [documentType]);

  const handleFieldChange = (e, index, fieldName, val) => {
    const { value } = e.target;
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      newObj[fieldName][index] = val || value || [];
      setLineItemsData(newObj);
      return newObj;
    });
  };
  const submitData = async (event, docStatus, btnType, saveState, docId) => {
    const updateId = docId || id;
    if (event) event.preventDefault();
    if (updateId !== documentData?._id) {
      handleNavigation(event);
      return;
    }
    // Regenerate content format
    const updatedData = regenerateContentFormat([...content], actualValues, vendors, paymentTerms);
    const updatedPayment = regeneratePaymentFormat(
      [...paymentArray],
      actualPaymentValues,
      paymentType,
      prepaid
    );
    const updatedCodes = regenerateCodeFormat([...accountCodeArray], actualaccountCodeValues);
    // Process line items
    let lineItemsCopy = [...lineItem];
    getLineItemsCopy(lineItemsCopy, lineItemsData);
    const buttonName = btnType || buttonType;
    const newData = {
      modifier: user,
      content: updatedData,
      vendorNumber,
      lineItems: lineItemsCopy,
      manualUsers: selectedEmails,
      disablePolicy: isEmpty(selectedEmails) && buttonName === 'manual' ? true : false,
      status: docStatus,
      callSource,
      saveHistory: saveState || false,
      paymentContent: updatedPayment,
      codeContent: updatedCodes,
      consolidatedLineItems: combineItems
    };

    const updateResponse = await updateDocument(updateId, newData);
    if (updateResponse?.data?.metadata?.status === 'SUCCESS') {
      if (event && saveState !== true)
        showToast(updateResponse?.data?.metadata?.message, 'success');
      if (saveState !== true) {
        setIsLoading(true);
        const prevId = currentId;
        dispatch(get_pending_data(documents?.filter((doc) => doc._id !== prevId)));
        handleNavigation(event);
      }
      const searchedKey = sessionStorage.getItem('searchedKey');
      const getDocResponse = await getSelectedDocs(searchedKey || '', savedFilters, callSource);
      if (getDocResponse?.response?.status === 401) {
        sessionStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(getDocResponse?.data?.payload?.data));
    } else if (event && saveState !== true) {
      showToast(
        updateResponse?.response?.data?.metadata?.message ||
          updateResponse?.data?.metadata?.message,
        'error'
      );
    }
  };

  const handleNavigation = async (event) => {
    if (documents?.length > 0 && currentIndex !== documents.length - 1 && event) {
      navigateToNextDocument();
    } else {
      callSaveState.current = false;

      navigate(userRole === VENDOR ? '/vendor' : '/home');
    }
  };

  const navigateToNextDocument = () => {
    const nextIndex = (currentIndex + 1) % documents.length;
    const nextDocumentId = documents[nextIndex]._id;
    resetStates();
    setCurrentId(nextDocumentId);
    navigate(`/canvas/${nextDocumentId}`);
    fetchDocumentData(nextDocumentId);
    drawLine('', '', '', '', imageDimensions);
  };

  const resetStates = () => {
    // Reset all the states as per the requirements
    setIsDrawerOpen(false);
    setImageDimensions({});
    setImages([]);
    setButtonColors([]);
    setTypeCheck(false);
    setVendorNumberCheck(false);
    setIsDocumentSaved(false);
    setVendorNumber('');
    setLineItem([]);
    setDocumentoaded(false);
    setIndex(0);
    setOpenApprovePrompt(false);
    setLineItemInitialObj({});
    setClickedBox(null);
    setClickedBoxRefrence('');
    setToggleDraw('');
    setAnchorEl(false);
    setNamesArray([]);
    setDocumentData({});
  };

  const checkPoMatching = async (callSrc, checkcall, purchaseOrder) => {
    setFetchingPO(true);
    setMatchingCallsrc(callSrc);
    let lineItemsCopy = [...lineItem];
    getLineItemsCopy(lineItemsCopy, lineItemsData);
    const params = {
      referenceDocumentId: callSrc === 'DeliveryTicket' ? refId?.map((item) => item?.id) : '',
      matchWith: callSrc,
      poNumber: callSrc === 'DeliveryTicket' ? invoiceNumber : purchaseOrder || po,
      total: totalAmount,
      lineItems: formatLineItems(lineItemsCopy),
      callPurpose: checkcall ? 'checkErpExistence' : 'Match',
      date: documentDate
    };
    const result = await matchPO(id, params);
    if (!checkcall) {
      if (result?.status == 200) {
        showToast(result?.data?.metadata?.message, 'success');
      } else {
        showToast(
          result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
          'error'
        );
      }
    }
    if (callSrc === 'DeliveryTicket') {
      setIsInvoiceNumberMatched(result?.data?.payload?.data?.poNumber);
    } else {
      setIsPoMatched(result?.data?.payload?.data?.poNumber);
    }
    if (!checkcall) {
      setIsAmountMatched(result?.data?.payload?.data?.total);
      setIsDateMatched(result?.data?.payload?.data?.date);
      setIsLineItemsMatched(result?.data?.payload?.data?.lineItem);
    }
    setFetchingPO(false);
  };

  const handleClick = (buttonRef, referenceId, idx, dataArray, showLine, page, confidence) => {
    if (toggleDraw !== referenceId) {
      if (isValidPage(page)) {
        setIndex(page);
      }
      setToggleDraw(referenceId);
      setTimeout(() => {
        let buttonPosition = getPosition('button_' + buttonRef, getRef);
        let boxPosition = getPosition('box_' + referenceId, getRef);
        BoxReferenceId = referenceId;
        ButtonReferenceId = referenceId;
        if (buttonPosition && boxPosition?.width !== 0 && boxPosition?.height !== 0) {
          drawLine(
            buttonPosition,
            boxPosition,
            idx,
            dataArray,
            imageDimensions,
            showLine,
            Math.round(confidence)
          );
        } else {
          drawLine('', '', idx, dataArray, imageDimensions, '');
          setClickedBox(null);
          setClickedBoxRefrence('');
        }
      }, 100);
    } else {
      drawLine('', '', idx, dataArray, imageDimensions, '');
      setToggleDraw('');
      setClickedBox(null);
      setClickedBoxRefrence('');
    }
  };

  const handleBoxClick = (name, value, ref, topPosition) => {
    const position = getPosition(ref, getRef);
    if (!deepEqual(clickedBoxRefrence, position)) {
      setClickedBoxRefrence(position);
      if (position && position?.top && position?.left) {
        setClickedBox({ name, value });
        setBoxPosition({
          top: topPosition + position.height + 5,
          left: position.left - 550
        });
      }
    } else {
      setClickedBox(null);
      setClickedBoxRefrence('');
    }
  };

  const handleValueChange = (value, index) => {
    let updatedArray = [...actualValues];
    updatedArray[index] = value;
    setActualValues(updatedArray);
  };

  const getUpdatedApproversAccordingtoBasePolicy = async (value) => {
    if (userRole === OWNER || userRole === ACCOUNTANT) {
      const res = await getUpdatedApprovers(policyBase, value);
      if (res?.status === 200) {
        setApprovers(res?.data?.payload?.data);
      }
    }
  };

  const handleButtonClick = async (e, status, type, saveState, docId) => {
    setLoadingButtons(true);
    try {
      await submitData(e, status, type, saveState, docId);
    } finally {
      setLoadingButtons(false);
    }
  };

  const shouldCheckPoMatching = (value) => {
    const isRelevantDocumentType = annotationType === INVOICE || annotationType === RECEIPT;
    const hasPoMatchPermission = userDetails?.user?.poMatchPermission;
    const isPoValid = po !== '' && po !== '-';
    const isRelevantValueName =
      value?.name === 'purchase_order' || value?.name === 'subcontract_number';
    return isRelevantValueName && hasPoMatchPermission && isRelevantDocumentType && isPoValid;
  };
  const formik = useFormik({
    initialValues: {
      fieldName: ''
    },
    onSubmit: (values) => {
      callSaveState.current
        ? handleButtonClick(null, documentData?.status, 'main', true)
        : undefined;
    }
  });

  useEffect(() => {
    return () => formik.handleSubmit();
  }, []);
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        overflow: 'hidden !important'
      }}>
      <Layout
        buttonArray={getButtonArray(documentData?.status)}
        path="canvas"
        show={userDetails?.role === OWNER ? 'showUser' : ''}
        submitData={submitData}
        id={id}
        docName={docName}
        padding={true}
        approvers={approvers}
        annotationType={annotationType}
        setImages={setImages}
        setRefId={setRefId}
        refId={refId}
        vendor={vendors}
        po={po}
        invoiceNumber={invoiceNumber}
        date={documentDate}
        images={images}
        lineItem={lineItem}
        lineItemsData={lineItemsData}
        setIndex={setIndex}
      />
      <Container maxWidth={null} style={{ padding: 0, overflow: 'hidden !important' }}>
        <>
          <div id="myCanvas" className="canvas_style"></div>
          <div className="main-container">
            <Box className="left-area" component="form" noValidate>
              {isLoading ? (
                <div className="loader" style={{ display: 'flex', marginTop: '10rem' }}>
                  <CircularProgress size={75} sx={{ color: Colors.DARKBLUE }} />
                </div>
              ) : (
                <Grid
                  className="review-bar"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '0.2em'
                    }
                  }}
                  ref={reviewScrollContainerRef}>
                  <div className="flex-div">
                    <p className="details-heading">{DOCUMENT_DETAILS}</p>
                    {images?.length > 1 && (
                      <Grid
                        sx={{
                          height: 'max-content',
                          position: 'absolute',
                          right: {
                            xs: userRole === 'Owner' ? '72%' : '73%',
                            lg: userRole === 'Owner' ? '60%' : '70%'
                          },
                          top: { xs: '60px', lg: '23px' }
                        }}>
                        <PaginationButtons
                          disableFirstButton={index === 0}
                          disableSecondButton={index === images.length - 1}
                          slideLeft={slideLeft}
                          slideRight={slideRight}
                          index={index}
                          images={images}
                        />
                      </Grid>
                    )}
                  </div>
                  <AccountsInput
                    label={VENDOR_NUMBER}
                    state={vendorNumberCheck}
                    setState={setVendorNumberCheck}
                    data={glInfo?.vendor?.map((vendor) => vendor?.id)}
                    setPermission={setVendorNumber}
                    permission={vendorNumber}
                    allowInput={false}
                    setValueChanged={setValueChanged}
                    name="vendorNumber"
                    setValidationErrors={setValidationErrors}
                    annotationType={annotationType}
                  />
                  <ContentFields
                    content={content}
                    vendors={vendors}
                    actualValues={actualValues}
                    glInfo={glInfo}
                    annotationType={annotationType}
                    smallScreen={smallScreen}
                    handleClick={handleClick}
                    handleTextClick={handleTextClick}
                    buttonColors={buttonColors}
                    isPoMatched={isPoMatched}
                    isDateMatched={isDateMatched}
                    invoiceNumber={invoiceNumber}
                    paymentTerms={paymentTerms}
                    setPaymentTerms={setPaymentTerms}
                    setOpen={setOpen}
                    open={open}
                    images={images}
                    checkDuplicationDumentNo={checkDuplicationDumentNo}
                    shouldCheckPoMatching={shouldCheckPoMatching}
                    checkPoMatching={checkPoMatching}
                    handleValueChange={handleValueChange}
                    handleSetValueState={handleSetValueState}
                    handleBoxClick={handleBoxClick}
                    handleButtonClick={handleButtonClick}
                    setRef={setRef}
                    imageDimensions={imageDimensions}
                    prepaid={prepaid}
                    setVendors={setVendors}
                    policyBase={policyBase}
                    getUpdatedApproversAccordingtoBasePolicy={
                      getUpdatedApproversAccordingtoBasePolicy
                    }
                    isAmountMatched={isAmountMatched}
                    lineItemsTotalMatch={lineItemsTotalMatch}
                    duplicateDocumentNoErrorObj={duplicateDocumentNoErrorObj}
                    handleInputChange={handleInputChange}
                    isInvoiceNumberMatched={isInvoiceNumberMatched}
                    lineItemAmountSum={lineItemAmountSum}
                    setDocumentoaded={setDocumentoaded}
                    documentoaded={documentoaded}
                    setTypeCheck={setTypeCheck}
                    typeCheck={typeCheck}
                    documentType={documentType}
                    setDocumentType={setDocumentType}
                    vendorsData={vendorsData}
                    po={po}
                  />

                  {!isEmpty(accountCodeArray) && (
                    <>
                      <CoddingFields
                        glInfo={glInfo}
                        accountCodeArray={accountCodeArray}
                        actualaccountCodeValues={actualaccountCodeValues}
                        setActualaccountCodeValues={setActualaccountCodeValues}
                        allowInput={false}
                        setValueChanged={setValueChanged}
                        setValidationErrors={setValidationErrors}
                        annotationType={annotationType}
                        handleTextClick={handleTextClick}
                        buttonColors={buttonColors}
                        handleSetValueState={handleSetValueState}
                        policyBase={policyBase}
                        getUpdatedApproversAccordingtoBasePolicy={
                          getUpdatedApproversAccordingtoBasePolicy
                        }
                        setFields={setFields}
                      />
                    </>
                  )}
                  {!isEmpty(paymentArray) && (
                    <PaymentFields
                      dataArray={paymentArray}
                      buttonColors={buttonColors}
                      prepaid={prepaid}
                      setPrepaid={setPrepaid}
                      setActualPaymentValues={setActualPaymentValues}
                      actualPaymentValues={actualPaymentValues}
                      annotationType={annotationType}
                      handleTextClick={handleTextClick}
                      paymentType={paymentType}
                      setPaymentType={setPaymentType}
                      setPaymentValidationErrors={setPaymentValidationErrors}
                    />
                  )}
                  <DocumentButtons
                    checkPoMatching={checkPoMatching}
                    accountCodeArray={accountCodeArray}
                    setActualaccountCodeValues={setActualaccountCodeValues}
                    setFields={setFields}
                    annotationType={annotationType}
                    lineItem={lineItem}
                    lineItemsTotalMatch={lineItemsTotalMatch}
                    openApprovePrompt={openApprovePrompt}
                    setOpenApprovePrompt={setOpenApprovePrompt}
                    handleButtonClick={handleButtonClick}
                    loadingButtons={loadingButtons}
                    glInfo={glInfo}
                    paymentTerms={paymentTerms}
                    duplicateDocumentNoErrorObj={duplicateDocumentNoErrorObj}
                    vendors={vendors}
                    hasErrors={
                      !isEmpty(validationErrors) && Object.keys(validationErrors)?.length > 0
                    }
                    hasPaymentErrors={
                      !isEmpty(paymentValidationErrors) &&
                      Object.keys(paymentValidationErrors)?.length > 0
                    }
                    hasLineItemErrors={
                      !isEmpty(validationLineItemsErrors) &&
                      Object.keys(validationLineItemsErrors)?.length > 0
                    }
                    typeCheck={typeCheck}
                    vendorNumberCheck={vendorNumberCheck}
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    approvers={approvers}
                    manualEmails={manualEmails}
                    selectedEmails={selectedEmails}
                    setSelectedEmails={setSelectedEmails}
                    fetchingPO={fetchingPO}
                    openPrompt={openPrompt}
                    setOpenPrompt={setOpenPrompt}
                    handleOpen={handleOpen}
                    refId={refId}
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    isPoMatched={isPoMatched}
                    isInvoiceNumberMatched={isInvoiceNumberMatched}
                    isLineItemsMatched={isLineItemsMatched}
                    handleClick={handleClick}
                    setRef={setRef}
                    setLineItemsData={setLineItemsData}
                    data={getLineItemsCoddingArray(glInfo)}
                    fields={fields}
                    obj={obj}
                    setObj={setObj}
                    namesArray={namesArray}
                    isLastActivityEmpty={activityObj}
                    lineItemInitialObj={lineItemInitialObj}
                    showPrompt={checkObjectValues(resultArray)}
                    valueChanged={valueChanged}
                    id={id}
                    callSource={callSource}
                    setLineItem={setLineItem}
                    setTolalItems={setTolalItems}
                    totalItems={totalItems}
                    headers={headers}
                    handleFieldChange={handleFieldChange}
                    handleBoxClick={handleBoxClick}
                    imageDimensions={imageDimensions}
                    setClickedBoxIndex={setClickedBoxIndex}
                    setIsLineItemsMatched={setIsLineItemsMatched}
                    matchingCallsrc={matchingCallsrc}
                    combineItems={combineItems}
                    setCombineItems={setCombineItems}
                    disableConsolidate={disableConsolidate}
                    handleAmountCalculator={handleInputChange}
                    buttonColors={buttonColors}
                    content={content}
                    setTypeCheck={setTypeCheck}
                    setVendorNumberCheck={setVendorNumberCheck}
                    setButtonColors={setButtonColors}
                    invoiceNumber={invoiceNumber}
                    setButtonType={setButtonType}
                    setFetchingPO={setFetchingPO}
                    lineItemsData={lineItemsData}
                    totalAmount={totalAmount}
                    po={po}
                    setIsInvoiceNumberMatched={setIsInvoiceNumberMatched}
                    setIsPoMatched={setIsPoMatched}
                    setConsolidating={setConsolidating}
                    array={array}
                    setIsAmountMatched={setIsAmountMatched}
                    setIsDateMatched={setIsDateMatched}
                    setIsInitialized={setIsInitialized}
                    isInitialized={isInitialized}
                  />
                </Grid>
              )}
            </Box>
            <RightSection
              patternBackground={patternBackground}
              drawLine={drawLine}
              imageDimensions={imageDimensions}
              setToggleDraw={setToggleDraw}
              clickedBox={clickedBox}
              setClickedBoxRefrence={setClickedBoxRefrence}
              setClickedBox={setClickedBox}
              isLoading={isLoading}
              id={id}
              setCurrentId={setCurrentId}
              resetStates={resetStates}
              handleButtonClick={handleButtonClick}
              images={images}
              index={index}
              imgElement={imgElement}
              content={content}
              ocrContent={documentData?.ocrOtherContent}
              boxPosition={boxPosition}
              replaceContentNames={replaceContentNames}
              actualValues={actualValues}
              handleValueChange={handleValueChange}
              handleSetValueState={handleSetValueState}
              handleInputChange={handleInputChange}
              invoiceNumber={invoiceNumber}
              vendors={vendors}
              setVendors={setVendors}
              checkDuplicationDumentNo={checkDuplicationDumentNo}
              shouldCheckPoMatching={shouldCheckPoMatching}
              checkPoMatching={checkPoMatching}
              lineItem={lineItem}
              arrangedLineitems={arrangedLineitems}
              headers={headers}
              clickedBoxIndex={clickedBoxIndex}
              obj={obj}
              handleFieldChange={handleFieldChange}
              isDrawerOpen={isDrawerOpen}
              glInfo={glInfo}
              policyBase={policyBase}
              getUpdatedApproversAccordingtoBasePolicy={getUpdatedApproversAccordingtoBasePolicy}
              paymentTerms={paymentTerms}
              setPaymentTerms={setPaymentTerms}
              scrollContainerRef={scrollContainerRef}
              annotationType={annotationType}
              setRef={setRef}
              buttonColors={buttonColors}
              handleTextClick={handleTextClick}
              setApprovers={setApprovers}
              setIsDocumentSaved={setIsDocumentSaved}
              setCanvasDimensions={setCanvasDimensions}
              setImageDimensions={setImageDimensions}
              currentStatus={documentData?.status}
              documentType={documentType}
            />
          </div>
        </>
      </Container>
    </Grid>
  );
};

export default React.memo(ResponsivePage);
