import axios from 'axios';
import { baseUrl } from '../constants/appConstants';
import { setHeaders, setERPType, setSignOutHeaders } from './common';

const BASE_URL = baseUrl();

export const uploadFiles = async (data, type, id) => {
  const formData = new FormData();
  for (let i = 0; i < data.length; i++) {
    formData.append('files', data[i]);
  }
  try {
    return await axios.post(
      BASE_URL + `api/v1/file/upload-files-v2?documentType=${type}&documentReference=${id}`,
      formData,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getSelectedDocs = async (searchKey, payload, callSource) => {
  const newData = encodeURIComponent(searchKey);
  try {
    return await axios.post(
      BASE_URL + `api/v1/document/v3/get-document?keyword=${newData}&callSource=${callSource}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getDocumentDetails = async (id, callSource) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/document/get/document/V2/${id}?callSource=${callSource}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getActivity = async (id, documentType) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/document/v3/get-document-activity/${id}?documentType=${documentType}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const updateDocument = async (id, body) => {
  try {
    return await axios.put(BASE_URL + `api/v1/document/update/content/${id}`, body, setHeaders());
  } catch (error) {
    return error;
  }
};

export const sendQuickbook = async (id) => {
  try {
    return await axios.get(BASE_URL + `api/v1/integration/send-to-erp/${id}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const downloadCSV = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/csv/get-all-documents-csv', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const sendEmailToVendor = async (payload) => {
 
  try {
    return await axios.post(BASE_URL + 'api/v1/email/send-email-to-vendor', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const createCsv = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + 'api/v1/email/send-email-selected-documents',
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const saveIntegration = async (integrationData) => {
  try {
    return await axios.post(BASE_URL + `api/v1/oauth/saveIntegration`, integrationData, {
      headers: {
        ...setHeaders().headers,
        ...setERPType().headers
      }
    });
  } catch (error) {
    return error;
  }
};

export const connectIntegration = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/oauth/authUri`, {
      headers: {
        ...setHeaders().headers,
        ...setERPType().headers
      }
    });
  } catch (error) {
    return error;
  }
};

export const diconnectIntegration = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/oauth/disconnect`, {
      headers: {
        ...setHeaders().headers,
        ...setERPType().headers
      }
    });
  } catch (error) {
    return error;
  }
};
export const sendEmailtoAdmin = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/email/send-email-admin', payload);
  } catch (error) {
    return error;
  }
};

export const customerSignup = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/user/sign-up', payload);
  } catch (error) {
    return error;
  }
};

export const createOperator = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/admin-create-operator', payload);
  } catch (error) {
    return error;
  }
};

export const findUser = async (email) => {
  try {
    return await axios.get(BASE_URL + `api/v1/awsCognito/get-user?email=${email}`);
  } catch (error) {
    return error;
  }
};

export const getAllOperators = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v1/awsCognito/get-all-operators');
  } catch (error) {
    return error;
  }
};

export const signUp = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/sign-up', payload);
  } catch (error) {
    return error;
  }
};

export const deleteOperator = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/admin-delete-operator', payload);
  } catch (error) {
    return error;
  }
};

export const confirmSignup = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/confirm-sign-up', payload);
  } catch (error) {
    return error;
  }
};

export const operatorPassword = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/admin-confirm-password', payload);
  } catch (error) {
    return error;
  }
};
export const resendCode = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/resend-confirmation-code', payload);
  } catch (error) {
    return error;
  }
};

export const signIn = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/sign-in', payload);
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/forget-password', payload);
  } catch (error) {
    return error;
  }
};

export const updatePassword = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/awsCognito/update-password', payload);
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v1/user/get-all-user', setHeaders());
  } catch (error) {
    return error;
  }
};

export const getAllErrors = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v1/document/get/error/documents', setHeaders());
  } catch (error) {
    return error;
  }
};

export const getErrorDocument = async (id) => {
  try {
    return await axios.get(BASE_URL + `api/v1/document/geterrordocument/${id}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const uploadPdf = async (data) => {
  const files = new FormData();
  for (let i = 0; i < data.length; i++) {
    files.append('files', data[i]);
  }
  try {
    return await axios.post(BASE_URL + 'api/v1/file/upload-split-doc', files, setHeaders());
  } catch (error) {
    return error;
  }
};

export const mergePdf = async (payload) => {
  try {
    return await axios.post(BASE_URL + `api/v1/file/merge-files-v2`, payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const uploadCsv = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/user/update-user-gl-info', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const getGlInfo = async (email) => {
  try {
    return await axios.get(BASE_URL + `api/v1/user/get-user-gl-info?email=${email}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const getUserInfo = async (name) => {
  try {
    return await axios.get(BASE_URL + `api/v1/user/get-user?userName=${name}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const generateBill = async (payload) => {
  try {
    return await axios.post(BASE_URL + `api/v1/payment/create-payment-link`, payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const getPaymentIntent = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + 'api/v1/payment/create-payment-intent',
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};
export const syncData = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/integration/sync-files', payload, {
      headers: {
        ...setHeaders().headers,
        ...setERPType().headers
      }
    });
  } catch (error) {
    return error;
  }
};

export const downloadQBDFile = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + '/api/v1/integration/qbd/get-qwc-file',
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const updateProfile = async (payload) => {
  try {
    return await axios.patch(BASE_URL + 'api/v1/user/update-user', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const changePaymentStatus = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + 'api/v1/payment/update-payment-status',
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const sendToQBD = async (id) => {
  try {
    return await axios.get(BASE_URL + `api/v1/integration/qbd/sync-documentId/${id}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const matchPO = async (id, payload) => {
  try {
    return await axios.post(
      BASE_URL + `api/v1/integration/match-supporting-doc/${id}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getVendors = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v1/vendor/get-all-vendor', setHeaders());
  } catch (error) {
    return error;
  }
};

export const getVendorsDocument = async (id) => {
  try {
    return await axios.get(BASE_URL + `api/v3/admin/get-vendor-doc/${id}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const addRole = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/user/add-role', payload, setHeaders());
  } catch (error) {
    return error;
  }
};
export const getOwnerUsers = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v1/user/get-roles', setHeaders());
  } catch (error) {
    return error;
  }
};

export const addPolicy = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v3/company/add-approval-policy', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (payload) => {
  try {
    const url = BASE_URL + 'api/v1/user/delete-role';
    const headers = setHeaders();
    return await axios.delete(url, { ...headers, data: payload });
  } catch (error) {
    return error;
  }
};

export const getPolicy = async () => {
  try {
    return await axios.get(BASE_URL + 'api/v3/company/get-approval-policy', setHeaders());
  } catch (error) {
    return error;
  }
};

export const getInfo = async (name, headers) => {
  try {
    return await axios.get(BASE_URL + `api/v1/user/get-user?userName=${name}`, headers());
  } catch (error) {
    return error;
  }
};

export const deletePolicy = async () => {
  try {
    const response = await axios.delete(
      BASE_URL + 'api/v3/company/delete-approval-policy',
      setHeaders()
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async (payload) => {
  try {
    const url = BASE_URL + 'api/v1/document/v3/delete-document';
    const headers = setHeaders();
    return await axios.delete(url, { ...headers, data: payload });
  } catch (error) {
    throw error;
  }
};
export const getDashboardRecord = async (start, end, vendorType, callSource) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v1/document/v3/get-all-info-document-dashboard?startDate=${start}&endDate=${end}&vendorType=${vendorType}&callSource=${callSource}
    `,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};
export const addManualUser = async (data, email, amount, name) => {
  const files = new FormData();
  for (let i = 0; i < data.length; i++) {
    files.append('files', data[i]);
  }
  files.append('email', email);
  files.append('amount', amount);
  files.append('name', name);
  try {
    const response = await axios.post(BASE_URL + 'api/v3/admin/add-manual-user', files, {
      ...setHeaders()
    });

    return response;
  } catch (error) {
    return error;
  }
};
export const getManualUsers = async () => {
  try {
    return await axios.get(BASE_URL + `api/v3/admin/get-all-manual-user`, setHeaders());
  } catch (error) {
    return error;
  }
};
export const getManualUserPaymentIntent = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v3/admin/create-payment-intent', payload);
  } catch (error) {
    return error;
  }
};
export const changeManualPaymentStatus = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v3/admin/update-payment-status', payload);
  } catch (error) {
    return error;
  }
};
export const processDuplicateDocument = async (id) => {
  try {
    return await axios.post(
      BASE_URL + `api/v1/document/v3/process-duplicate-document?documentId=${id}`,
      '',
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const registerVendor = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/vendor/sign-up', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const updateVendor = async (payload) => {
  try {
    return await axios.post(BASE_URL + 'api/v1/vendor/update-doc-limit', payload, setHeaders());
  } catch (error) {
    return error;
  }
};

export const getDocumentCount = async (startDate, endDate, email, role) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v3/admin/get-doc-count?startDate=${startDate}&endDate=${endDate}&email=${email}&role=${role}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const downloadFile = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + 'api/v1/document/v3/download-documents',
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getApprovers = async (documentId) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/document/v3/get-document-approver/${documentId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getDashboardDocumentCount = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/document/v3/get-document-count`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const signOut = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/awsCognito/sign-out`, {
      headers: {
        ...setHeaders().headers,
        ...setSignOutHeaders().headers
      }
    });
  } catch (error) {
    return error;
  }
};

export const searchDocumentsbyVendorName = async (vendorName, documentId) => {
  try {
    const newVendor = encodeURIComponent(vendorName);
    return await axios.get(
      BASE_URL +
        `api/v3/attachment/upload-attachment-search?venderName=${newVendor}&docNumber=${documentId}`,
      { ...setHeaders() }
    );
  } catch (error) {
    return error;
  }
};

export const uploadAttachments = async (data, documentIds, instrumentIds, companyId) => {
  const formData = new FormData();
  for (let i = 0; i < data.length; i++) {
    formData.append('files', data[i]);
  }
  const documentParams = documentIds?.map((id) => `documentRef=${id}`)?.join('&');
  const instrumentParams = instrumentIds?.map((id) => `instrumentNo=${id}`)?.join('&');
  const url = `${BASE_URL}api/v3/attachment/upload-attachment-files?${documentParams}&${instrumentParams}&companyId=${companyId}`;
  try {
    return await axios.post(url, formData, setHeaders());
  } catch (error) {
    return error;
  }
};

export const searchAttachmentById = async (id) => {
  try {
    return await axios.get(BASE_URL + `api/v3/attachment/search-attachment?keyword=${id}`, {
      ...setHeaders()
    });
  } catch (error) {
    return error;
  }
};

export const editAttachment = async (payload, id) => {
  try {
    return await axios.patch(
      BASE_URL + `api/v3/attachment/update-attachment/${id}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const deleteAttachment = async (id, companyId) => {
  try {
    return await axios.delete(
      BASE_URL + `api/v3/attachment/delete-attachment/${id}?companyId=${companyId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const removeAttachment = async (docId, id, companyId) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v1/document/v3/remove-attachment/${docId}?attachmentId=${id}&companyId=${companyId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const searchDeliveryTicketbyVendorName = async (
  vendorName,
  date,
  po,
  invoiceNumber,
  show
) => {
  try {
    const newVendor = encodeURIComponent(vendorName);
    const newPo = encodeURIComponent(po);
    const newInvoiceNumber = encodeURIComponent(invoiceNumber);
    return await axios.get(
      BASE_URL +
        `api/v1/document/v3/search-by-filters?date=${date}&vendor=${newVendor}&purchaseOrder=${newPo}&documentNumber=${newInvoiceNumber}&filter=${show}`,
      { ...setHeaders() }
    );
  } catch (error) {
    return error;
  }
};

export const attachDeliveryTicket = async (payload) => {
  try {
    return await axios.patch(
      BASE_URL + `api/v1/document/v3/attach-documents`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const searchbyChequeNumber = async (key) => {
  try {
    const newKey = encodeURIComponent(key);
    return await axios.get(BASE_URL + `api/v1/integration/search-check_no/${newKey}`, {
      headers: {
        ...setHeaders().headers,
        ...setERPType().headers
      }
    });
  } catch (error) {
    return error;
  }
};

export const getTaskQueue = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/integration/qbd/get-queue`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const clearQueue = async () => {
  try {
    return await axios.get(BASE_URL + `api/v1/integration/qbd/reset-queue`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const deleteDeliveryTicket = async (payload) => {
  try {
    return await axios.patch(
      BASE_URL + `api/v1/document/v3/remove-attached-documents`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getAttachmentsbyDocId = async (id) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/document/v3/get-attach-documents/${id}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const FetchDefaultCoding = async (number) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/integration/get-default-codes?vendor=${number}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};
export const getSupportingDocument = async (id, payload) => {
  try {
    return await axios.post(
      BASE_URL + `api/v1/integration/get-document-by-erp/${id}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const checkDocNoDuplication = async (id, companyId, docNo, vendor) => {
  try {
    const newKey = encodeURIComponent(vendor);
    return await axios.get(
      BASE_URL +
        `api/v1/document/v3/check-duplicate-document-number/${id}?companyId=${companyId}&documentNumber=${docNo}&vendor=${newKey}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getAllBatches = async (companyId) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/file/get-all-split-extraction?companyId=${companyId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getBatchById = async (batchId) => {
  try {
    return await axios.get(BASE_URL + `api/v1/file/split-batch-by-id/${batchId}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const updateTableColumns = async (payload) => {
  try {
    return await axios.patch(BASE_URL + 'api/v1/user/updateTableContent', payload, setHeaders());
  } catch (error) {
    return error;
  }
};
export const getSyncedData = async (type) => {
  try {
    return await axios.get(BASE_URL + `api/v3/company/get-synched-data?type=${type}`, setHeaders());
  } catch (error) {
    return error;
  }
};

export const syncManagersFromErp = async (companyId) => {
  try {
    return await axios.get(
      BASE_URL + `api/v3/company/get-jobId-manager-based-policy?companyId=${companyId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getReport = async () => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/document/v3/download-all-documents-report`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getCodeDetails = async (type, recordNumber) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v1/integration/get-code-details?codeListType=${type}&recordNumber=${recordNumber}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const updateDocumentType = async (payload) => {
  try {
    return await axios.put(
      BASE_URL + `api/v1/document/v3/changeDocumentTypeFromHilScreen`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getUpdatedApprovers = async (base, value) => {
  try {
    const newValue = encodeURIComponent(value);
    return await axios.get(
      BASE_URL + `api/v3/company/get-potential-policy-approver?base=${base}&value=${newValue}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const saveComments = async (id, payload) => {
  try {
    return await axios.patch(
      BASE_URL + `api/v1/document/v3/add-comment/${id}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const pullBackDocument = async (payload) => {
  try {
    return await axios.patch(
      BASE_URL + `api/v1/document/v3/reset-approval-flow`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const deleteBatchDoc = async (payload) => {
  try {
    const url = BASE_URL + 'api/v1/file/delete-split-extraction';
    const headers = setHeaders();
    return await axios.delete(url, { ...headers, data: payload });
  } catch (error) {
    return error;
  }
};

export const addComment = async (id, payload, files = []) => {
  try {
    const formData = new FormData();
    const commentArray = Array.isArray(payload) ? payload : [payload];
    formData.append('comment', JSON.stringify(commentArray));
    files.forEach((file) => formData.append('files', file));
    return await axios.patch(
      BASE_URL + `api/v1/document/v3/add-comment/${id}`,
      formData,
      setHeaders({ 'Content-Type': 'multipart/form-data' })
    );
  } catch (error) {
    return error;
  }
};

export const downloadFileAttachment = async (fileKey, companyId, docId) => {
  try {
    const url = `${BASE_URL}api/v1/file/download-s3-file/${docId}`;

    const params = {
      s3key: fileKey,
      companyId: companyId
    };
    return await axios.get(url, { params, ...setHeaders() });
  } catch (error) {
    return error;
  }
};


export const getJobDivision = async (jobId) => {
  try {
    const job = encodeURIComponent(jobId);
    return await axios.get(
      BASE_URL + `api/v1/integration/get-job-division?jobId=${job}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getAllExportBatches = async (companyId) => {
  try {
    return await axios.get(
      `${BASE_URL}api/v1/csv/get-all-export-batches?companyId=${companyId}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const downloadExportedCsvBatch = async (batchId, s3Key, companyId) => {
  try {
    return await axios.get(
      `${BASE_URL}api/v1/csv/download-exported-csv-batch/${batchId}?s3key=${encodeURIComponent(s3Key)}&companyId=${companyId}`,
      {
        ...setHeaders(),
      }
    );
  } catch (error) {
    return error;
  }
};

export const updateExportCsvStatus = async (params) => {
  try {
    return await axios.put(
      `${BASE_URL}api/v1/csv/update/export-csv-status`,
      params, 
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const regenerateCsvByBatch = async (params) => {
  try {
    return await axios.put(
      `${BASE_URL}api/v1/csv/regenerate-csv-by-batch`,
      params,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const checkAvailableEmail = async (companyId, email) => {
  try {
    return await axios.get(
      `${BASE_URL}api/v3/company/check-available-email/${companyId}?email=${encodeURIComponent(email)}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

