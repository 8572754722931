import React, { useRef } from 'react';
import { getPosition, getYearRange } from '../services/common';
import Konva from 'konva';
import { Colors } from '../config/default';
import { PanToolOutlined, CloseOutlined } from '@mui/icons-material';
import { documentTypes, documentStatusText } from './appConstants';
import { getJobDivision } from '../services/services';

const { INVOICE, ESTIMATE, DELIVERY_TICKET } = documentTypes;
const { ERROR, POPULATED, DUPLICATE, PENDING } = documentStatusText;
export const findMissingUtil = (first, second) => {
  const spreaded = [...first, ...second];
  return spreaded.filter((el) => {
    return !(first.includes(el) && second.includes(el));
  });
};
export const useDynamicRefs = () => {
  const refs = useRef({});
  const setRef = (key) => {
    if (!refs.current[key]) {
      refs.current[key] = React.createRef();
    }
    return refs.current[key];
  };
  const getRef = (key) => {
    return refs.current[key];
  };
  return [setRef, getRef];
};

const levenshtein = (a, b) => {
  if (a === b) return 0;
  if (!a?.length) return b?.length;
  if (!b?.length) return a?.length;

  let prevRow = Array(b?.length + 1)
    ?.fill(0)
    ?.map((_, i) => i);
  let currRow = Array(b?.length + 1);

  for (let i = 1; i <= a?.length; i++) {
    currRow[0] = i;
    for (let j = 1; j <= b?.length; j++) {
      currRow[j] = Math.min(
        prevRow[j] + 1, // Deletion
        currRow[j - 1] + 1, // Insertion
        prevRow[j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1) // Substitution
      );
    }
    [prevRow, currRow] = [currRow, prevRow];
  }
  return prevRow[b?.length];
};

export function findBestMatch(array, value, returnIndex) {
  if (!Array.isArray(array) || !value) {
    return returnIndex ? -1 : false;
  }
  const sanitize = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
  const sanitizedValue = sanitize(value);
  const threshold = Math.max(3, sanitizedValue?.length * 0.3); // Dynamic threshold
  let closestIndex = -1;
  let minDistance = Infinity;

  for (let i = 0; i < array?.length; i++) {
    const itemName = sanitize(array[i]?.name || array[i]);
    if (!itemName) continue;
    if (sanitizedValue === itemName) return returnIndex ? i : true; // Exact matchs
    const distance = levenshtein(sanitizedValue, itemName);
    if (distance <= threshold) {
      if (!returnIndex) return true;
      if (distance < minDistance) {
        minDistance = distance;
        closestIndex = i;
      }
    }
  }

  return returnIndex ? (minDistance <= threshold ? closestIndex : -1) : false;
}

export function checkObjectValues(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== true) {
      return true;
    }
  }
  return false;
}

export function findVendorIndex(vendorName, glInfo) {
  return findBestMatch(glInfo?.vendor, vendorName, true);
}

export function isvendorValueMatching(array, value) {
  return findBestMatch(array, value, false);
}

export function convertTimeToSeconds(time) {
  const match = time.match(/(\d+)h?:?(\d*)min?:?(\d*)sec?/);
  if (!match) {
    return 0;
  }
  const [, hours, minutes, seconds] = match.map((part) => (part === '' ? 0 : Number(part)));
  return hours * 60 + minutes;
}

export const vendorValueMatchForERP = (glInfo, vendors, erpType, userDetails) => {
  return (
    !isvendorValueMatching(glInfo?.vendor, vendors) &&
    (userDetails?.activeIntegration !== '' || erpType === 'RentalMan')
  );
};

export const getLineItemsCopy = (lineItemsCopy, lineItemsData) => {
  Object.keys(lineItemsData)?.forEach((key) => {
    if (key !== undefined && lineItemsData[key] !== undefined) {
      lineItemsData[key]?.forEach((val, index) => {
        if (val !== undefined && lineItemsCopy[index] !== undefined) {
          const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
          if (check !== -1 && lineItemsCopy[index][check]) {
            lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
          }
        }
      });
    }
  });
};

export const drawLinesByPosition = (
  buttonPosition,
  boxPosition,
  idx,
  dataArray,
  imageDimensions,
  canvasDimensions,
  userDetails,
  getRef,
  showLine,
  confidence,
  BoxReferenceId,
  ButtonReferenceId,
  scrollContainerRef,
  reviewScrollContainerRef
) => {
  var stage;
  let leftSideBarSpace = 450;
  let strokeColor;
  if (confidence >= 99) {
    strokeColor = 'green';
  } else if (confidence >= 85 && confidence < 99) {
    strokeColor = Colors.YELLOW;
  } else {
    strokeColor = Colors.RED;
  }
  stage = new Konva.Stage({
    container: 'myCanvas',
    height: canvasDimensions?.height,
    width: canvasDimensions?.width
  });

  let layer = new Konva.Layer();
  let curve = new Konva.Path({
    data: `
      M ${leftSideBarSpace},${buttonPosition?.top + buttonPosition?.height / 2}
      Q ${(leftSideBarSpace + boxPosition?.left) / 2},${
      buttonPosition?.top + buttonPosition?.height / 2
    }
      ${boxPosition?.left},${boxPosition?.top}
  `,
    stroke: userDetails?.company?.accuracyPointing ? strokeColor : Colors.RED,
    strokeWidth: 3,
    draggable: false
  });

  let rect = new Konva.Rect({
    x: boxPosition?.left,
    y: boxPosition?.top,
    width: dataArray[idx]?.width * imageDimensions?.width || 1,
    height: dataArray[idx]?.height * imageDimensions?.height,
    stroke: userDetails?.company?.accuracyPointing ? strokeColor : Colors.RED,
    strokeWidth: 3,
    draggable: false
  });
  showLine && layer?.add(curve);
  layer?.add(rect);
  stage?.add(layer);
  if (
    !dataArray ||
    dataArray[idx]?.value === '-' ||
    dataArray[idx]?.value === '' ||
    !buttonPosition ||
    !boxPosition
  ) {
    stage = null;
    BoxReferenceId = null;
    ButtonReferenceId = null;
    return;
  }

  function updatePosition() {
    let buttonPos = getPosition('button_' + ButtonReferenceId, getRef);
    let rectPos = getPosition('box_' + BoxReferenceId, getRef);
    let newButtonHeight = buttonPos?.height;
    let newButtonTop = buttonPos?.top;
    let newBoxLeft = rectPos?.left;
    let newBoxTop = rectPos?.top;

    curve.setData(`
            M ${leftSideBarSpace},${newButtonTop + newButtonHeight / 2}
            Q ${(leftSideBarSpace + newBoxLeft) / 2},${newButtonTop + newButtonHeight / 2}
            ${newBoxLeft},${newBoxTop}
        `);
    rect?.setAttr('x', newBoxLeft);
    rect?.setAttr('y', newBoxTop);
    showLine && layer?.add(curve);
    layer?.add(rect);
  }
  const rectPos = getPosition('box_' + BoxReferenceId, getRef);
  const scrollAmount = rectPos?.top - 20;
  scrollContainerRef?.current?.scrollBy({
    top: scrollAmount,
    behavior: 'smooth'
  });

  scrollContainerRef?.current?.addEventListener('scroll', updatePosition);
  reviewScrollContainerRef?.current?.addEventListener('scroll', updatePosition);
};

//line items coding array
export const getLineItemsCoddingArray = (glInfo) => {
  const LineItemsDataArray = [
    { name: 'userCostType', value: glInfo?.costType },
    { name: 'userPhases', value: glInfo?.phase },
    { name: 'userJobId', value: glInfo?.jobIds },
    { name: 'userCostId', value: glInfo?.costIds },
    { name: 'GlAccount', value: glInfo?.glAccount },
    { name: 'serviceCode', value: glInfo?.serviceCode },
    { name: 'equipmentNo', value: glInfo?.equipment },
    { name: 'Product_Code', value: glInfo?.productCode || [] },
    { name: 'glDivision', value: glInfo?.glDivision || [] }
  ];
  return LineItemsDataArray;
};

//replace names
export const getGlList = (name, glInfo) => {
  switch (name) {
    case 'userCostType':
      return glInfo?.costType;
    case 'userPhases':
      return glInfo?.phase;
    case 'userJobId':
      return glInfo?.jobIds;
    case 'userCostId':
      return glInfo?.costIds;
    case 'GlAccount':
      return glInfo?.glAccount;
    case 'serviceCode':
      return glInfo?.serviceCode;
    case 'equipmentNo':
      return glInfo?.equipment;
    case 'Product_Code':
      return glInfo?.productCode || [];
    case 'glDivision':
      return glInfo?.glDivision || [];
    case 'glPeriod':
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    case 'glYear':
      return getYearRange();
    default:
      return [];
  }
};
//for empty line items header
export const generateLineItemsHeader = (
  annotationType,
  isConstructionCompany,
  erpType,
  serviceAndEquipmentPermission,
  userDetails
) => {
  let codes = ['Description', 'Quantity'];
  if (annotationType !== DELIVERY_TICKET) {
    codes = [...codes, 'Unit_Price', 'Amount', 'GlAccount'];
  }
  if (annotationType === DELIVERY_TICKET) {
    codes = [...codes, 'UOM'];
    codes.unshift('Product_Code');
  }
  if (annotationType === INVOICE || annotationType === ESTIMATE) {
    codes.unshift('Product_Code');
  }
  if (isConstructionCompany && annotationType !== DELIVERY_TICKET) {
    codes = [...codes, 'userJobId', 'userPhases', 'userCostId', 'userCostType'];
  }
  if (erpType === 'Foundation' && annotationType === INVOICE && serviceAndEquipmentPermission) {
    codes = [...codes, 'equipmentNo', 'serviceCode'];
  }
  if (userDetails?.company?.glDivisionPermission) {
    codes = [...codes, 'glDivision'];
  }
  return codes;
};

// onhold ad reject butttons
export const getButtonArray = (currentStatus) => {
  let buttonArray = [];
  if (currentStatus && currentStatus !== 'on Hold') {
    buttonArray.push({
      name: 'On Hold',
      status: 'on Hold',
      icon: <PanToolOutlined sx={{ width: { xs: '13px', lg: '17px' }, color: Colors.YELLOW }} />,
      Color: Colors.YELLOW
    });
  }
  if (currentStatus && currentStatus !== 'Reject') {
    buttonArray.push({
      name: 'Reject',
      status: 'Reject',
      icon: <CloseOutlined sx={{ width: { xs: '13px', lg: '21px' }, color: Colors.RED }} />,
      Color: Colors.RED
    });
  }
  return buttonArray;
};

//get css classes for errors
export const getInputFieldClass = (
  value,
  isError,
  userDetails,
  isAmountMatched,
  lineItemsTotalMatch,
  annotationType,
  duplicateDocumentNoErrorObj,
  paymentTerms,
  InvoiceNumberKeys
) => {
  if (
    (value.name === 'discount' && !isError.status) ||
    ((value.name === 'total' || value.name === 'current_payment_due') &&
      userDetails?.user?.poMatchPermission &&
      isAmountMatched?.status === false &&
      (!isError?.status ||
        (value.name === 'payment_terms' &&
          userDetails?.user?.erpType === 'Foundation' &&
          paymentTerms?.trim() !== '' &&
          paymentTerms !== null) ||
        (!lineItemsTotalMatch && annotationType !== DELIVERY_TICKET)))
  ) {
    return 'input-field-style-dual-error';
  }
  if (
    !isError?.status ||
    value.name === 'discount' ||
    (!lineItemsTotalMatch && annotationType !== DELIVERY_TICKET) ||
    (InvoiceNumberKeys?.includes(value?.name) && duplicateDocumentNoErrorObj?.match)
  ) {
    return 'input-field-style-error';
  }
  return 'input-field-style';
};

//replace payment type
export const replaceType = (type) => {
  switch (type) {
    case 'CHK':
      return 'Check';
    case 'CRC':
      return 'Credit Card';
    case 'EFT':
      return 'EFT';
    default:
      return '-';
  }
};

//arrangeLineItems
export const reArrangeArrays = (arrayOfArrays) => {
  const referenceStructure = arrayOfArrays[0]?.map((item) => item?.name);
  return arrayOfArrays?.map((array) =>
    referenceStructure?.map(
      (name) => array?.find((item) => item?.name === name) || { name, value: '-' } // Use a default if not found
    )
  );
};

export const regenerateContentFormat = (updatedData, actualValues, vendors, paymentTerms) => {
  actualValues?.forEach((element, idx) => {
    updatedData[idx].value = element?.replace(/[$]/g, '');
  });

  const vendorField =
    updatedData.find((data) => data.name === 'vendor') ||
    updatedData.find((data) => data.name === 'from_contractor');
  if (vendorField) vendorField.value = vendors;

  const paymentTermsObject = updatedData.find((data) => data.name === 'payment_terms');
  if (paymentTermsObject) paymentTermsObject.value = paymentTerms ?? '';

  return updatedData;
};
export const regeneratePaymentFormat = (
  updatedPayment,
  actualPaymentValues,
  paymentType,
  prepaid
) => {
  actualPaymentValues?.forEach((element, idx) => {
    updatedPayment[idx].value = element?.replace(/[$]/g, '');
  });

  const paymentStatusObject = updatedPayment.find((data) => data.name === 'payment_status');
  const paymentTypeObject = updatedPayment.find((data) => data.name === 'payment_type');

  if (paymentTypeObject) paymentTypeObject.value = paymentType;
  if (paymentType !== '' && paymentStatusObject) paymentStatusObject.value = prepaid ? 'P' : 'R';

  return updatedPayment;
};

export const regenerateCodeFormat = (updatedCodes, actualaccountCodeValues) => {
  actualaccountCodeValues?.forEach((element, idx) => {
    updatedCodes[idx].value = (element ?? '').replace(/[$]/g, '');
  });
  return updatedCodes;
};

export const getDivision = async (
  jobNum,
  setFields,
  setActualaccountCodeValues,
  accountCodeArray
) => {
  const result = await getJobDivision(jobNum);
  if (result?.status === 200 && result?.data?.payload?.data) {
    setFields((prev) => ({
      ...prev,
      glDivision: result?.data?.payload?.data
    }));
    setActualaccountCodeValues((prevValues) => {
      const updatedValues = [...prevValues];
      const updateValue = (name, value) => {
        const index = accountCodeArray?.findIndex((item) => item.name === name);
        if (index !== -1) updatedValues[index] = value;
      };
      updateValue('glDivision', result?.data?.payload?.data);
      return updatedValues;
    });
  }
};

export const disablePullBack = (params) => {
  return (
    params.row.documentType === ESTIMATE ||
    params.row.documentType === DELIVERY_TICKET ||
    params?.row?.documentStatus === ERROR ||
    params?.row?.documentStatus === DUPLICATE ||
    params?.row?.documentStatus === PENDING ||
    params?.row?.documentStatus === POPULATED
  );
};
