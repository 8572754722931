import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router';

import { Button, Card, Grid, Typography, CircularProgress } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Colors } from '../config/default';
import InvoiceList from '../components/invoiceLists';
import { getDocumentDetails, getErrorDocument } from '../services/services';
import PngImage from '../components/pngImage';
import AccountDetailList from '../components/accountDetailList';
import Layout from '../components/layout';
import { getParentTabs, AdminParentTabs, clientParentTabs, formatNumber } from '../services/common';
import PaginationButtons from '../components/paginationButtons';
import {
  userRoles,
  documentTypes,
  documentStatusText,
  documentDetails
} from '../constants/appConstants';
import ActivityDrawer from '../components/activityDrawer';
import ErrorDetailsList from '../components/ErrorDetailsList';
import theme from '../theme';
import LineitemsDrawer from '../components/lineitemsDrawer';
import AttachmentDrawer from '../components/attachmentDrawer';
import DocumentComments from '../components/documentComments';
import { autoCap } from '../services/validationService';
import { replaceType } from '../constants/utils';

export default function VerifiedDetails() {
  const { ADMIN, OWNER, VENDOR } = userRoles;
  const { BACK, DOCUMENT_DETAILS, ACCOUNT_DETAILS, PAYMENT_DETAILS } = documentDetails;
  const { INVOICE, ESTIMATE, DELIVERY_TICKET, RECEIPT, PURCHASE_ORDER } = documentTypes;
  const { FAILURE, ERROR } = documentStatusText;
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [lineitems, setLineitems] = useState([]);
  const [documentReference, setDocumentReference] = useState('');
  const [originalQuote, setOriginalQuote] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openComment, setOpenComment] = useState(false);
  const [documentStatus, setDocumentStatus] = useState('');
  const totalPages = Math.ceil(originalQuote.length / 1);
  const indexOfLastItem = currentPage * 1;
  const indexOfFirstItem = indexOfLastItem - 1;
  const currentItems = originalQuote?.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const parentTabs = getParentTabs(userRole);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  let content = !isEmpty(url?.standardContent) ? url?.standardContent : url?.content;
  let paymentContent = url?.paymentContent || [];
  const { id } = useParams();
  const selectedData = async () => {
    setLoading(true);
    let result;
    if (userDetails?.role === ADMIN) {
      result = await getErrorDocument(id);
    } else {
      result = await getDocumentDetails(id, callSource);
    }
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else if (result?.response?.status === 403) {
      navigate('/unauthorized');
    }
    const res = result?.data?.payload?.data?.document;
    setLineitems(res?.lineItems);
    setDocumentType(res?.documentType);
    setDocumentReference(res?.documentReference);
    setDocumentStatus(res?.documentStatus);
    setUrl(res);
    setImages(
      result?.data?.payload?.data?.document?.convertedS3Keys ||
        result?.data?.payload?.data?.convertedS3Keys
    );
    setData(result?.data?.payload?.data);
    setAttachments(result?.data?.payload?.data?.attachments || []);
    setLoading(false);
  };

  useEffect(() => {
    selectedData();
  }, []);
  useEffect(() => {
    if (documentReference && !isEmpty(documentReference)) {
      showOriginalQuote(documentReference[0]?.id || documentReference);
    }
  }, [documentReference]);

  const showOriginalQuote = async (id) => {
    const res = await getDocumentDetails(id, 'app');
    if (res?.status === 200) {
      setOriginalQuote(res?.data?.payload?.data?.document?.convertedS3Keys);
    }
  };
  const handleBackward = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleForward = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };
  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundColor: Colors.BG_BLACK
        }}>
        <Layout
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={
            userRole === ADMIN
              ? AdminParentTabs
              : userRole === VENDOR
              ? clientParentTabs
              : parentTabs
          }
          path="Details"
          show={userDetails?.role === OWNER ? 'showUser' : ''}
        />
        <Grid
          xs={12}
          sx={{
            mt: { xs: '2em', md: '0' },
            display: 'flex',
            justifyContent: 'space-between',
            pl: '3em',
            pr: '3em'
          }}>
          <Button
            variant="outlined"
            sx={{
              border: `1px solid ${Colors.DARKBLUE}`,
              borderRadius: '10px',
              height: '2rem',
              width: '5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '.8em',
              textTransform: 'none',
              color: Colors.WHITE,
              '&:hover': {
                background: 'none',
                border: `1px solid ${Colors.DARKBLUE}`
              }
            }}
            onClick={() =>
              userDetails?.role === ADMIN
                ? navigate('/admin')
                : userDetails?.role === VENDOR
                ? navigate('/vendor')
                : navigate('/home')
            }>
            <ArrowBack sx={{ color: Colors.DARKBLUE, fontSize: '1.4em', mr: '.3em' }} />
            {BACK}
          </Button>
          <div style={{ display: 'flex' }}>
            {userDetails?.role !== ADMIN &&
              documentStatus !== FAILURE &&
              documentStatus !== ERROR && (
                <>
                  <ActivityDrawer id={id} documentType={documentType} />
                </>
              )}
          </div>
        </Grid>
        {loading && (
          <Grid
            container
            sx={{
              height: '80vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress size={150} color="primary" />
          </Grid>
        )}
        {!loading && (
          <Grid
            container
            sx={{
              height: '80vh',
              alignItems: 'flex-start',
              ml: { xs: '0em', sm: '3em' },
              mr: { xs: '0em', sm: '3em' },
              justifyContent: { xs: 'center', sm: 'normal' },
              gap: { xs: '1em', xl: '2em' },
              backgroundColor: Colors.BG_BLACK,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0em',
                height: '0em',
                borderRadius: '20px'
              }
            }}>
            <Card
              sx={{
                height: '67vh',
                mt: { xs: '2em', md: '0' },
                width: { xs: '70%', sm: '22%', xl: '18%' },
                backgroundColor: Colors.NAV_BLACK,
                boxShadow: 'none',
                borderRadius: '1em',
                padding: '1.5em'
              }}>
              <div>
                <div>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { xs: '0.80em', lg: '0.80em', xl: '1.25em' },
                      fontWeight: '600',
                      mb: '1.5em'
                    }}>
                    {DOCUMENT_DETAILS}
                  </Typography>
                </div>

                <Grid
                  item
                  sx={{
                    marginTop: '0.5em',
                    height: '59vh',
                    paddingRight: '1.25em',
                    width: '-webkit-fill-available',
                    overflowY: 'auto'
                  }}>
                  {userDetails?.role === ADMIN ||
                  documentStatus === FAILURE ||
                  documentStatus === ERROR ? (
                    <ErrorDetailsList data={data} />
                  ) : (
                    <InvoiceList apiData={content} document={url} />
                  )}
                  {userDetails?.role !== ADMIN &&
                    documentStatus !== FAILURE &&
                    documentStatus !== ERROR && (
                      <div>
                        {userRole === 'Vendor' ||
                        documentType === ESTIMATE ||
                        documentType === DELIVERY_TICKET ? (
                          ''
                        ) : (
                          <>
                            <div>
                              <Typography
                                sx={{
                                  color: Colors.TOURQUISE,
                                  fontSize: { xs: '0.80em', lg: '0.80em', xl: '1.25em' },
                                  fontWeight: '600',
                                  mb: '1.5em'
                                }}>
                                {ACCOUNT_DETAILS}
                              </Typography>
                            </div>
                            <AccountDetailList dataArray={url?.codeContent} />
                          </>
                        )}
                      </div>
                    )}
                  {userDetails?.role !== ADMIN &&
                    !isEmpty(paymentContent) &&
                    (documentType === INVOICE || documentType === RECEIPT) && (
                      <>
                        <div>
                          <Typography
                            sx={{
                              color: Colors.TOURQUISE,
                              fontSize: { xs: '0.80em', lg: '0.80em', xl: '1.25em' },
                              fontWeight: '600',
                              mb: '1.5em'
                            }}>
                            {PAYMENT_DETAILS}
                          </Typography>
                        </div>
                        {paymentContent &&
                          paymentContent?.map(
                            (item) =>
                              (item?.name !== 'check_no' ||
                                paymentContent?.find((i) => i?.name === 'payment_type')?.value ===
                                  'CHK') && (
                                <Grid
                                  container
                                  xs={12}
                                  sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    color: Colors.TEXTGREY,
                                    mb: '1.5em'
                                  }}>
                                  <Grid xs={4}>
                                    <Typography sx={{ fontSize: '0.80em' }}>
                                      {autoCap(item?.name)}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={7} sx={{ textAlign: 'right' }}>
                                    <Typography sx={{ fontSize: '0.80em' }}>
                                      {item?.name === 'payment_amount'
                                        ? formatNumber(item?.value)
                                        : item?.name === 'payment_status' && item?.value === 'P'
                                        ? 'Paid'
                                        : item?.name === 'payment_status' && item?.value === 'R'
                                        ? 'Un Paid'
                                        : item?.name === 'payment_type'
                                        ? replaceType(item?.value)
                                        : item?.value || '-'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                          )}
                      </>
                    )}
                </Grid>
              </div>
              {lineitems?.length > 0 && <LineitemsDrawer lineitems={lineitems} />}
            </Card>
            <Grid>
              {images?.length > 0 && <PngImage imageSrc={images[index]} imageAlt={index} />}
              {images?.length > 1 && (
                <PaginationButtons
                  disableFirstButton={index === 0}
                  disableSecondButton={index === images.length - 1}
                  slideLeft={slideLeft}
                  slideRight={slideRight}
                  index={index}
                  images={images}
                />
              )}
            </Grid>
            {documentType === PURCHASE_ORDER && images?.length > 0 && (
              <Grid>
                {currentItems?.map((s3Key, index) => (
                  <PngImage imageSrc={s3Key} imageAlt={index} />
                ))}
                {totalPages > 1 && (
                  <PaginationButtons
                    disableFirstButton={currentPage === 1}
                    disableSecondButton={currentPage === totalPages}
                    slideLeft={handleBackward}
                    slideRight={handleForward}
                    index={currentPage - 1}
                    totalPages={totalPages}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
}
