import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, Grid, CircularProgress, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { AttachFile, Close, MailOutline, DeleteOutline } from '@mui/icons-material/';
import { Colors } from '../config/default';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../richTextEditor.css';
import { sendEmailToVendor } from '../services/services';
import { useParams } from 'react-router-dom';
import { useToast } from '../toast/toastContext';
import { HtmlTooltip } from '../services/common';
import { isEmailValid } from '../services/validationService';
import { useDropzone } from 'react-dropzone';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55vw',
  bgcolor: Colors.NAV_BLACK,
  boxShadow: 24,
  p: 3,
  borderRadius: '10px'
};

export default function EmailVendor({ documentName, selectedData, id }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [richText, setRichText] = useState(
    `<br><br><p>Please reply to "${userDetails?.user?.email}" for correspondence on this email!</p>`
  );
  const [email, setEmail] = useState('');
  const [ccEmails, setCcEmails] = useState('');
  const [subject, setSubject] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const { showToast } = useToast();

  useEffect(() => {
    setDocumentId(id);
  }, [id]);

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleDeleteAttachment = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true
  });

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleCcChange = (e) => setCcEmails(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleChange = (content, delta, source, editor) => {
    setRichText(editor.getHTML());
  };
  const isButtonDisabled =
    !email.trim() ||
    !isEmailValid(email) ||
    !subject.trim() ||
    !richText.replace(/<\/?[^>]+(>|$)/g, '').trim();

  const apiData = {
    emailContent: richText
      .replace('class="ql-align-center"', 'style=text-align:center;')
      .replace(/class=\"ql-align-center\"/g, 'style=text-align:center;')
      .replace('class="ql-align-right"', 'style=text-align:right;')
      .replace(/class="ql-align-right"/g, 'style=text-align:right;'),
    subject,
    sendTo: email,
    documentId: documentId,
    cc: ccEmails
      .split(',')
      .map((cc) => cc.trim())
      .filter((cc) => isEmailValid(cc))
  };

  const handleClick = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append(
      'emailContent',
      JSON.stringify(
        richText
        .replace('class="ql-align-center"', 'style=text-align:center;')
        .replace(/class=\"ql-align-center\"/g, 'style=text-align:center;')
        .replace('class="ql-align-right"', 'style=text-align:right;')
        .replace(/class="ql-align-right"/g, 'style=text-align:right;'),
      )
    );
    formData.append('subject', JSON.stringify(subject));
    formData.append('sendTo', JSON.stringify(email));
    formData.append('documentId', JSON.stringify(documentId));
    files.forEach((file) => formData.append('files', file));

    const ccArray = ccEmails
      .split(',')
      .map((cc) => cc.trim())
      .filter((cc) => isEmailValid(cc));

    formData.append('cc', JSON.stringify(ccArray));

    const res = await sendEmailToVendor(formData);

    if (res?.status === 200) {
      showToast(res?.data?.metadata?.message, 'success');
      setLoading(false);
      setOpen(false);
      setRichText(
        `<br><br><p>Please reply to "${userDetails?.user?.email}" for correspondence on this email!</p>`
      );
      setEmail('');
      setCcEmails('');
      setSubject('');
      setFiles([]);
      selectedData();
    } else {
      setLoading(false);
      showToast(res?.data?.metadata?.message || res?.response?.data?.metadata?.message, 'error');
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }],
      ['align', { align: 'center' }, { align: 'right' }],
      ['link']
    ]
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          fontSize: '.8em',
          textTransform: 'none',
          borderRadius: '10px',
          width: '100%',
          padding: { xs: 0, sm: 'auto' },
          height: '2.3rem',
          color: Colors.WHITE,
          marginRight: '10px',
          '&:disabled': {
            color: 'gray',
            cursor: 'not-allowed'
          },
          '&:hover': {
            background: 'none'
          }
        }}
        >
        Email
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', right: 16, top: 16, color: Colors.WHITE }}>
            <Close />
          </IconButton>
          <Grid item xs={12} sx={{ mt: '2em' }}>
            <input
              onChange={handleEmailChange}
              value={email}
              type="text"
              style={{
                width: '96%',
                backgroundColor: Colors.Canvas_BG,
                padding: '16px 2%',
                color: Colors.WHITE,
                borderRadius: '10px',
                border: 'none'
              }}
              placeholder="To"
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '1em' }}>
            <input
              onChange={handleCcChange}
              value={ccEmails}
              type="text"
              style={{
                width: '96%',
                backgroundColor: Colors.Canvas_BG,
                padding: '16px 2%',
                color: Colors.WHITE,
                borderRadius: '10px',
                border: 'none'
              }}
              placeholder="CC (comma separated)"
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '1em' }}>
            <input
              onChange={handleSubjectChange}
              value={subject}
              type="text"
              style={{
                width: '96%',
                backgroundColor: Colors.Canvas_BG,
                padding: '16px 2%',
                color: Colors.WHITE,
                borderRadius: '10px',
                border: 'none'
              }}
              placeholder="Subject"
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '1em' }}>
            <ReactQuill
              style={{
                width: '100%',
                height: '28vh',
                color: Colors.WHITE,
                border: 'none',
                outline: 'none'
              }}
              className="richText"
              value={richText}
              onChange={handleChange}
              modules={modules}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: '3em' }}>
            <div
              {...getRootProps()}
              style={{
                border: '2px dashed grey', 
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '10px',
                color: 'white', 
                backgroundColor: 'rgba(255, 255, 255, 0.1)', 
              }}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography variant="body2" sx={{ color: 'white' }}>
                  Drop the files here...
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ color: 'white' }}>
                  Drag & drop files, or click to select
                </Typography>
              )}
            </div>
          </Grid>

          {/* Display Selected Files */}
          {files.length > 0 && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {files.map((file, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: Colors.Canvas_BG,
                      padding: '5px',
                      borderRadius: '10px'
                    }}>
                    <AttachFile sx={{ color: Colors.TOURQUISE, mr: 1 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        flex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: 'white'
                      }}>
                      {file.name}
                    </Typography>
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => handleDeleteAttachment(file.name)}>
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}

          <Grid container item xs={12} sx={{ justifyContent: 'center', mt: '3em' }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', color: Colors.WHITE }}>
                <AttachFile sx={{ fontSize: '1.1rem', color: Colors.TOURQUISE }} />
                <HtmlTooltip
                  title={
                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                      {documentName}
                    </Typography>
                  }>
                  <Typography>
                    {documentName?.length > 15
                      ? `${documentName?.substring(0, 10)}...${documentName?.substring(
                          documentName?.lastIndexOf('.') - 1
                        )}`
                      : documentName}{' '}
                  </Typography>
                </HtmlTooltip>
              </Box>
            </Grid>
            <Button
              onClick={handleClick}
              disabled={isButtonDisabled || loading}
              variant="contained"
              sx={{
                color: Colors.WHITE,
                width: '8rem',
                height: '2.2rem',
                fontSize: '16px',
                textTransform: 'none',
                backgroundColor: '#24658D',
                borderRadius: '10px',
                '&:hover': {
                  background: '#24658D'
                }
              }}>
              {loading ? (
                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={18} sx={{ color: Colors.DARKBLUE }} />
                </Grid>
              ) : (
                'Send'
              )}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
