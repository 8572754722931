import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';

import theme from '../../theme';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Grid, Chip } from '@mui/material';

import { getSelectedDocs, createCsv } from '../../services/services';
import {
  clientParentTabs,
  vendorChildTabs,
  dashboardTabs,
  replaceStatus,
  defaultFilter
} from '../../services/common';
import { get_queue, get_tabs, get_fitered_obj } from '../../redux/actions/action';
import Layout from '../../components/layout';
import HeaderSection from '../../components/headerSection';
import DataTable from '../../components/dataTable';
import { useToast } from '../../toast/toastContext';
import VendorDashboard from '../pages/vendorDashboard';

export default function VendorHomePage() {
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const parentActiveTab = globalActiveTab?.activeTab;
  const [activeTab, setActiveTab] = useState(parentActiveTab || clientParentTabs[0]?.name);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.documentChildActiveTab || vendorChildTabs[0]?.name
  );
  const savedFilters = useSelector((state) => state?.filters?.filters);
  const [dashboardChildTabs, setDashboardChildTabs] = useState(dashboardTabs[0]?.name);
  const [filteredData, setFilteredData] = useState([]);
  const [recordExist, setRecordExist] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [createCsvLoading, setCreateCsvLoading] = useState(false);
  const searchedData = sessionStorage.getItem('searchedKey');
  const [searchProducts, setSearchProducts] = useState(searchedData || '');
  const [dataAccordingTabs, setDataAccordingTabs] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedRowsHasPO, setSelectedRowsHasPO] = useState(true);
  const pendingQueues = useSelector((state) => state?.queue?.queue[0]?.documents);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const { showToast } = useToast();
  const formatedFilterObject = isEmpty(savedFilters)
    ? {
        search: false,
        filter: false,
        sort: '',
        createdAt: { to: '', from: '' },
        amount: { min: '', max: '' },
        sendByEmail: false,
        status: []
      }
    : savedFilters;
  const [getDocument, setGetDocument] = useState(formatedFilterObject);
  const getDocumentRef = useRef(getDocument);
  //filter states
  const [selectedOptions, setSelectedOptions] = useState(savedFilters?.status || []);
  const [byEmail, setByEmail] = useState(savedFilters?.sendByEmail || false);
  const [amount, setAmount] = useState(
    savedFilters?.amount || {
      min: '',
      max: ''
    }
  );
  const [recieivedAtDate, setRecieivedAtDate] = useState(savedFilters?.createdAt?.to || '');
  const [recieivedFromDate, setRecieivedFromDate] = useState(savedFilters?.createdAt?.from || '');
  const [permission, setPermission] = useState(savedFilters?.sort || '');

  const getAppliedFilters = () => {
    const chips = [];
    if (selectedOptions?.length) {
      const replacedStatuses = selectedOptions?.map(replaceStatus);
      chips.push({ label: `Status: ${replacedStatuses?.join(', ')}`, key: 'status' });
    }
    if (amount?.min && amount?.max)
      chips.push({ label: `Amount: ${amount?.min} - ${amount?.max}`, key: 'amount' });
    if (recieivedAtDate && recieivedFromDate)
      chips.push({
        label: `Received At: ${recieivedAtDate} - ${recieivedFromDate}`,
        key: 'createdAt'
      });
    if (permission)
      chips.push({
        label: `Sort: ${permission === 'Descending' ? 'New to Old' : 'Old to New'}`,
        key: 'sort'
      });
    if (byEmail) chips.push({ label: 'Sent by Email', key: 'sendByEmail' });
    return chips;
  };
  const updateFilters = () => {
    const createdAt =
      recieivedAtDate && recieivedFromDate
        ? { to: recieivedAtDate, from: recieivedFromDate }
        : { to: '', from: '' };
    const amountObj =
      amount?.min && amount?.max ? { min: amount?.min, max: amount?.max } : { min: '', max: '' };
    const filter = {
      search: searchedData && searchedData !== '' && searchedData !== 'undefined' ? true : false,
      filter:
        selectedOptions.length > 0 ||
        byEmail ||
        amount?.min !== '' ||
        amount?.max !== '' ||
        (recieivedAtDate && recieivedFromDate) ||
        permission !== ''
          ? true
          : false,
      sort: permission,
      createdAt,
      amount: amountObj,
      sendByEmail: byEmail,
      status: selectedOptions
    };

    dispatch(get_fitered_obj(filter));
    setGetDocument(filter);
  };
  const handleChipDelete = (key) => {
    switch (key) {
      case 'status':
        setSelectedOptions([]);
        break;
      case 'amount':
        setAmount({ min: '', max: '' });
        break;
      case 'createdAt':
        setRecieivedAtDate('');
        setRecieivedFromDate('');
        break;
      case 'sort':
        setPermission('');
        break;
      case 'sendByEmail':
        setByEmail(false);
        break;
      default:
        break;
    }
    updateFilters();
  };
  useEffect(() => {
    updateFilters();
  }, [
    searchedData,
    selectedOptions,
    byEmail,
    amount,
    recieivedAtDate,
    recieivedFromDate,
    permission
  ]);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  const Tabs = {
    activeTab: activeTab,
    documentChildActiveTab: childActiveTab
  };
  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab]);
  useEffect(() => {
    setActiveTab(parentActiveTab || clientParentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.documentChildActiveTab || vendorChildTabs[0]?.name);
  }, [parentActiveTab, globalActiveTab?.documentChildActiveTab]);
  const allQueuesData = async (key) => {
    setLoading(true);
    const result = await getSelectedDocs(key || searchProducts, getDocumentRef.current, callSource);
    dispatch(get_queue(result?.data?.payload?.data));
    if (getDocumentRef?.current?.search) {
      if (result?.status === 200) {
        if (!isEmpty(result?.data?.payload?.data)) {
          setFilteredData(result?.data?.payload?.data?.documents);
        } else {
          setRecordExist(true);
        }
        sessionStorage.setItem('searchedKey', key || searchProducts);
        setSearchProducts(key || searchProducts);
      } else {
        showToast(
          result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
          'error'
        );
      }
    }
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    setCheckedIds([]);
    setLoading(false);
  };

  const intervalCall = async () => {
    const result = await getSelectedDocs(searchProducts, getDocumentRef.current, callSource);
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
  };

  useEffect(() => {
    allQueuesData();
  }, []);
  useEffect(() => {
    if (getDocumentRef.current && !isEqual(getDocumentRef.current, getDocument)) {
      getDocumentRef.current = getDocument;
      allQueuesData();
    }
  }, [getDocument]);
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const interval = setInterval(() => {
        intervalCall();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    setCreateCsvLoading(true);
    const params = {
      documentIds: checkedIds
    };
    const response = await createCsv(params);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      setCheckedIds('');
      allQueuesData();
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
    setCreateCsvLoading(false);
  };
  const handleClear = () => {
    setSearchProducts('');
    setFilteredData([]);
    setSelectedOptions([]);
    setByEmail(false);
    setAmount({
      min: '',
      max: ''
    });
    setRecieivedAtDate('');
    setRecieivedFromDate('');
    setPermission('');
    dispatch(get_fitered_obj(defaultFilter));
    setGetDocument(defaultFilter);
    setRecordExist(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={clientParentTabs}
          path="vendorHome"
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          getAppliedFilters={getAppliedFilters}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={parentActiveTab === 'Dashboard' ? dashboardTabs : vendorChildTabs}
              setActiveTab={
                parentActiveTab === 'Dashboard' ? setDashboardChildTabs : setChildActiveTab
              }
              activeTab={parentActiveTab === 'Dashboard' ? dashboardChildTabs : childActiveTab}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setLoading={setLoading}
              loading={loading}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              intervalCall={intervalCall}
              tab={parentActiveTab === 'Dashboard' ? 'Dashboard' : 'documents'}
              createCsvLoading={createCsvLoading}
              currentUser="Vendor"
              handleCreateCSV={handleCreateCSV}
              searchProducts={searchProducts}
              setSearchProducts={setSearchProducts}
              dataAccordingTabs={dataAccordingTabs}
              selectedRowsHasPO={selectedRowsHasPO}
              setGetDocument={setGetDocument}
              allQueuesData={allQueuesData}
              amount={amount}
              setAmount={setAmount}
              recieivedAtDate={recieivedAtDate}
              setRecieivedAtDate={setRecieivedAtDate}
              byEmail={byEmail}
              setByEmail={setByEmail}
              recieivedFromDate={recieivedFromDate}
              setRecieivedFromDate={setRecieivedFromDate}
              permission={permission}
              setPermission={setPermission}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getAppliedFilters={getAppliedFilters}
              handleFilterandSearchClear={handleClear}
            />
            {loading ? (
              <Grid
                container
                sx={{
                  height: '75vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={150} color="primary" />
              </Grid>
            ) : parentActiveTab === 'Dashboard' ? (
              <VendorDashboard
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            ) : (
              <>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: '.3rem', height: '5vh', paddingLeft: '1.8rem' }}>
                  {getAppliedFilters()?.map((chip) => (
                    <Grid item key={chip?.key}>
                      <Chip
                        label={chip?.label}
                        onDelete={() => handleChipDelete(chip?.key)}
                        color="primary"
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>
                <DataTable
                  resdata={pendingQueues}
                  setCheckedIds={setCheckedIds}
                  checkedIds={checkedIds}
                  filterData={filteredData}
                  toShow="ApiSearch"
                  recordExist={recordExist}
                  currentUser="Vendor"
                  isIntegrated={activeIntegration}
                  childActiveTab={childActiveTab}
                  setDataAccordingTabs={setDataAccordingTabs}
                  setSelectedRowsHasPO={setSelectedRowsHasPO}
                  searchProducts={searchProducts}
                  getDocument={getDocument}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
