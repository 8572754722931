import * as React from 'react';
import { Drawer, IconButton, Button, Grid, Typography, Tabs, Tab, Box } from '@mui/material';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Colors } from '../config/default';
import ActivityCard from './activityCard';
import { HtmlTooltip } from '../services/common';
import { accountDetailsComponent, documentStatusText, userRoles } from '../constants/appConstants';
import { getDocumentDetails, getErrorDocument } from '../services/services';
import { useState, useEffect } from 'react';
import ActivityAttachment from './activityAttachment';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector } from 'react-redux';
import ApproversCard from './approversCard';

export default function ActivityDrawer({ type, id, disable, documentType, documentName }) {
  const { ADMIN } = userRoles;

  const { SHOW_ACTIVITY } = accountDetailsComponent;
  const { DUPLICATE, PENDING } = documentStatusText;
  const [state, setState] = React.useState({ right: false });
  const [tabIndex, setTabIndex] = React.useState(0);
  const [attachments, setAttachments] = useState([]);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);

  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const list = (anchor) => (
    <Grid
      sx={{
        alignItems: 'center',
        height: '100vh',
        backgroundColor: Colors.BG_BLACK,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '.3em',
          height: '0em',
          borderRadius: '20px'
        }
      }}
      onClick={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: Colors.NAV_BLACK,
          px: 2
        }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="primary">
          <Tab label="Activity" />
          <Tab label="Attachments" />
          <Tab label="Approvers" />
        </Tabs>

        <IconButton onClick={toggleDrawer(anchor, false)} sx={{ color: Colors.WHITE }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <Box hidden={tabIndex !== 0}>
          <ActivityCard id={id} documentType={documentType} documentName={documentName} />
        </Box>
        <Box hidden={tabIndex !== 1}>
          <ActivityAttachment
            id={id}
            userRole={userRole}
          />
        </Box>
        <Box hidden={tabIndex !== 2}>
          <ApproversCard
            id={id}
          />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {type === 'document' || type === 'canvas' ? (
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {SHOW_ACTIVITY}
                </Typography>
              }>
              <span>
                <IconButton
                  sx={{
                    border: type === 'canvas' ? '.5px solid #2E97A7' : 'none',
                    mr: type === 'canvas' ? '0.5rem' : 'auto'
                  }}
                  variant={type === 'canvas' ? 'outlined' : 'standard'}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleDrawer(anchor, true)(event);
                  }}
                  aria-label="activity"
                  disabled={disable === PENDING || disable === DUPLICATE}>
                  <ViewTimelineOutlinedIcon
                    sx={{
                      fontSize: '0.8em',
                      color:
                        disable === PENDING || disable === DUPLICATE || type === 'canvas'
                          ? Colors.WHITE
                          : Colors.DARKBLUE
                    }}
                  />
                </IconButton>
              </span>
            </HtmlTooltip>
          ) : (
            <Button
              variant="Outlined"
              sx={{
                border: '1px solid ',
                borderColor: Colors.DARKBLUE,
                height: '2rem',
                textTransform: 'none',
                color: Colors.WHITE,
                borderRadius: '10px',
                '&:hover': { backgroundColor: 'transparent' }
              }}
              onClick={toggleDrawer(anchor, true)}>
              <ArrowBackIosNewIcon sx={{ color: Colors.DARKBLUE, fontSize: '1em', mr: '.3em' }} />
              <Typography sx={{ fontSize: '1em' }}>{SHOW_ACTIVITY}</Typography>
            </Button>
          )}

          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

