import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { rolesConfig } from '../services/authenticationService';

export default function Protected({ children, role }) {
  const authUser = sessionStorage.getItem('token');
  const location = useLocation();
  const currentPath = location.pathname;

  if (!authUser) {
    return <Navigate to="/" state={{ from: history.location }} />;
  }
  const allowedRoutes = rolesConfig[role] || [];
  const pathMatches = allowedRoutes?.some((route) => {
    const regexPattern = route?.replace(/\/:id$/, '/[a-zA-Z0-9_-]+');
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(currentPath);
  });

  if (!pathMatches) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }

  return children;
}
