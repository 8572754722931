import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { autoCap } from '../services/validationService';
import { Colors } from '../config/default';
import { activity } from '../constants/appConstants';
import { isEmpty } from 'lodash';
import { IconButton } from '@mui/material';
import { downloadFileAttachment } from '../services/services';
import { Check, InfoOutlined, ArrowDownwardOutlined } from '@mui/icons-material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const ActivityDetailCard = ({
  timelineLeftContent,
  timelineRightContent,
  documentType,
  activityIndex,
  id
}) => {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const companyId = userDetails?.company?._id;

  const cleanEmailText = (html) => {
    if (!html) return '';
    let cleanedHtml = html.replace(/<br\s*\/?>/g, '');

    return cleanedHtml;
  };

  function transformDataForQBO(data) {
    if (erpType === 'QuickBooksOnline') {
      data?.lineItems?.forEach((item) => {
        if (item?.jobId !== undefined) {
          item.projectId = item?.jobId;
          delete item?.jobId;
        }
        if (item?.costType !== undefined) {
          item.class = item?.costType;
          delete item?.costType;
        }
      });
      if (data?.standardContent?.jobId !== undefined) {
        data.standardContent.projectId = data?.standardContent?.jobId;
        delete data?.standardContent?.jobId;
      }
      if (data?.standardContent?.costType !== undefined) {
        data.standardContent.class = data?.standardContent?.costType;
        delete data?.standardContent?.costType;
      }
    }
    return data;
  }

  const rightContent = transformDataForQBO(timelineRightContent);

  const {
    UPDATED_BY,
    UPLOADED_BY,
    CONTENT,
    LINEITEM,
    DELIVERY_TICKET,
    CREATED_BY,
    COMMENTED_BY,
    ATTACHMENT_BY,
    ATTACHMENT_REMOVED_BY,
    COMMENT,
    EMAIL_SENT
  } = activity;

  const dateObject = new Date(timelineLeftContent?.time);
  const formattedDate = dateObject?.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  const downloadAttachment = async (fileKey, companyId) => {
    const response = await downloadFileAttachment(fileKey, companyId, id);
    if (response?.status === 200) {
      if (response?.data?.payload?.data?.length === 1) {
        response?.data?.payload?.data?.forEach((file) => {
          DownloadDocument(file?.s3_file_name, file?.signedUrl);
        });
      }
    }
  };

  const DownloadDocument = (s3FileName, signedUrl) => {
    const link = document.createElement('a');
    link.href = signedUrl;
    link.setAttribute('download', s3FileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [currentAttachmentIndices, setCurrentAttachmentIndices] = useState(
    Array(rightContent?.attachment?.length).fill(0)
  );
  const [enlargedStates, setEnlargedStates] = useState(
    Array(rightContent?.attachment?.length).fill(false)
  );

  const handleNextAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      newIndices[i] = (newIndices[i] + 1) % rightContent.attachment[i]?.convertedS3Keys?.length;
      return newIndices;
    });
  };

  const handlePrevAttachment = (e, i) => {
    e.stopPropagation();
    setCurrentAttachmentIndices((prev) => {
      const newIndices = [...prev];
      const nextIndex = newIndices[i] - 1;
      newIndices[i] =
        nextIndex < 0 ? rightContent.attachment[i]?.convertedS3Keys?.length - 1 : nextIndex;
      return newIndices;
    });
  };
  const handlePrevCommentAttachment = (event, fileIndex) => {
    event.stopPropagation();

    setCurrentAttachmentIndices((prevIndices) => {
      const currentIndex = prevIndices?.[fileIndex] ?? 0;
      return {
        ...prevIndices,
        [fileIndex]: currentIndex > 0 ? currentIndex - 1 : 0
      };
    });
  };

  const handleNextCommentAttachment = (event, fileIndex) => {
    event.stopPropagation();

    const file = rightContent?.comment?.[0]?.attachment?.[fileIndex];
    const totalPages = file?.convertedS3Keys?.length || 0;

    setCurrentAttachmentIndices((prevIndices) => {
      const currentIndex = prevIndices?.[fileIndex] ?? 0;

      return {
        ...prevIndices,
        [fileIndex]: currentIndex < totalPages - 1 ? currentIndex + 1 : currentIndex
      };
    });
  };

  const handlePrevEmailAttachment = (event, fileIndex) => {
    event.stopPropagation();

    setCurrentAttachmentIndices((prevIndices) => {
      if (!prevIndices) return {}; 
      const currentIndex = prevIndices[fileIndex] ?? 0;

      return {
        ...prevIndices,
        [fileIndex]: Math.max(0, currentIndex - 1) 
      };
    });
  };

  const handleNextEmailAttachment = (event, fileIndex) => {
    event.stopPropagation();

    const file = rightContent?.emailRef?.attachment?.[fileIndex];
    const totalPages = file?.convertedS3Keys?.length || 0;
    if (!file || totalPages === 0) return;

    setCurrentAttachmentIndices((prevIndices) => {
      if (!prevIndices) return {}; 
      const currentIndex = prevIndices[fileIndex] ?? 0;

      return {
        ...prevIndices,
        [fileIndex]: Math.min(totalPages - 1, currentIndex + 1) 
      };
    });
  };

  const handleImageClick = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    setEnlargedStates((prev) => {
      const newEnlargedStates = [...prev];
      newEnlargedStates[i] = !newEnlargedStates[i];
      return newEnlargedStates;
    });
  };

  return (
    <Card
      sx={{
        borderRadius: '1em',
        backgroundColor: Colors.BG_BLACK,
        marginBottom: '1em',
        width: '100%'
      }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div">
              {timelineLeftContent?.status === 'Pending' ||
              timelineLeftContent?.status === 'Error' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.3em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {UPLOADED_BY}
                  </Typography>

                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : timelineLeftContent?.status === 'Created' ||
                timelineLeftContent?.status === 'Populated' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '0.5em' }}>
                    <Check
                      sx={{
                        color: Colors.TOURQUISE,
                        mr: '0.3em',
                        fontSize: { lg: '0.8em', xl: '1em' }
                      }}
                    />
                    <Typography
                      sx={{
                        color: Colors.TEXTGREY,
                        fontSize: { lg: '0.6em', xl: '1em' },
                        fontWeight: '600'
                      }}>
                      {CREATED_BY}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : timelineLeftContent?.status === 'Add' && rightContent?.comment?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.3em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {COMMENTED_BY}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : timelineLeftContent?.status === 'Add' && rightContent?.attachment?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.5em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {ATTACHMENT_BY}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : timelineLeftContent?.status === 'Remove' &&
                rightContent?.attachment?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.5em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {ATTACHMENT_REMOVED_BY}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : timelineLeftContent?.status === 'Sent Email' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.5em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {EMAIL_SENT}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <InfoOutlined
                    sx={{
                      color: Colors.TOURQUISE,
                      mr: '0.3em',
                      fontSize: { lg: '0.8em', xl: '1em' }
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.TEXTGREY,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600',
                      marginRight: '0.3em'
                    }}>
                    {UPDATED_BY}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.TOURQUISE,
                      fontSize: { lg: '0.6em', xl: '1em' },
                      fontWeight: '600'
                    }}>
                    {timelineLeftContent?.userName || timelineLeftContent?.user}
                  </Typography>
                </Box>
              )}
              <Divider
                sx={{
                  borderBottom: `2px dashed ${Colors.GREY}`,
                  marginTop: '0.5em'
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} py={1}>
              {((documentType === 'Po' && activityIndex !== 0) || documentType !== 'Po') && (
                <>
                  {rightContent &&
                    !isEmpty(rightContent?.standardContent) &&
                    timelineLeftContent?.status !== 'Populated' &&
                    timelineLeftContent?.status !== 'Created' && (
                      <Grid container spacing={1} sx={{ px: 2, mb: 1 }}>
                        <Grid item xs={4} sx={{ alignSelf: 'flex-start' }}>
                          <Typography
                            sx={{
                              color: Colors.TOURQUISE,
                              fontSize: { lg: '0.8em', xl: '1em' },
                              fontWeight: 'bold'
                            }}>
                            {CONTENT}:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {Object.entries(rightContent['standardContent']).map(([key, value]) => (
                            <Typography
                              key={key}
                              sx={{ fontSize: { xs: '13px', sm: '0.8em', xl: '1em' } }}>
                              {autoCap(key)} : {value}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    )}

                  {rightContent && !isEmpty(rightContent?.['lineItems']) && (
                    <>
                      {rightContent?.['lineItems']?.map((lineItem, index) =>
                        !isEmpty(lineItem) ? (
                          <Grid container spacing={1} sx={{ px: 2, mb: 1 }} key={index}>
                            <Grid item xs={4} sx={{ alignSelf: 'flex-start' }}>
                              <Typography
                                sx={{
                                  fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
                                  color: Colors.TOURQUISE,
                                  fontWeight: 'bold'
                                }}>
                                {`${LINEITEM} ${index + 1}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              {Object.entries(lineItem).map(([key, value]) => (
                                <Typography
                                  key={key}
                                  sx={{ fontSize: { xs: '13px', sm: '0.8em', xl: '1em' } }}>
                                  {key}: {value}
                                </Typography>
                              ))}
                            </Grid>
                          </Grid>
                        ) : null
                      )}
                    </>
                  )}

                  {rightContent && !isEmpty(rightContent?.attachment) && (
                    <Grid container spacing={1} sx={{ px: 2, mb: 1 }}>
                      {rightContent?.attachment?.map((file, i) => (
                        <Grid key={i} item xs={12}>
                          <Box
                            sx={{
                              backgroundColor: Colors.Canvas_BG,
                              height: '25vh',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '20px',
                              position: 'relative',
                              padding: '.5rem 0'
                            }}>
                            {file?.convertedS3Keys?.length > 1 && (
                              <>
                                <IconButton
                                  sx={{ position: 'absolute', left: '3%', color: Colors.WHITE }}
                                  onClick={(e) => handlePrevAttachment(e, i)}>
                                  <ChevronLeft />
                                </IconButton>
                                <IconButton
                                  sx={{ position: 'absolute', right: '3%', color: Colors.WHITE }}
                                  onClick={(e) => handleNextAttachment(e, i)}>
                                  <ChevronRight />
                                </IconButton>
                              </>
                            )}
                            <>
                              <img
                                alt="attachment"
                                src={file?.convertedS3Keys[currentAttachmentIndices[i]]}
                                style={{
                                  height: '100%',
                                  objectFit: 'contain',
                                  cursor: 'pointer'
                                }}
                                onClick={(e) => handleImageClick(e, i)}
                              />
                              {enlargedStates[i] && (
                                <img
                                  alt="attachment"
                                  src={file?.convertedS3Keys[currentAttachmentIndices[i]]}
                                  style={{
                                    width: '100vw',
                                    height: '100vh',
                                    objectFit: 'contain',
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    opacity: 1
                                  }}
                                  onClick={(e) => handleImageClick(e, i)}
                                />
                              )}
                            </>
                          </Box>
                          <Box
                            sx={{
                              textAlign: 'left',
                              mt: 1,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => downloadAttachment(file.s3Key, companyId)}>
                            <Typography
                              sx={{
                                maxWidth: '120px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                color: Colors.TEXTGREY,
                                fontSize: { lg: '0.8em', xl: '1em' },
                                cursor: 'pointer'
                              }}>
                              {file.fileName}
                            </Typography>
                            <IconButton
                              sx={{
                                color: Colors.TOURQUISE,
                                fontSize: { lg: '0.8em', xl: '1em' }
                              }}>
                              <ArrowDownwardOutlined />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {rightContent && !isEmpty(rightContent?.comment) && (
                    <>
                      {rightContent?.comment?.map((comment, commentIndex) => (
                        <Grid container spacing={1} sx={{ px: 2, mb: 1 }} key={commentIndex}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                mb: 1
                              }}>
                              {comment?.comment}
                            </Typography>

                            {!isEmpty(comment?.attachment) && (
                              <Grid container spacing={2}>
                                {comment?.attachment?.map((file, fileIndex) => {
                                  const currentIndex = currentAttachmentIndices?.[fileIndex] ?? 0;
                                  const imageUrl = file?.convertedS3Keys?.[currentIndex] ?? '';
                                  const isSingleAttachment = comment?.attachment?.length === 1;

                                  return (
                                    <Grid key={fileIndex} item xs={isSingleAttachment ? 12 : 6}>
                                      <Box
                                        sx={{
                                          backgroundColor: Colors.Canvas_BG,
                                          minHeight: '20vh',
                                          maxHeight: '25vh',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          borderRadius: '20px',
                                          position: 'relative',
                                          padding: '.5rem 0'
                                        }}>
                                        {file?.convertedS3Keys?.length > 1 && (
                                          <>
                                            <IconButton
                                              sx={{
                                                position: 'absolute',
                                                left: '3%',
                                                color: isSingleAttachment
                                                  ? Colors.WHITE
                                                  : Colors.BG_BLACK
                                              }}
                                              onClick={(e) =>
                                                handlePrevCommentAttachment(e, fileIndex)
                                              }>
                                              <ChevronLeft />
                                            </IconButton>

                                            <IconButton
                                              sx={{
                                                position: 'absolute',
                                                right: '3%',
                                                color: isSingleAttachment
                                                  ? Colors.WHITE
                                                  : Colors.BG_BLACK
                                              }}
                                              onClick={(e) =>
                                                handleNextCommentAttachment(e, fileIndex)
                                              }>
                                              <ChevronRight />
                                            </IconButton>
                                          </>
                                        )}

                                        <img
                                          alt="comment attachment"
                                          src={imageUrl}
                                          style={{
                                            width: '100%',
                                            maxWidth: '90%',
                                            maxHeight: '30vh',
                                            objectFit: 'contain',
                                            cursor: 'pointer'
                                          }}
                                          onClick={(e) => handleImageClick(e, fileIndex)}
                                        />
                                        {enlargedStates[fileIndex] && (
                                          <img
                                            alt="comment attachment"
                                            src={imageUrl}
                                            style={{
                                              width: '100vw',
                                              height: '100vh',
                                              objectFit: 'contain',
                                              position: 'fixed',
                                              top: 0,
                                              left: 0,
                                              zIndex: 9999,
                                              cursor: 'pointer'
                                            }}
                                            onClick={(e) => handleImageClick(e, fileIndex)}
                                          />
                                        )}
                                      </Box>

                                      <Box
                                        sx={{
                                          textAlign: 'left',
                                          mt: 1,
                                          display: 'flex',
                                          alignItems: 'center',
                                          cursor: 'pointer'
                                        }}
                                        onClick={() =>
                                          downloadAttachment(file.s3FileKey, companyId)
                                        }>
                                        <Typography
                                          sx={{
                                            maxWidth: '120px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            color: Colors.TEXTGREY,
                                            fontSize: { lg: '0.8em', xl: '1em' },
                                            cursor: 'pointer'
                                          }}>
                                          {file.originalFileName}
                                        </Typography>
                                        <IconButton
                                          sx={{
                                            color: Colors.TOURQUISE,
                                            fontSize: { lg: '0.8em', xl: '1em' }
                                          }}>
                                          <ArrowDownwardOutlined />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                  {rightContent && (rightContent?.emailRef || rightContent?.email) && (
                    <Grid container spacing={2} sx={{ px: 2, mb: 2 }}>
                      {/* Email Header */}
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: '0.8em',
                            color: Colors.TEXTGREY,
                            textAlign: 'left'
                          }}>
                          From:{' '}
                          <span style={{ marginLeft: '5px' }}>
                            {(rightContent?.emailRef || rightContent?.email)?.from}
                          </span>
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: '0.8em',
                            color: Colors.TEXTGREY,
                            textAlign: 'left'
                          }}>
                          To:{' '}
                          <span style={{ marginLeft: '5px' }}>
                            {(rightContent?.emailRef || rightContent?.email)?.to}
                          </span>
                        </Typography>

                        {(rightContent?.emailRef || rightContent?.email)?.cc?.length > 0 && (
                          <Typography
                            sx={{
                              fontSize: '0.8em',
                              color: Colors.TEXTGREY,
                              textAlign: 'left'
                            }}>
                            CC:{' '}
                            <span style={{ marginLeft: '5px' }}>
                              {rightContent?.emailRef?.cc.join(', ')}
                            </span>
                          </Typography>
                        )}
                      </Grid>

                      {/* Email Subject */}
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: '0.8em',
                            color: Colors.TEXTGREY,
                            textAlign: 'left',
                            mb: 1
                          }}>
                          Subject: {(rightContent?.emailRef || rightContent?.email)?.subject}
                        </Typography>
                      </Grid>

                      {/* Email Body */}
                      <Grid item xs={12}>
                        <Typography
                          component="div"
                          sx={{
                            color: Colors.TEXTGREY,
                            textAlign: 'left',
                            '& *': {
                              textAlign: 'left !important',
                              fontSize: '0.8rem !important'
                            }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: cleanEmailText(
                              (rightContent?.emailRef || rightContent?.email)?.text
                            )
                          }}
                        />
                      </Grid>

                      {/* Attachments */}
                      {!(rightContent?.emailRef || rightContent?.email)?.attachment
                        ?.length ? null : (
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: { xs: '13px', sm: '1em' },
                              fontWeight: 'bold',
                              color: Colors.TEXTGREY,
                              mb: 1,
                              textAlign: 'left'
                            }}>
                            Attachments:
                          </Typography>
                          <Grid container spacing={2}>
                            {(rightContent?.emailRef || rightContent?.email)?.attachment?.map(
                              (file, fileIndex) => {
                                const currentIndex = currentAttachmentIndices?.[fileIndex] ?? 0;
                                const imageUrl = file?.convertedS3Keys?.[currentIndex] ?? '';

                                return (
                                  <Grid key={fileIndex} item xs={12} sm={6} md={6}>
                                    <Box
                                      sx={{
                                        backgroundColor: Colors.Canvas_BG,
                                        minHeight: '25vh',
                                        maxHeight: '25vh',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '5px',
                                        position: 'relative',
                                        padding: '.5rem 0',
                                        cursor: 'pointer'
                                      }}
                                      onClick={(e) => handleImageClick(e, fileIndex)}>
                                      {/* Multi-page navigation */}
                                      {file?.convertedS3Keys?.length > 1 && (
                                        <>
                                          <IconButton
                                            sx={{
                                              position: 'absolute',
                                              left: '3%',
                                              color: Colors.WHITE
                                            }}
                                            onClick={(e) =>
                                              handlePrevEmailAttachment(e, fileIndex)
                                            }>
                                            <ChevronLeft />
                                          </IconButton>
                                          <IconButton
                                            sx={{
                                              position: 'absolute',
                                              right: '3%',
                                              color: Colors.WHITE
                                            }}
                                            onClick={(e) =>
                                              handleNextEmailAttachment(e, fileIndex)
                                            }>
                                            <ChevronRight />
                                          </IconButton>
                                        </>
                                      )}

                                      <img
                                        alt="attachment preview"
                                        src={imageUrl}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          maxHeight: '25vh',
                                          objectFit: 'contain',
                                          cursor: 'pointer'
                                        }}
                                        onClick={(e) => handleImageClick(e, fileIndex)}
                                      />
                                      {enlargedStates[fileIndex] && (
                                        <img
                                          alt="attachment preview"
                                          src={imageUrl}
                                          style={{
                                            width: '100vw',
                                            height: '100vh',
                                            objectFit: 'contain',
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            zIndex: 9999,
                                            cursor: 'pointer'
                                          }}
                                          onClick={(e) => handleImageClick(e, fileIndex)}
                                        />
                                      )}
                                    </Box>

                                    {/* Download Option */}
                                    <Box
                                      sx={{
                                        textAlign: 'left',
                                        mt: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => downloadAttachment(file.s3FileKey, companyId)}>
                                      <Typography
                                        sx={{
                                          maxWidth: '120px',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          color: Colors.TEXTGREY,
                                          fontSize: { lg: '0.8em', xl: '1em' }
                                        }}>
                                        {file.originalFileName}
                                      </Typography>
                                      <IconButton
                                        sx={{
                                          color: Colors.TOURQUISE,
                                          fontSize: { lg: '0.8em', xl: '1em' }
                                        }}>
                                        <ArrowDownwardOutlined />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                );
                              }
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {rightContent && !isEmpty(rightContent?.supportingDocument) && (
                    <Grid container spacing={1} sx={{ px: 2, mb: 1 }}>
                      <Grid item xs={4} sx={{ alignSelf: 'flex-start' }}>
                        <Typography
                          sx={{
                            color: Colors.TOURQUISE,
                            fontSize: { lg: '0.8em', xl: '1em' },
                            fontWeight: 'bold'
                          }}>
                          {DELIVERY_TICKET}:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {rightContent?.supportingDocument?.map((value, i) => (
                          <div key={i} style={{ marginBottom: '8px' }}>
                            <Typography sx={{ fontSize: { xs: '13px', sm: '0.8em', xl: '1em' } }}>
                              Reference No : {value?.documentNumber}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '13px', sm: '0.8em', xl: '1em' },
                                maxWidth: '230px',
                                wordBreak: 'break-word'
                              }}>
                              Filename : {value?.fileName}
                            </Typography>
                            <Typography sx={{ fontSize: { xs: '13px', sm: '0.8em', xl: '1em' } }}>
                              Type :{' '}
                              {value?.documentType === 'Estimate' ? 'Quote' : value?.documentType}
                            </Typography>
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pt: '1em'
              }}>
              <Typography
                sx={{
                  color: Colors.TEXTGREY,
                  fontSize: { lg: '0.8em', xl: '1em' },
                  fontWeight: '600',
                  pl: '0.5em'
                }}>
                {timelineLeftContent?.user}
              </Typography>
              <Typography
                sx={{
                  color: Colors.TEXTGREY,
                  fontSize: { lg: '12px', xl: '12px' }
                }}>
                {formattedDate}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ActivityDetailCard;
