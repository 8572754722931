import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { Colors } from '../config/default';
import AttachmentDropzone from '../components/attachmentDropzone';
import { Close } from '@mui/icons-material';
import { useToast } from '../toast/toastContext';
import { uploadAttachments } from '../services/services';
import { isEmpty } from 'lodash';
import { truncateFileName } from '../services/common';
import '../../src/attachment.css';

export default function AttachmentUpload({ refreshAttachments, setOpenUploadModal, id }) {
  const { showToast } = useToast();
  const [files, setFiles] = useState([]);
  const [instrumentIds, setInstrumentIds] = useState([]);
  const [uploading, setUploading] = useState(false);

  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;

  const generateUniqueId = () => `INST-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  useEffect(() => {
    setInstrumentIds(files?.map(() => generateUniqueId()));
  }, [files]);

  const removeFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const UploadAttachment = async () => {
    setUploading(true);
    if (isEmpty(files)) {
      showToast('Please upload at least one file.', 'error');
      return;
    }
    setUploading(true);
    const result = await uploadAttachments(files, [id], instrumentIds, companyId);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      setFiles([]);
      setInstrumentIds([]);
      await refreshAttachments();
      setOpenUploadModal(false);
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setUploading(false);
  };

  return (
    <Grid container sx={{ height: '75vh', padding: '1rem 2rem' }}>
      {/* Attachments Section */}
      <Grid
        item
        xs={12}
        sx={{ p: 2, backgroundColor: Colors.NAV_BLACK, borderRadius: '20px', height: '73vh' }}>
        <Typography sx={{ color: Colors.TOURQUISE, mb: '1rem' }}>Attachments</Typography>
        <AttachmentDropzone setFiles={setFiles} />
        <Grid item sx={{ height: '62%', overflowY: 'auto', mb: 2 }}>
          {isEmpty(files) ? (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <Typography sx={{ color: Colors.TEXTGREY }}>No Attachments</Typography>
            </Grid>
          ) : (
            files.map((file, index) => (
              <Box
                key={index}
                sx={{ border: '2px dashed #5B5B5B', borderRadius: '20px', p: 2, mb: 1 }}>
                <Close
                  sx={{ float: 'right', color: Colors.TEXTGREY, cursor: 'pointer' }}
                  onClick={() => removeFile(file.name)}
                />
                <Typography variant="body1" sx={{ color: Colors.WHITE, mt: 1 }}>
                  <strong>File Name:</strong> {truncateFileName(file.name, 19)}
                </Typography>
              </Box>
            ))
          )}
        </Grid>
        <Button
          disabled={uploading || isEmpty(files)}
          sx={{
            color: Colors.WHITE,
            backgroundColor: Colors.DEEPBLUE,
            borderRadius: '10px',
            width: '100%',
            '&:hover': { background: Colors.DEEPBLUE },
            '&:disabled': { backgroundColor: '#282828' }
          }}
          onClick={UploadAttachment}>
          {uploading ? <CircularProgress size={25} color="inherit" /> : 'Attach'}
        </Button>
      </Grid>
    </Grid>
  );
}
