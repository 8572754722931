import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';

import { DataGrid, GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { Colors } from '../config/default';
import {
  DescriptionOutlined,
  CallReceivedOutlined,
  TopicOutlined,
  GradingOutlined,
  CalendarMonthOutlined,
  AccountBalanceOutlined,
  PersonOutlineOutlined,
  SensorsOutlined,
  HighlightAltOutlined,
  CheckCircleOutlined,
  AccessTimeOutlined,
  SendOutlined,
  DeleteOutlineOutlined,
  HourglassTopRounded,
  ErrorOutlineRounded,
  CloseOutlined,
  PanToolOutlined,
  VisibilityOutlined,
  ContentCopy,
  AttachFile,
  CheckOutlined,
  EmailOutlined,
  SubdirectoryArrowLeft,
  PaidOutlined
} from '@mui/icons-material';
import { CircularProgress, Grid, Typography, Box, IconButton, Button } from '@mui/material';

import { formatDate, formatDateWithoutTime, formatNumber } from '../services/common';
import { userRoles } from '../constants/appConstants';
import {
  sendToQBD,
  sendQuickbook,
  getSelectedDocs,
  deleteDocument,
  updateTableColumns,
  getUserInfo,
  pullBackDocument
} from '../services/services';
import { HtmlTooltip } from '../services/common';
import { get_queue, get_pending_data, user_information } from '../redux/actions/action';
import ActivityDrawer from './activityDrawer';
import Prompt from './prompt';
import { useToast } from '../toast/toastContext';
import { documentStatusText, tableComponent } from '../constants/appConstants';
import MuiModal from './muiModel';
import { disablePullBack } from '../constants/utils';

const datagridSx = {
  border: 'none',
  color: Colors.TEXTGREY,
  paddingX: '1rem',
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-main': { borderRadius: '10px' },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderBottom: 'none !important',
    '& .MuiDataGrid-root': { border: 'none !important' },
    '& .MuiDataGrid-row': {
      width: '99%',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#24658D !important',
        color: Colors.WHITE
      },
      '&:nth-of-type(2n-1)': {
        backgroundColor: '#1E1E1E'
      }
    },
    '& div': { border: 'none' }
  },

  '& .MuiDataGrid-columnCheckbox': {
    backgroundColor: '#fdfdfd',
    width: '20px',
    height: '20px'
  },

  '& .MuiDataGrid-columnHeaders': {
    border: 'none !important',
    color: Colors.WHITE
  }
};
export default function DataTable({
  checkedIds,
  setCheckedIds,
  resdata,
  toShow,
  filterData,
  recordExist,
  isIntegrated,
  childActiveTab,
  setDataAccordingTabs,
  setSelectedRowsHasPO,
  searchProducts,
  getDocument,
  setSelectedRowsHasReview,
  setChildActiveTab
}) {
  const {
    APPROVED,
    PENDING,
    REJECT,
    WAITING,
    ERROR,
    ONHOLD,
    POPULATED,
    DUPLICATE,
    EXPORT,
    ATTACHED,
    GENERATED
  } = documentStatusText;
  const {
    RECIEVED_AT,
    VENDOR_NAME,
    AMOUNT_DUE,
    PO_NUMBER,
    DOCUMENT_DATE,
    DOCUMENT_NAME,
    DOCUMENT_ID,
    JOB_ID,
    PENDING_DOC,
    STATUS,
    ONHOLD_DOC,
    REJECTED_DOC,
    IN_REVIEW,
    PROCESSING,
    ERROR_DOC,
    VERFIED,
    APPROVED_DOC,
    ACTIONS,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_MESSAGE,
    DOCUMENT_NOT_FOUND,
    SEARCH_NOT_FOUND,
    APPROVER_DOCUMENT_NOT_FOUND,
    GENERATE_PO,
    INVOICE_NUMBER,
    SEND_BY_EMAIL,
    PULL_BACK,
    PULL_BACK_MESSAGE,
    PAYMENT_STATUS,
    DOCUMENT_TYPE,
    IN_ERP
  } = tableComponent;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [loading, setLoading] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [pullingBack, setPullingBack] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [pullBackOpen, setPullBackOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [opened, setOpened] = useState(false);
  const searchedKey = sessionStorage.getItem('searchedKey');
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const defaultColumns = {
    receivedAt: true,
    __check__: true,
    vendorName: true,
    invoiceNo: true,
    amountDue: true,
    poNumber: true,
    dueDate: true,
    documentName: true,
    documentId: true,
    status: true,
    actions: true,
    iconButtons: true,
    sendByEmail: true,
    paid: true,
    documentType: true
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    userDetails?.user?.customTableContent || defaultColumns
  );
  const [page, setPage] = useState(0);
  const [highlightedRow, setHighlightedRow] = useState(null);

  const resetColumns = () => {
    setColumnVisibilityModel(defaultColumns);
    updateContent(defaultColumns);
  };
  const areColumnsSame = JSON.stringify(columnVisibilityModel) === JSON.stringify(defaultColumns);
  const CustomFooter = ({ resetColumns }) => (
    <GridFooterContainer>
      <Button
        disabled={areColumnsSame}
        onClick={resetColumns}
        sx={{ margin: '10px', ':hover': { background: 'none' } }}>
        Reset Columns
      </Button>
      <GridPagination />
    </GridFooterContainer>
  );
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );
  const erpType = userDetails?.user?.erpType;
  const handleOpenDuplicatePopup = (params) => {
    setOpened(true);
    setRowId(params?.row?.id);
    setDocumentName(params?.row?.documentName);
  };
  const handleOpen = (e, params) => {
    e.stopPropagation();
    setOpen(true);
    setRowId(params?.row?.id);
    setDocumentName(params?.row?.documentName);
  };
  const handlePullBackOpen = (e, params) => {
    e.stopPropagation();
    setPullBackOpen(true);
    setRowId(params?.row?.id);
    setDocumentName(params?.row?.documentName);
  };

  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const { OWNER, ACCOUNTANT, VENDOR } = userRoles;

  const filterByDocumentStatus = (data) => {
    if (childActiveTab === 'To Review') {
      return (
        data?.filter(
          (item) =>
            item?.documentStatus !== WAITING &&
            item?.documentStatus !== REJECT &&
            item?.documentStatus !== ONHOLD &&
            item?.exportCsvStatus !== EXPORT &&
            item?.documentStatus !== APPROVED
        ) || []
      );
    } else if (childActiveTab === 'In Review') {
      return data?.filter((item) => item?.documentStatus === WAITING) || [];
    } else if (childActiveTab === 'Rejected') {
      return data?.filter((item) => item?.documentStatus === REJECT) || [];
    } else if (childActiveTab === 'On Hold') {
      return data?.filter((item) => item?.documentStatus === ONHOLD) || [];
    } else if (childActiveTab === 'Exported') {
      return data?.filter((item) => item?.exportCsvStatus === EXPORT) || [];
    } else if (childActiveTab === 'Approved') {
      return (
        data?.filter(
          (item) => item?.documentStatus === APPROVED && item?.exportCsvStatus !== EXPORT
        ) || []
      );
    } else {
      return data || [];
    }
  };
  useEffect(() => {
    let filteredResult;
    if (toShow === 'ApiSearch' && !isEmpty(filterData)) {
      filteredResult = filterByDocumentStatus(filterData);
    } else if (toShow === 'ApiSearch' && recordExist) {
      filteredResult = filterByDocumentStatus(filterData);
    } else {
      filteredResult = filterByDocumentStatus(resdata);
    }
    setDataAccordingTabs(filteredResult);
  }, [filterData, childActiveTab]);

  const handleDeleteDocument = async (e) => {
    e.stopPropagation();
    setDeleting(true);
    const params = {
      documentIds: [rowId]
    };
    const result = await deleteDocument(params);
    if (result?.status === 200) {
      const response = await getSelectedDocs(
        searchedKey || searchProducts,
        getDocument,
        callSource
      );
      setDeleting(false);
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      if (response?.response?.status === 401) {
        sessionStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(response?.data?.payload?.data));
      setCheckedIds([]);
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
      setLoading(false);
      setOpen(false);
    }
  };

  const handlePullBackDocument = (e) => {
    e.stopPropagation();
    pullBackDoc(rowId);
  };

  const pullBackDoc = async (id) => {
    setPullingBack(true);
    const params = {
      documentIds: [id],
      companyId: userDetails?.company?._id
    };
    const result = await pullBackDocument(params);
    if (result?.status === 200) {
      const response = await getSelectedDocs(
        searchedKey || searchProducts,
        getDocument,
        callSource
      );
      setPullingBack(false);
      showToast(result?.data?.metadata?.message, 'success');
      setPullBackOpen(false);
      dispatch(get_queue(response?.data?.payload?.data));
      const documentIndex = response?.data?.payload?.data?.documents?.findIndex(
        (doc) => doc._id === id
      );
      if (documentIndex !== -1) {
        const pageToNavigate = Math.floor(documentIndex / 9);
        setPage(pageToNavigate);
        setHighlightedRow(id);
      }
      setChildActiveTab('To Review');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
      setLoading(false);
      setPullBackOpen(false);
    }
  };

  let filteredData;
  if (toShow === 'ApiSearch' && !isEmpty(filterData)) {
    filteredData = filterByDocumentStatus(filterData);
  } else if (toShow === 'ApiSearch' && recordExist) {
    filteredData = filterByDocumentStatus(filterData);
  } else {
    filteredData = filterByDocumentStatus(resdata);
  }
  const updateContent = async (dataColumn) => {
    setColumnVisibilityModel(dataColumn);
    const params = {
      customTableContent: dataColumn
    };
    const result = await updateTableColumns(params);
    if (result?.status === 200) {
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
      }
    }
  };
  useEffect(() => {
    if (filteredData) {
      dispatch(get_pending_data(filteredData));
    }
  }, [filteredData]);

  const documentFilter = (documentStatus, erpQbdStatus, sendErpStatus) => {
    switch (documentStatus) {
      case PENDING:
        return PROCESSING;
      case VERFIED:
        return VERFIED;
      case ONHOLD:
        return ONHOLD_DOC;
      case REJECT:
        return REJECTED_DOC;
      case WAITING:
        return IN_REVIEW;
      case POPULATED:
        return PENDING_DOC;
      case DUPLICATE:
        return DUPLICATE;
      case ATTACHED:
        return GENERATED;
      case APPROVED:
        if (erpQbdStatus === PENDING) {
          return 'Added to Queue';
        } else if (erpQbdStatus === ERROR) {
          return ERROR_DOC;
        } else if (erpQbdStatus === APPROVED || sendErpStatus === APPROVED) {
          return VERFIED;
        } else {
          return APPROVED_DOC;
        }
      default:
        return ERROR_DOC;
    }
  };
  const columns = [
    {
      field: 'sendByEmail',
      filterable: false,
      headerName: SEND_BY_EMAIL,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <EmailOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {SEND_BY_EMAIL}
        </div>
      ),
      flex: 1,
      minWidth: 85,
      renderCell: (params) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%'
          }}>
          {params?.row?.sendByEmail ? <CheckOutlined sx={{ color: Colors.TOURQUISE }} /> : '-'}
        </div>
      )
    },
    {
      field: 'paid',
      filterable: false,
      headerName: PAYMENT_STATUS,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PaidOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {PAYMENT_STATUS}
        </div>
      ),
      flex: 1,
      minWidth: 85,
      renderCell: (params) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%'
          }}>
          {params?.row?.paymentStatus === 'Paid' ? (
            <CheckOutlined sx={{ color: Colors.TOURQUISE }} />
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      field: 'receivedAt',
      filterable: false,
      headerName: RECIEVED_AT,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CallReceivedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {RECIEVED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 90
    },
    {
      field: 'documentType',
      filterable: false,
      headerName: DOCUMENT_TYPE,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {DOCUMENT_TYPE}
        </div>
      ),
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%'
          }}>
          {params?.row?.documentType === 'Estimate'
            ? 'Quote'
            : params?.row?.documentType === 'DeliveryTicket'
            ? 'Delivery Ticket'
            : params?.row?.documentType}
        </div>
      )
    },
    {
      field: 'vendorName',
      headerName: VENDOR_NAME,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PersonOutlineOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {VENDOR_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'invoiceNo',
      filterable: false,
      headerName: INVOICE_NUMBER,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <TopicOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {INVOICE_NUMBER}
        </div>
      ),
      flex: 1,
      minWidth: 120
    },
    {
      field: 'amountDue',
      filterable: false,
      headerName: AMOUNT_DUE,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <AccountBalanceOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {AMOUNT_DUE}
        </div>
      ),
      flex: 1,
      minWidth: 110
    },
    {
      field: 'poNumber',
      filterable: false,
      headerName: PO_NUMBER,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <GradingOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {PO_NUMBER}
        </div>
      ),
      flex: 1,
      minWidth: 110
    },
    {
      field: 'dueDate',
      filterable: false,
      headerName: DOCUMENT_DATE,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CalendarMonthOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {DOCUMENT_DATE}
        </div>
      ),
      flex: 1,
      minWidth: 130
    },
    {
      field: 'documentName',
      headerName: DOCUMENT_NAME,
      disableColumnMenu: true,
      hideable: false,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {DOCUMENT_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'documentId',
      filterable: false,
      headerName: userRole === 'Vendor' ? DOCUMENT_ID : JOB_ID,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <TopicOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {userRole === 'Vendor' ? DOCUMENT_ID : JOB_ID}
        </div>
      ),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'status',
      headerName: STATUS,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <SensorsOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {STATUS}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: true,
      valueGetter: (params) =>
        documentFilter(
          params?.row?.documentStatus,
          params?.row?.erpQbdStatus,
          params?.row?.sendErpStatus
        ),
      renderCell: (params) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          {params?.row?.documentStatus === POPULATED ? (
            <>
              <AccessTimeOutlined
                sx={{ color: Colors.MUSTARD, fontSize: '17px', marginRight: '4px' }}
              />
              {PENDING_DOC}
            </>
          ) : params?.row?.documentStatus === ONHOLD ? (
            <>
              <PanToolOutlined
                sx={{ color: Colors.YELLOW, fontSize: '17px', marginRight: '4px' }}
              />
              {ONHOLD_DOC}
            </>
          ) : params?.row?.documentStatus === REJECT ? (
            <>
              <CloseOutlined sx={{ color: Colors.RED, fontSize: '17px', marginRight: '4px' }} />
              {REJECTED_DOC}
            </>
          ) : params?.row?.documentStatus === WAITING ? (
            <>
              <VisibilityOutlined
                sx={{ color: Colors.DARKBLUE, fontSize: '17px', marginRight: '4px' }}
              />
              {IN_REVIEW}
            </>
          ) : params?.row?.documentStatus === ATTACHED ? (
            <>
              <AttachFile sx={{ color: Colors.DARKBLUE, fontSize: '17px', marginRight: '4px' }} />
              {params?.row?.documentType === 'Estimate' ? GENERATED : ATTACHED}
            </>
          ) : params?.row?.documentStatus === PENDING ? (
            <>
              <HourglassTopRounded
                sx={{ color: Colors.LIGHTGREY, fontSize: '17px', marginRight: '4px' }}
              />
              {PROCESSING}
            </>
          ) : params?.row?.documentStatus === DUPLICATE ? (
            <>
              <ContentCopy sx={{ color: Colors.TEEL_BLUE, fontSize: '17px', marginRight: '4px' }} />
              {DUPLICATE}
            </>
          ) : params?.row?.documentStatus === APPROVED ? (
            params?.row?.erpQbdStatus === PENDING ? (
              'Added to Queue'
            ) : params?.row?.erpQbdStatus === ERROR ? (
              <HtmlTooltip
                title={
                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                    {params?.row?.message}
                  </Typography>
                }>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <ErrorOutlineRounded
                    sx={{
                      color: Colors.RED,
                      fontSize: '17px',
                      marginRight: '4px'
                    }}
                  />
                  {ERROR_DOC}
                </Box>
              </HtmlTooltip>
            ) : params?.row?.erpQbdStatus === APPROVED ||
              params?.row?.sendErpStatus === APPROVED ? (
              <>
                <CheckCircleOutlined
                  sx={{ color: Colors.Green, fontSize: '17px', marginRight: '4px' }}
                />
                {IN_ERP}
              </>
            ) : (
              <>
                <CheckCircleOutlined
                  sx={{ color: Colors.LIGHTBLUE, fontSize: '17px', marginRight: '4px' }}
                />
                {APPROVED_DOC}
              </>
            )
          ) : (
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {params?.row?.message}
                </Typography>
              }>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <ErrorOutlineRounded
                  sx={{
                    color: Colors.RED,
                    fontSize: '17px',
                    marginRight: '4px'
                  }}
                />
                {ERROR_DOC}
              </Box>
            </HtmlTooltip>
          )}
        </div>
      )
    },

    {
      field: 'actions',
      sortable: false,
      filterable: false,
      headerName: ACTIONS,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTIONS}
        </div>
      ),
      flex: 1,
      minWidth: 115,
      renderCell: (params) =>
        params?.row?.documentType === 'Estimate' ? (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:
                params?.row?.documentStatus === APPROVED &&
                childActiveTab !== EXPORT &&
                params?.row?.sendErpStatus === PENDING
                  ? 'pointer'
                  : ''
            }}
            onClick={(e) =>
              params?.row?.documentStatus === APPROVED &&
              childActiveTab !== EXPORT &&
              params?.row?.sendErpStatus === PENDING &&
              (erpType === 'Foundation' || erpType === 'QuickBooksDesktop') &&
              userDetails?.user?.quotePermission
                ? navigate(`/generatePo/${params?.row?.id}`)
                : null
            }>
            <DescriptionOutlined
              sx={{
                fontSize: '17px',
                marginRight: '.5rem',
                color:
                  params?.row?.documentStatus === APPROVED &&
                  childActiveTab !== EXPORT &&
                  params?.row?.sendErpStatus === PENDING &&
                  (erpType === 'Foundation' || erpType === 'QuickBooksDesktop') &&
                  userDetails?.user?.quotePermission
                    ? Colors.TOURQUISE
                    : Colors.DISABLEDGRAY
              }}
            />
            {GENERATE_PO}
          </div>
        ) : (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:
                isIntegrated === '' ||
                params?.row?.documentStatus === POPULATED ||
                params?.row?.documentStatus === ONHOLD ||
                params?.row?.documentStatus === REJECT ||
                params?.row?.documentStatus === WAITING ||
                childActiveTab === EXPORT ||
                (erpType === 'Foundation' && params?.row?.documentType !== 'Po')
                  ? ''
                  : params?.row?.documentStatus === APPROVED &&
                    params?.row?.erpQbdStatus === PENDING
                  ? ''
                  : 'pointer'
            }}
            onClick={(e) =>
              isIntegrated === '' ||
              (erpType === 'Foundation' && params?.row?.documentType !== 'Po')
                ? null
                : params?.row?.documentStatus === APPROVED && params?.row?.erpQbdStatus === PENDING
                ? null
                : params?.row?.erpQbdStatus === APPROVED || params?.row?.sendErpStatus === APPROVED
                ? navigate(`/details/${params?.row?.id}`)
                : params?.row?.documentStatus === APPROVED && childActiveTab !== EXPORT
                ? handleSendToERP(
                    e,
                    params?.row?.id,
                    params?.row?.activeIntegration,
                    params?.row?.email,
                    params
                  )
                : null
            }>
            {loading[params.row.id] ? (
              <div
                style={{
                  minWidth: 160,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={22} sx={{ color: 'white' }} />
              </div>
            ) : (
              <>
                {params?.row?.erpQbdStatus === PENDING ? (
                  ''
                ) : params?.row?.erpQbdStatus === APPROVED ||
                  params?.row?.sendErpStatus === APPROVED ? (
                  <DescriptionOutlined
                    sx={{
                      fontSize: '20px',
                      marginRight: '.5rem',
                      color: Colors.TOURQUISE
                    }}
                  />
                ) : (
                  <SendOutlined
                    sx={{
                      fontSize: '17px',
                      marginRight: '.5rem',
                      color:
                        params?.row?.documentStatus === APPROVED &&
                        childActiveTab !== EXPORT &&
                        isIntegrated !== '' &&
                        erpType !== 'Foundation'
                          ? Colors.TOURQUISE
                          : (erpType === 'Foundation' || erpType === 'QuickBooksDesktop') &&
                            params?.row?.documentType === 'Po' &&
                            params?.row?.documentStatus === APPROVED &&
                            childActiveTab !== EXPORT &&
                            isIntegrated !== ''
                          ? Colors.TOURQUISE
                          : Colors.DISABLEDGRAY
                    }}
                  />
                )}

                {params.row.actions}
              </>
            )}
          </div>
        )
    },
    {
      field: 'iconButtons',
      sortable: false,
      filterable: false,
      headerName: '',
      minWidth: 120,
      flex: 1,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 90
          }}>
          {(userRole === OWNER || userRole === ACCOUNTANT) && (
            <>
              <HtmlTooltip
                title={
                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                    {DELETE_DOCUMENT}
                  </Typography>
                }>
                <span>
                  <IconButton
                    onClick={(e) => handleOpen(e, params)}
                    aria-label="activity"
                    disabled={params?.row?.documentStatus === WAITING}>
                    <DeleteOutlineOutlined
                      sx={{
                        fontSize: '0.8em',
                        color:
                          params?.row?.documentStatus === WAITING ? Colors.DISABLEDGRAY : Colors.RED
                      }}
                    />
                  </IconButton>
                </span>
              </HtmlTooltip>
              <Prompt
                title={DELETE_DOCUMENT}
                description={DELETE_DOCUMENT_MESSAGE}
                leftButton="Cancel"
                rightButton="Delete"
                deleteContent={documentName}
                setOpen={setOpen}
                open={open}
                opacity={true}
                handleClick={handleDeleteDocument}
                loading={deleting}
              />
            </>
          )}

          {params?.row?.documentStatus === DUPLICATE && (
            <MuiModal
              color="none"
              show="duplicateDocument"
              id={rowId}
              opened={opened}
              setOpened={setOpened}
              handleOpenPopup={() => handleOpenDuplicatePopup(params)}
              documentName={documentName}
              LargeScreenWidth="60vw"
              XLScreenWidth="60vw"
            />
          )}
          <ActivityDrawer
            type="document"
            id={params?.row?.id}
            disable={params?.row?.documentStatus}
            documentType={params?.row?.documentType}
          />
          {(userRole === OWNER || userRole === ACCOUNTANT) && (
            <>
              <HtmlTooltip
                title={
                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                    {params?.row?.documentApprovers === null ? 'Reset' : PULL_BACK}
                  </Typography>
                }>
                <span>
                  <IconButton
                    sx={{
                      marginRight: 2
                    }}
                    onClick={(e) => handlePullBackOpen(e, params)}
                    aria-label="pull-back"
                    disabled={disablePullBack(params)}>
                    <SubdirectoryArrowLeft
                      sx={{
                        fontSize: '0.8em',
                        color: disablePullBack(params) ? Colors.DISABLEDGRAY : Colors.MUSTARD
                      }}
                    />
                  </IconButton>
                </span>
              </HtmlTooltip>
              <Prompt
                title={PULL_BACK}
                description={PULL_BACK_MESSAGE}
                leftButton="Cancel"
                rightButton="Pull Back"
                pullBackContent={documentName}
                setOpen={setPullBackOpen}
                open={pullBackOpen}
                opacity={true}
                handleClick={handlePullBackDocument}
                loading={pullingBack}
              />
            </>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    const generatedData =
      filteredData &&
      filteredData?.map((item, index) => ({
        id: item?._id,
        receivedAt: formatDate(item?.createdAt),
        documentName: item?.originalFileName,
        documentId: userRole === VENDOR ? item?._id : item?.standardizeContent?.userJobId || '-',
        invoiceNo: item?.standardizeContent?.document_number || '-',
        poNumber: item?.standardizeContent?.purchase_order || '-',
        dueDate: formatDateWithoutTime(item?.standardizeContent?.date) || '-',
        amountDue: formatNumber(item?.standardizeContent?.total) || '-',
        vendorName: item?.standardizeContent?.vendor || '-',
        actions:
          item?.erpQbdStatus === PENDING ? (
            <span>&nbsp;&nbsp;&nbsp;---</span>
          ) : item?.erpQbdStatus === APPROVED || item?.sendErpStatus === APPROVED ? (
            'See Details'
          ) : (
            'Send to ERP'
          ),
        documentStatus: item?.documentStatus,
        message: item?.message,
        erpQbdStatus: item?.erpQbdStatus,
        sendErpStatus: item?.sendErpStatus,
        exportCsvStatus: item?.exportCsvStatus,
        documentType: item?.documentType,
        sendByEmail: item?.sendByEmail,
        documentApprovers: item?.documentApproval,
        paymentStatus: item?.paymentStatus,
        documentType: item?.documentType
      }));

    if (Array.isArray(generatedData) && !isEqual(generatedData, rows)) {
      setRows(generatedData);
    }
  }, [filteredData, rows]);
  const handleQuickbooksClick = async (id) => {
    const res = await sendQuickbook(id);
    if (res?.data?.metadata?.status === 'SUCCESS') {
      showToast(res?.data?.metadata?.message, 'success');
      const result = await getSelectedDocs(searchedKey || searchProducts, getDocument, callSource);
      dispatch(get_queue(result?.data?.payload?.data));
    } else if (res?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(res?.data?.metadata?.message || res?.response?.data?.metadata?.message, 'error');
    }
  };
  const handleSendToQBD = async (id) => {
    const response = await sendToQBD(id);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      const result = await getSelectedDocs(searchedKey || searchProducts, getDocument, callSource);
      dispatch(get_queue(result?.data?.payload?.data));
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
  };
  const handleSendToERP = async (event, id) => {
    event.stopPropagation();
    setLoading((prevLoadingRows) => ({ ...prevLoadingRows, [id]: true }));
    try {
      if (activeIntegration === 'QuickBooksDesktop') {
        await handleSendToQBD(id);
      } else {
        await handleQuickbooksClick(id);
      }
      setLoading((prevLoadingRows) => ({ ...prevLoadingRows, [id]: false }));
    } catch (error) {
      setLoading((prevLoadingRows) => ({ ...prevLoadingRows, [id]: false }));
    }
  };
  function CustomNoRowsOverlay() {
    return (
      <Grid
        container
        sx={{
          height: 'inherit',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            backgroundColor: Colors.NAV_BLACK,
            padding: '.5em',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: { xs: '.7em', md: '1em' },
            marginTop: '.5em'
          }}>
          <ErrorOutlineRounded
            sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
          />
          {toShow === 'ApiSearch' && recordExist
            ? SEARCH_NOT_FOUND
            : (userRole === OWNER || userRole === ACCOUNTANT) && childActiveTab === 'To Review'
            ? DOCUMENT_NOT_FOUND
            : APPROVER_DOCUMENT_NOT_FOUND}
        </Box>
      </Grid>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          width: '100%',
          height: { xs: 'auto', sm: '60vh', lg: '70vh' }
        }}>
        {rows ? (
          <DataGrid
            sx={{
              ...datagridSx,
              overflow: 'auto',
              '& .MuiDataGrid-virtualScroller': {
                '&::-webkit-scrollbar': {
                  height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.WHITE,
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: Colors.BG_BLACK
                }
              }
            }}
            rows={rows}
            columns={columns.filter(Boolean)}
            page={page}
            getRowClassName={(params) => {
              if (params.row.id === highlightedRow) {
                return 'highlight-row';
              }
              return '';
            }}
            autoPageSize
            unstable_cellSelection
            onPageChange={(newPage) => setPage(newPage)}
            hideFooterSelectedRowCount
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              footer: () => <CustomFooter resetColumns={resetColumns} /> // Custom toolbar with button
            }}
            onRowClick={(params) =>
              params?.row?.documentType === 'Estimate' &&
              params?.row?.documentStatus === APPROVED &&
              params?.row?.sendErpStatus === PENDING
                ? navigate(`/generatePo/${params?.row?.id}`)
                : userRole === VENDOR &&
                  params?.row?.documentStatus !== DUPLICATE &&
                  params?.row?.documentStatus !== ERROR
                ? navigate(`/details/${params?.row?.id}`)
                : params?.row?.documentStatus === POPULATED ||
                  params?.row?.documentStatus === ONHOLD ||
                  params?.row?.documentStatus === REJECT ||
                  (params?.row?.documentStatus === APPROVED && params?.row?.erpQbdStatus === ERROR)
                ? navigate(`/canvas/${params?.row?.id}`)
                : params?.row?.documentStatus === WAITING ||
                  params?.row?.documentStatus === ATTACHED ||
                  (params?.row?.documentStatus === APPROVED &&
                    params?.row?.erpQbdStatus !== ERROR) ||
                  params?.row?.documentStatus === ERROR ||
                  params?.row?.erpQbdStatus === ERROR
                ? navigate(`/details/${params?.row?.id}`)
                : params?.row?.documentStatus === DUPLICATE && userRole !== VENDOR
                ? handleOpenDuplicatePopup(params)
                : ''
            }
            isRowSelectable={(params) =>
              params?.row?.documentStatus == POPULATED ||
              params?.row?.documentStatus === APPROVED ||
              params?.row?.documentStatus === ATTACHED ||
              params?.row?.documentStatus === WAITING ||
              params?.row?.documentStatus === VERFIED ||
              (userRole === VENDOR &&
                params?.row?.documentStatus !== DUPLICATE &&
                params?.row?.documentStatus !== ERROR)
            }
            checkboxSelection={userRole === VENDOR ? false : true}
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              const selectedRows = rows?.filter((row) => newRowSelectionModel?.includes(row?.id));
              const hasPO = selectedRows?.some(
                (row) =>
                  row?.documentType === 'Po' ||
                  (row?.documentStatus !== APPROVED && row?.documentStatus !== ATTACHED)
              );
              const hasReviewDoc = selectedRows?.some((row) => row?.documentStatus === WAITING);
              setSelectedRowsHasPO(hasPO);
              setSelectedRowsHasReview(hasReviewDoc);
              setCheckedIds(newRowSelectionModel);
            }}
            rowSelectionModel={checkedIds}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              isEmpty(newModel) ? updateContent(defaultColumns) : updateContent(newModel)
            }
            keepNonExistentRowsSelected
          />
        ) : (
          <div>Loading...</div>
        )}
      </Grid>
    </ThemeProvider>
  );
}
