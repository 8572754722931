export const Colors = {
  BG_BLACK: '#141414',
  DARKBLUE: '#1AACAC',
  MUSTARD: '#EEBA6D',
  WHITE: '#fafafa',
  LIGHTGREY: '#BABABA',
  DEEPBLUE: '#24658D',
  TOURQUISE: '#2E97A7',
  NAV_BLACK: '#1E1E1E',
  TEXTGREY: '#fafafa',
  RED: '#E95050',
  DEEPGREY: '#585858',
  Green: '#17B169',
  Canvas_BG: '#282828',
  Canvas_Left_BG: '#161618',
  LIGHTBLUE: '#429EB0',
  YELLOW: '#F1A230',
  GREY: '#262626',
  DISABLEDGRAY: '#A7A7A7',
  DARKGRAY: '#303030',
  QB_GREEN: '#2CA01C',
  LIGHT_TOURQUISE: '#5AB8C5',
  TEEL_BLUE: '#4682B4',
  BLAKISH_BLUE: '#1A2427',
  POLICY_BG: '#1C1C1C',
  OCR_BG: '#D1F9FF',
  OCR_BORDER: '#5CBBCA'
};
