import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { replaceName } from '../services/common';

export default function AccountDetailList({ dataArray }) {
  const erpType = useSelector((state) => state?.userInfo?.userInfo?.user?.erpType);

  if (!dataArray || dataArray?.length === 0) {
    return null;
  }

  return (
    <>
      {dataArray?.map((item, index) => (
        <Grid
          container
          key={index}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            color: Colors.TEXTGREY,
            mb: '1.5em'
          }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '0.80em' }}>{replaceName(item?.name, erpType)}</Typography>
          </Grid>
          <Grid item xs={7} sx={{ textAlign: 'right' }}>
            <Typography sx={{ fontSize: '0.80em' }}>{item?.value || '-'}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
