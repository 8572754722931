import React, { useState } from 'react';
import { Grid, Button, CircularProgress, Modal, Box } from '@mui/material';
import { RemoveRedEyeOutlined, AttachFile, Close } from '@mui/icons-material';
import MuiModal from './muiModel';
import MuiPopover from './muiPopover';
import ActivityDrawer from './activityDrawer';
import CommentsInput from './commentsInput';
import { Colors } from '../config/default';

const ActionButtonsGrid = ({
  id,
  annotationType,
  refId,
  po,
  images,
  deliveryTicketUpload,
  poMatchPermission,
  lineItem,
  lineItemsData,
  setImages,
  setRefId,
  vendor,
  invoiceNumber,
  date,
  setIndex,
  userDetails,
  OWNER,
  ACCOUNTANT,
  buttonArray,
  loadingButtons,
  handlePopoverOpen,
  handlePopoverClose,
  handleButtonClick,
  setPendingAction,
  pendingAction,
  anchorEl,
  setAnchorEl,
  approvers,
  docName
}) => {
  const [openCommentModal, setOpenCommentModal] = useState(false);

  const handleClose = () => {
    setOpenCommentModal(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: Colors.Canvas_BG,
    borderRadius: '20px',
    p: 3,
    zIndex: '99'
  };

  return (
    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
      <ActivityDrawer type="canvas" id={id} documentType={annotationType} documentName={docName} />
      {(annotationType === 'Invoice' || annotationType === 'Receipt' || annotationType === 'G702') &&
        (deliveryTicketUpload || poMatchPermission) && (
          <MuiModal
            text="View Document"
            icon={<RemoveRedEyeOutlined sx={{ fontSize: '20px' }} />}
            show="supportingDoc"
            id={refId}
            po={po}
            LargeScreenWidth="70vw"
            XLScreenWidth="47vw"
            images={images}
            annotationType={annotationType}
            deliveryTicketUpload={deliveryTicketUpload}
            poMatchPermission={poMatchPermission}
            lineItem={lineItem}
            lineItemsData={lineItemsData}
          />
        )}
      {annotationType === 'Invoice' && deliveryTicketUpload && (
        <MuiModal
          text="Remove/Attach Delivery Ticket (Only approved delivery tickets can be attached)"
          icon={<AttachFile sx={{ fontSize: '20px' }} />}
          show="deliveryTicket"
          setImages={setImages}
          setRefId={setRefId}
          id={refId}
          vendor={vendor}
          po={po}
          invoiceNumber={invoiceNumber}
          date={date}
          LargeScreenWidth="58vw"
          setIndex={setIndex}
        />
      )}
      {userDetails?.role !== OWNER && userDetails?.role !== ACCOUNTANT &&
        buttonArray?.map((btn) => {
          const isLoading = loadingButtons[btn?.status];
          return (
            <Button
              key={btn?.name}
              disabled={isLoading}
              onMouseEnter={btn?.name === 'Reject' ? handlePopoverOpen : undefined}
              onMouseLeave={btn?.name === 'Reject' ? handlePopoverClose : undefined}
              variant="outlined"
              sx={{
                border: `1px solid ${btn?.Color}`,
                borderRadius: '10px',
                height: '2rem',
                width: '7rem',
                marginRight: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '.8em',
                textTransform: 'none',
                color: Colors.WHITE,
                '&:hover': {
                  background: 'none',
                  border: `1px solid ${btn?.Color}`,
                },
              }}
              startIcon={isLoading ? null : btn?.icon}
              onClick={(e) => {
                if (btn.status === 'on hold') {
                  handleButtonClick(e, btn.status);
                } else {
                  setPendingAction(btn.status);
                  setOpenCommentModal(true);
                }
              }}
            >
              {isLoading ? <CircularProgress size={16} sx={{ color: btn?.Color }} /> : btn?.name}
            </Button>
          );
        })}
      {openCommentModal && (
        <Modal
          open={openCommentModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: '30vw' }}>
            <Close
              sx={{ color: Colors.TEXTGREY, top: '10px', right: '10px', cursor: 'pointer', position: 'absolute' }}
              onClick={handleClose}
            />
            <CommentsInput
              handleSubmitClick={handleButtonClick}
              status={pendingAction}
              setOpenCommentModal={setOpenCommentModal}
              id={id}
            />
          </Box>
        </Modal>
      )}
      <MuiPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        startMessage="Rejected"
        approvers={approvers?.approved?.length > 0 ? approvers?.approved[approvers?.approved?.length - 1] : []}
        startPosition="bottom"
        endPosition="top"
      />
    </Grid>
  );
};

export default ActionButtonsGrid;
