/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEqual } from 'lodash';

import { Grid, CircularProgress, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  TopicOutlined,
  DescriptionOutlined,
  HighlightAltOutlined,
  CallReceivedOutlined,
  EmailOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import AdminDataTable from '../components/adminDataTable';
import theme from '../theme';
import { getAllUsers, getAllErrors, getVendors, getManualUsers } from '../services/services';
import {
  get_all_users,
  get_manual_users,
  get_tabs,
  get_all_vendors
} from '../redux/actions/action';
import Layout from '../components/layout';
import {
  AdminParentTabs,
  AdminChildTabs,
  userColumns,
  AdminDocChildTabs,
  formatDate,
  AdminVendorsChildTabs,
  vendorColumns,
  formatNumberWithoutDecimal
} from '../services/common';

import HeaderSection from '../components/headerSection';
import { Colors } from '../config/default';
import MuiModal from '../components/muiModel';
import { CustomerDataTable, tableComponent } from '../constants/appConstants';

export default function AdminHomePage() {
  const { EMAIL, USERNAME } = CustomerDataTable;
  const { RECIEVED_AT, DOCUMENT_NAME, DOCUMENT_ID, VIEW, ACTIONS } = tableComponent;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const parentActiveTab = globalActiveTab?.activeTab;
  const [activeTab, setActiveTab] = useState(parentActiveTab || AdminParentTabs[0]?.name);
  const [manualLoading, setManualLoading] = useState(false);
  const [recordExist, setRecordExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.usersActiveTab || AdminChildTabs[0]?.name
  );
  const [docChildTab, setDocChildTab] = useState(AdminDocChildTabs[0]?.name);
  const [vendorChildTab, setVendorChildTab] = useState(AdminVendorsChildTabs[0]?.name);

  const [rows, setRows] = useState([]);
  const [errorRows, setErrorRows] = useState([]);
  const [vendorRows, setVendorRows] = useState([]);
  const [errorsList, setErrorsList] = useState([]);
  const vendorsList = useSelector((state) => state?.vendor?.allVendors);
  const [manualUsersRow, setManualUsersRow] = useState([]);
  const users = useSelector((state) => state?.users?.allUsers);
  const manualUsers = useSelector((state) => state?.manualUsers?.manualUsers);
  const Tabs = {
    activeTab: activeTab,
    usersActiveTab: childActiveTab
  };
  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab]);
  useEffect(() => {
    setActiveTab(parentActiveTab || AdminParentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.usersActiveTab || AdminChildTabs[0]?.name);
  }, [parentActiveTab, globalActiveTab?.usersActiveTab]);
  const getErrors = async () => {
    setErrorLoading(true);
    const result = await getAllErrors();
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    setErrorsList(result?.data?.payload?.data);
    setErrorLoading(false);
  };

  const allusers = async () => {
    setLoading(true);
    const result = await getAllUsers();
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    dispatch(get_all_users(result?.data?.payload?.data));
    setLoading(false);
  };
  const getAllVendors = async () => {
    setVendorLoading(true);
    const result = await getVendors();
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    dispatch(get_all_vendors(result?.data?.payload?.data));
    setVendorLoading(false);
  };
  const getManualUser = async () => {
    setManualLoading(true);
    const result = await getManualUsers();
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    dispatch(get_manual_users(result?.data?.payload?.data));
    setManualLoading(false);
  };
  useEffect(() => {
    allusers();
    getErrors();
    getAllVendors();
    getManualUser();
  }, []);
  const manualUserColumns = [
    {
      field: 'name',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <PersonOutlineOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {USERNAME}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },
    {
      field: 'email',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <EmailOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {EMAIL}
        </div>
      ),
      minWidth: 900,
      sortable: false
    },

    {
      field: 'action',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTIONS}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: false,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <MuiModal
          text="create bill"
          data={params?.row?.email}
          username={params?.row?.name}
          color={Colors.TOURQUISE}
          show={'manual users'}
          btnHeight="2rem"
          width="6rem"
          getManualUser={getManualUser}
        />
      )
    }
  ];
  const errorColumns = [
    {
      field: 'date',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CallReceivedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {RECIEVED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: false
    },
    {
      field: 'documentName',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {DOCUMENT_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },
    {
      field: 'documentId',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <TopicOutlined sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }} />
          {DOCUMENT_ID}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: false
    },

    {
      field: 'action',
      headerName: (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTIONS}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: false,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{
            height: '2rem',
            width: '6rem',
            borderRadius: '10px',
            color: Colors.WHITE,
            textTransform: 'none'
          }}
          onClick={(e) => navigate(`/details/${params?.row?.documentId}`)}>
          {VIEW}
        </Button>
      )
    }
  ];
  useEffect(() => {
    const generatedData = users[0]?.map((item, index) => ({
      id: item?._id,
      email: item?.email,
      invoices: formatNumberWithoutDecimal(item?.totalInvoices),
      pages: formatNumberWithoutDecimal(item?.totalNumberOfPages),
      Username: item?.userName,
      role: item?.role || '---',
      company: item?.companyName || '---'
    }));
    if (!isEqual(generatedData, users)) {
      setRows(generatedData);
    }
  }, [users]);
  useEffect(() => {
    const newUsers = manualUsers[0]?.map((item, index) => ({
      id: item?._id,
      email: item?.email,
      name: item?.name
    }));
    if (!isEqual(newUsers, manualUsers)) {
      setManualUsersRow(newUsers);
    }
  }, [manualUsers]);
  useEffect(() => {
    const Data = errorsList?.map((item, index) => ({
      id: item?._id,
      documentName: item?.key,
      documentId: item?.documentId,
      date: formatDate(item?.createdAt)
    }));
    if (!isEqual(Data, errorsList)) {
      setErrorRows(Data);
    }
  }, [errorsList]);
  useEffect(() => {
    const vendorData = vendorsList[0]?.map((item, index) => ({
      id: index,
      email: item?.email,
      invoices: formatNumberWithoutDecimal(item?.totalNumberOfDocs),
      pages: formatNumberWithoutDecimal(item?.totalNumberOfPages),
      documents: item?.documents,
      limit: formatNumberWithoutDecimal(item?.limit),
      docLimit: item?.limit,
      clientId: item?.clientId
    }));
    if (!isEqual(vendorData, vendorsList)) {
      setVendorRows(vendorData);
    }
  }, [vendorsList]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={AdminParentTabs}
          recordExist={recordExist}
          path="admin"
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={
                parentActiveTab === 'Documents'
                  ? AdminDocChildTabs
                  : parentActiveTab === 'Users'
                  ? AdminChildTabs
                  : AdminVendorsChildTabs
              }
              setActiveTab={
                parentActiveTab === 'Documents'
                  ? setDocChildTab
                  : parentActiveTab === 'Users'
                  ? setChildActiveTab
                  : setVendorChildTab
              }
              activeTab={
                parentActiveTab === 'Documents'
                  ? docChildTab
                  : parentActiveTab === 'Users'
                  ? childActiveTab
                  : vendorChildTab
              }
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              tab={parentActiveTab}
              currentUser="Admin"
              getManualUser={getManualUser}
              getAllVendors={getAllVendors}
            />
            {loading || manualLoading || errorLoading || vendorLoading ? (
              <Grid
                container
                sx={{
                  height: '75vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={150} color="primary" />
              </Grid>
            ) : parentActiveTab === 'Users' ? (
              <>
                {childActiveTab === 'All Users' && (
                  <AdminDataTable
                    apiData={users}
                    rows={rows}
                    setRows={setRows}
                    columns={userColumns}
                  />
                )}
                {childActiveTab === 'Manual Users' && (
                  <AdminDataTable
                    apiData={manualUsers}
                    rows={manualUsersRow}
                    setRows={setManualUsersRow}
                    columns={manualUserColumns}
                  />
                )}
              </>
            ) : activeTab === 'Documents' ? (
              <AdminDataTable
                apiData={errorsList}
                rows={errorRows}
                setRows={setErrorRows}
                columns={errorColumns}
              />
            ) : (
              <AdminDataTable
                apiData={vendorsList}
                rows={vendorRows}
                setRows={setVendorRows}
                columns={vendorColumns}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
