import React from 'react';

import { Grid, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { canvasDetails } from '../constants/appConstants';
import { formatNumber } from '../services/common';
import { replaceContentNames } from '../services/validationService';

export default function InvoiceList({ apiData, document }) {
  const { VENDOR_NUMBER } = canvasDetails;

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          color: Colors.TEXTGREY,
          mb: '1.5em'
        }}>
        <Grid xs={4}>
          <Typography sx={{ fontSize: '0.80em' }}>{VENDOR_NUMBER}</Typography>
        </Grid>
        <Grid xs={7} sx={{ textAlign: 'right' }}>
          <Typography sx={{ fontSize: '0.80em' }}>{document?.vendorNumber || '-'}</Typography>
        </Grid>
      </Grid>

      {apiData &&
        apiData.map((item) => (
          <Grid
            container
            xs={12}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              color: Colors.TEXTGREY,
              mb: '1.5em'
            }}>
            <Grid xs={4}>
              <Typography sx={{ fontSize: '0.80em' }}>
                {item?.name === 'Estimate_no'
                  ? item?.name.replace('Estimate_no', 'Quote No')
                  : replaceContentNames(item?.name)}
              </Typography>
            </Grid>
            <Grid xs={7} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.80em' }}>
                {item?.name === 'total' ? formatNumber(item?.value) : item?.value || '-'}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </div>
  );
}
