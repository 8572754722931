import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Grid, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../theme';
import Layout from '../components/layout';
import { getParentTabs, usersChildTabs } from '../services/common';
import HeaderSection from '../components/headerSection';
import UserDataGrid from '../components/userDataGrid';
import { getOwnerUsers, syncData } from '../services/services';
import { useToast } from '../toast/toastContext';

function Users() {
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [childActiveTab, setChildActiveTab] = useState(usersChildTabs[0]?.name);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const [erpLoading, setErpLoading] = useState(false);
  const { showToast } = useToast();
  const parentTabs = getParentTabs(userRole);
  const getUsers = async () => {
    setLoading(true);
    const result = await getOwnerUsers();
    if (result?.status === 200) {
      const resArray = result?.data?.payload?.data;
      let OwnerObject = { email: userDetails?.user?.email, role: 'Owner', signUp: true };
      resArray?.unshift(OwnerObject);
      setApiData(resArray);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, []);
  const handleSyncErp = async (actIntegration) => {
    setErpLoading(true);
    sessionStorage.setItem('erpType', actIntegration);
    const params = {
      data: ['employees']
    };
    const syncResult = await syncData(params);
    if (syncResult?.status === 200) {
      showToast(syncResult?.data?.metadata?.message, 'success');
      getUsers();
    } else {
      showToast(
        syncResult?.data?.metadata?.message || syncResult?.response?.data?.metadata?.message,
        'error'
      );
    }
    setErpLoading(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundColor: 'primary.light'
        }}>
        <Layout
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={parentTabs}
          path="users"
          show={userDetails?.role === 'Owner' ? 'showUser' : ''}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <HeaderSection
            currentTabs={usersChildTabs}
            setActiveTab={setChildActiveTab}
            activeTab={childActiveTab}
            tab="Users"
            currentUser="Customer"
            getUsers={getUsers}
            handleSyncErp={handleSyncErp}
            erpLoading={erpLoading}
          />
          {loading ? (
            <Grid
              container
              sx={{
                height: '75vh',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress size={150} color="primary" />
            </Grid>
          ) : (
            <UserDataGrid apiData={apiData} getUsers={getUsers} />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Users;
