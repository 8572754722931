import React from 'react';
import { Colors } from '../config/default';
import { Check } from '@mui/icons-material';
import { documentStatusText } from '../constants/appConstants';

function BatchDocumentsDrawer({ option1, option2, option3, dataList }) {
  const { APPROVED } = documentStatusText;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          padding: '0.5rem 0',
          fontSize: '.9rem',
          color: Colors.TOURQUISE
        }}>
        <div style={{ minWidth: '1rem', textAlign: 'center', marginRight: '.5rem' }}>#</div>
        <div style={{ flex: 1, marginRight: '1rem' }}>{option1}</div>
        <div style={{ flex: 1, marginRight: '1rem' }}>{option2}</div>
        <div style={{ flex: 1 }}>{option3}</div>
      </div>

      {dataList?.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 0',
            fontSize: '.8rem'
          }}>
          <div
            style={{
              minWidth: '1rem',
              textAlign: 'center',
              color: Colors.TOURQUISE,
              marginRight: '.5rem'
            }}>
            {`${index + 1}.`}
          </div>
          <span
            style={{
              flex: 1,
              marginRight: '1rem',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              overflowWrap: 'break-word'
            }}>
            {item?.fileName || '-'}
          </span>
          <span style={{ flex: 1 }}>{item?.docNumber || '-'}</span>
          <span style={{ flex: 1 }}>
            {item?.sendErpStatus === APPROVED ? (
              <Check sx={{ color: Colors.TOURQUISE, fontSize: '20px' }} />
            ) : (
              '-'
            )}
          </span>
        </div>
      ))}
    </div>
  );
}

export default BatchDocumentsDrawer;
